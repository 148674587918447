import React, { useState, useEffect } from 'react'
import deleteImg from "../../images/delete.svg";
import Button from "react-bootstrap/Button";
import Addbtn from '../../images/Addbtn.svg';
import * as constants from "../../Utils/URLConstants";
import axios from "axios";
import LoadingOverlay from 'react-loading-overlay';
import { Form } from 'react-bootstrap';
import Save from '../../images/Save.svg';
import Edit from '../../images/Edit.svg';
import post from '../../images/post.svg';
import ReactSnackBar from "react-js-snackbar";
import { Modal } from "react-bootstrap";

function RosterSchedule() {
  const [count, setCount] = useState(0);
  const [allRoster, setAllRoster] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [metaData, setsetMetaData] = useState(null);
  const [scheduleNames, setscheduleNames] = useState(null);
  const [editFieldValue, setEditFieldValue] = useState(0);
  const [readOnlyEmpName, setReadOnly] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [filterSearch, setFilterSearch] = useState(false);
  const [empData, setEmpData] = useState([]);
  const [empSearchData, setEmpSearchData] = useState([]);

  const [userKey, setUserKey] = useState("");
  const [isDelete, setIsDelete] = useState(false);
  const [formFields, setFormFields] = useState(
    {

      userName: '',
      scheduleId: '',
      startDate: '',
      endDate: '',
      level1: '',
      level2: '',
      level3: '',

    });
  const [emoji, setEmoji] = useState("");
  const [snackmessage, setSnackMessage] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);


  useEffect(() => {
    loadMetaData();
    getAllScheduleNames();
    handleEmpName();
  }, []);

  useEffect(() => {
    getAllRosterData();
  }, [count]);


  const handleSubmit = (e) => {

    console.log(e)
  }


  const setFieldValueData = (val) => {
    setEditFieldValue(val.id);
  }

  const setDeleteFlag = (val) => {
    setDeleteId(val.id);
    setIsDelete(true);
  }

  const closeModal = () => {
    setDeleteId(0);
    setIsDelete(false);
  }

  const snackEnable = () => {
    setTimeout(() => {
      setShowSnackBar(false);
    }, 4000);
  }

  const getAllRosterData = async () => {
    setLoading(true);
    axios
      .get(constants.GET_ALL_ROSTER, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        setAllRoster(result.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("failed with error ", error);
      });
  };

  const loadMetaData = async () => {
    axios
      .get(constants.GET_ALL_META_DATA, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        setsetMetaData(Object.entries(result.data));
      })
      .catch((error) => {
        console.log("failed with error ", error);
      });
  };

  const getAllScheduleNames = async () => {
    axios
      .get(constants.GET_SCHEDULE_NAMES, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        setscheduleNames(Object.entries(result.data));
      })
      .catch((error) => {
        console.log("failed with error ", error);
      });
  };

  const updateFormData = (val) => {
    setLoading(true);
    axios
      .put(
        constants.UPDATE_ROSTER,
        val,
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setSnackMessage("Roster Updated Successfully !");
        setEmoji("✔️");
        setShowSnackBar(true);
        snackEnable();
        setLoading(false);
        setCount(count + 1)
        setEditFieldValue(0);
      })
      .catch((error) => {
        setSnackMessage("Invalid Data")
        setEmoji("❌");
        setShowSnackBar(true);
        snackEnable();
        setLoading(false);
      });
  }

  const saveFormData = (val) => {
    setShowSnackBar(false);

    if (val.userName === '' || val.scheduleId === '' || val.startDate === '' || val.endDate === '' || val.level1 === '') {
      setIsSubmitted(true);
    }
    else {
      val.userName = userKey;
      //setFormFields({ ...formFields, userName: userKey })
      axios
        .post(
          constants.CREATE_ROSTER,
          val,
          {
            headers: {
              Authorization: sessionStorage.getItem("token"),
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          setSnackMessage("Roster Created Successfully !");
          setEmoji("✔️");
          setShowSnackBar(true);
          setIsSubmitted(false);
          setFormFields({
            userName: '',
            scheduleId: '',
            startDate: '',
            endDate: '',
            level1: '',
            level2: '',
            level3: '',
          })
          snackEnable();

          setCount(count + 1);
        })
        .catch((error) => {
          setSnackMessage("Roster Exist  with Same Dates Already!")
          setEmoji("❌");
          setShowSnackBar(true);
          setIsSubmitted(false);
          snackEnable();
        });
    }
  }

  const deleteFormData = () => {
    axios
      .delete(constants.DELETE_ROSTER, {
        params: { rosterId: deleteId },

        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        setIsDelete(false);
        setSnackMessage(" Deleted Successfully!");
        snackEnable();
        setEmoji("✔️");
        setCount(count + 1);
      })
      .catch((error) => {
        setShowSnackBar(true);
        setSnackMessage("Something Went Wrong!");
        snackEnable();
        setEmoji("❌");
      });
  };

  const handleEmpName = (e) => {
    axios.get(constants.SEARCH_EMP_NAMES, {
      params: { name: e },
      headers: {
        "Authorization": sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    }).then((result) => {


      const values = Object.entries(result.data);
      setEmpData(values);

    }).catch((error) => {
      console.log("failed with error ", error);
    })
  }

  const searchEmpName = (e) => {

    setFilterSearch(true)
    axios.get(constants.SEARCH_EMP_NAMES_ROSTER, {
      params: { name: e },
      headers: {
        "Authorization": sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    }).then((result) => {


      const values = Object.entries(result.data);
      setEmpSearchData(values);

    }).catch((error) => {
      console.log("failed with error ", error);
    })
  }

  const postFormData = (val) => {
    setLoading(true);
    axios
      .post(
        constants.POST_ROSTER, {},

        {
          params: { rosterId: val.id },
          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setSnackMessage("Roster Posted Successfully !");
        setEmoji("✔️");
        setShowSnackBar(true);
        snackEnable();
        setLoading(false);
        setCount(count + 1);
      })
      .catch((error) => {
        setSnackMessage("Invalid Data")
        setEmoji("❌");
        setShowSnackBar(true);
        snackEnable();
        setLoading(false);
      });
  }


  const handleSubFormChange = (event, val) => {

    let newState = allRoster.map(value => {
      if (value.id === val.id && event.target.value !== "") {

        if (event.target.name === 'level1') {
          value.level2 = '';
          value.level3 = '';
        }

        if (event.target.name === 'level2') {
          value.level3 = '';
        }
        return { ...value, [event.target.name]: event.target.value }

      }
      return value;
    });
    setAllRoster(newState);
  }

  const handleSelectName = (e, k, val) => {
    setFormFields({ ...formFields, userName: val })
    setFilterSearch(false);
    setUserKey(k);
  }


  return (
    <div>
      <p style={{ color: "#66676c", fontSize: "13px" }}>Dashboard &gt; Roster </p>
      {/* Top Header */}
      <div className="bg-white p-3 rounded d-flex justify-content-between d-none">



        <div>
          <input
            type="text"
            name=""
            id=""
            placeholder="Search text"
            className="border border-black px-2 py-1 widthForInput mx-4 rounded"
            style={{ all: 'unset', visibility: 'hidden' }}
          />
        </div>
        {/* Cancel and Save Buttons in header */}
        <div className="mx-5">
          <button onClick={(e) => {
            e.preventDefault()

          }} className="btn btn-success py-1">Post</button>
        </div>
      </div>

      {/* Hero section */}
      <div><ReactSnackBar autoHideDuration={3000} Icon={<span>{emoji}</span>} Show={showSnackBar}>
        {snackmessage}
      </ReactSnackBar>
      </div>
      <LoadingOverlay active={loading} spinner text="Please Wait... " >
        <div style={{ height: '70vh', overflow: 'scroll', marginTop: '5%', position: 'relative', width: '100%' }}>


          <b>Create New Roster</b>
          <Form >
            {
              <div className='w-100 bg-white  px-4 mt-1 mb-3 rounded-4 border border-2 py-2 shadow-sm d-flex justify-content-between align-items-center'>
                {/* <span>
                    <input type="checkbox" name="" id="" style={{ scale: '1.5', width: 'unset' }} />
                  </span> */}
                <span className='d-flex flex-column position-relative '>
                  < Form.Control input type="text" name="userName" id="" required isInvalid={isSubmitted && formFields.userName === ''} value={formFields.userName} style={{ width: '100%', height: '130%', padding: '5px' }} placeholder='Enter Employee Name*' onChange={(e) => {
                    setFormFields({ ...formFields, userName: e.target.value })
                    searchEmpName(e.currentTarget.value);
                  }} />
                  {
                    filterSearch ?
                      <span className='bg-white border position-absolute' style={{ maxHeight: '500%', marginTop: '45px', overflow: 'scroll', overflowX: 'hidden', width: '100%' }}>
                        {empSearchData?.map(([key, val]) => (
                          <li data-index={val} className="hoverList" onClick={event => handleSelectName(event, key, val)} key={key}>{val}</li>
                        ))}

                      </span> : ''
                  }

                </span>

                {/* <span style={{ color: "#66676c", fontSize: "14px" }}>Employee Name <span style={{ color: 'red' }}>*</span>:<br />
                  <Form.Select name="userName" id="" required isInvalid={isSubmitted && formFields.userName === ''} onChange={(e) => setFormFields({ ...formFields, userName: e.target.value })} value={formFields.userName} style={{ marginTop: '0px', fontSize: '14px', width: 'fit-content' }}>
                    <option value="" selected style={{ display: 'none' }}>Select Employee Name</option>
                    {empData?.map(([key, val]) => (

                      <option value={key} style={{ fontSize: '14px' }}>{val}</option>
                    ))}
                  </Form.Select>
                </span> */}


                {/* schedule name div */}
                <div className='d-flex flex-column schedulePaddingRoster'>
                  <div className='d-flex justify-content-between w-100'>
                    <span style={{ color: "#66676c", fontSize: "14px", minWidth: '30%' }} className='paddingForScheduleName'> Schedule Name <span style={{ color: 'red' }}>*</span>:<br />
                      <Form.Select name="scheduleId" id="" required isInvalid={isSubmitted && formFields.scheduleId === ''} onChange={(e) => setFormFields({ ...formFields, scheduleId: e.target.value })} value={formFields.scheduleId} style={{ marginTop: '0px', height: 'unset', width: '90%', padding: '0px 15px 0px 3px', fontSize: '14px' }}>
                        <option value="" selected style={{ display: 'none' }}>Select Schedule</option>
                        {scheduleNames?.map(([key, val]) => (

                          <option value={key}>{val}</option>
                        ))}
                      </Form.Select>
                    </span>

                    <span style={{ color: "#66676c", fontSize: "14px", minWidth: '30%' }} id="isInvalidInRoster">
                      Start Date <span style={{ color: 'red' }}>*</span>:  < Form.Control input type="date" min={new Date().toISOString().slice(0, 10)} name="startDate" required isInvalid={isSubmitted && formFields.startDate === ''} value={formFields.startDate} onChange={(e) => setFormFields({ ...formFields, startDate: e.target.value })} id="startDate" style={{ marginTop: '0px', width: '90%', padding: '4px 25px', fontSize: '14px' }} />
                    </span>
                    <span style={{ color: "#66676c", fontSize: "14px", minWidth: '30%' }} id="isInvalidInRoster">
                      End Date <span style={{ color: 'red' }}>*</span>: < Form.Control input type="date" min={new Date().toISOString().slice(0, 10)} name="endDate" id="endDate" required isInvalid={isSubmitted && formFields.endDate === ''} value={formFields.endDate} onChange={(e) => setFormFields({ ...formFields, endDate: e.target.value })} className='' style={{ marginTop: '0px', width: '90%', padding: '4px 25px', fontSize: '14px' }} />
                    </span>
                  </div>



                  {/* levels */}
                  <div className='d-flex flex-row justify-content-between my-1 align-items-center'>

                    <div style={{ color: "#66676c", fontSize: "14px", minWidth: '30%' }} id="isInvalidInRoster">
                      <span>Level 1 <span style={{ color: 'red' }}>*</span>:</span>
                      <Form.Select name="level1" id="" value={formFields.level1} required isInvalid={isSubmitted && formFields.level1 === ''} onChange={(e) => setFormFields({ ...formFields, level1: e.target.value, level2: '', level3: '' })} className='mt-0 pr-2 py-0' style={{ marginTop: '0px', fontSize: '14px' }}>
                        <option value="" selected style={{ display: 'none' }}>Select Level1</option>
                        {
                          metaData !== null && metaData?.map(([key, val]) => (

                            <option value={key}>{key}</option>
                          ))
                        }
                      </Form.Select>
                    </div>

                    <div style={{ color: "#66676c", fontSize: "14px", minWidth: '30%' }} id="isInvalidInRoster">
                      Level 2:
                      <Form.Select name="level2" id="" value={formFields.level2} onChange={(e) => setFormFields({ ...formFields, level2: e.target.value, level3: '' })} className='mt-0 pr-2 py-0' style={{ marginTop: '0px', fontSize: '14px' }}>
                        <option value="" selected style={{ display: 'none' }}>Select Level2</option>
                        {
                          metaData !== null && metaData?.map(([key, val]) => (
                            key === formFields.level1 ?
                              (Object.entries(val)?.map(([key, val]) => (
                                <option value={key}>{key}</option>
                              ))) : ""
                          ))

                        }

                      </Form.Select>
                    </div>
                    <div style={{ color: "#66676c", fontSize: "14px", minWidth: '30%' }} id="isInvalidInRoster">
                      <span>Level 3:</span>
                      <Form.Select name="level3" id="" onChange={(e) => setFormFields({ ...formFields, level3: e.target.value })} className='mt-0 pr-2 py-0' style={{ marginTop: '0px', fontSize: '14px' }}>
                        <option value="" selected style={{ display: 'none' }}>Select Level3</option>
                        {
                          metaData !== null && metaData?.map(([key, val]) => (
                            key === formFields.level1 ?
                              (Object.entries(val)?.map(([key, val]) => (
                                key === formFields.level2 ?
                                  (val.toString().split(",").map((line) => (

                                    <option value={line}>{line}</option>))) : ""

                              ))) : ""
                          ))
                        }
                      </Form.Select>
                    </div>


                  </div>
                </div>





                <img src={Save} alt="" onClick={() => saveFormData(formFields)} />


              </div>
            }
          </Form>

          <b>Existing Rosters</b>
          <Form onSubmit={handleSubmit}>
            {allRoster !== null ?
              allRoster.map((item, i) => (
                <div className='w-100 bg-white  px-4 mt-3 rounded-4 border border-2 py-2 shadow-sm d-flex justify-content-between align-items-center'>

                  <span className='d-flex flex-column '>
                    <span style={{ color: "#66676c", fontSize: "14px" }}>Employee Name:</span><span className='fontWeight'>
                      <Form.Select name="userName" disabled id="" value={item.userName} style={{ marginTop: '-1px', width: '90%' }}>
                        {/* <option style={{ display: 'none' }} value="">{item.scheduleId}</option> */}
                        {empData?.map(([key, val]) => (

                          <option value={key}>{val}</option>
                        ))}
                      </Form.Select>
                    </span>
                  </span>
                  <Button style={{ width: '9%', fontSize: '14px', marginTop: '12px' }} variant={
                    item.rosterStatus === 'ACCEPTED' ? 'success' : item.rosterStatus === 'REJECTED' ? 'danger' : item.rosterStatus === 'PENDING' ? 'warning' : 'info'
                  }>{item.rosterStatus}</Button>

                  {/* middle flex */}
                  <div style={{ width: '65%' }} className=''>
                    <div className='d-flex flex-col justify-content-around align-items-center'>
                      <span style={{ width: '30%', color: "#66676c", fontSize: "14px" }}>
                        Schedule Name <span style={{ color: 'red' }}>*</span>:  <br />
                        <Form.Select name="scheduleId" id="" disabled={editFieldValue === item.id ? false : true} onChange={(event) => handleSubFormChange(event, item)} value={item.scheduleId} style={{ marginTop: '0px', padding: '2px 5px' }} readOnly={editFieldValue === item.id ? false : true}>


                          {scheduleNames?.map(([key, val]) => (

                            <option value={key}>{val}</option>
                          ))}
                        </Form.Select>
                      </span>

                      <span style={{ width: '30%', color: "#66676c", fontSize: "14px" }}>
                        Start Date <span style={{ color: 'red' }}>*</span>: <Form.Control input type="date" name="startDate" min={new Date().toISOString().slice(0, 10)} disabled={editFieldValue === item.id ? false : true} value={item.startDate} readOnly={editFieldValue === item.id ? false : true} onChange={(event) => handleSubFormChange(event, item)} id="startDate" className='mt-0 text-black' style={{ height: 'unset', padding: '2px', width: '80%' }} />
                      </span>

                      <span style={{ width: '30%', color: "#66676c", fontSize: "14px" }}>
                        End Date <span style={{ color: 'red' }}>*</span>:<br /> <Form.Control input type="date" name="endDate" id="endDate" min={new Date().toISOString().slice(0, 10)} disabled={editFieldValue === item.id ? false : true} value={item.endDate} readOnly={editFieldValue === item.id ? false : true} onChange={(event) => handleSubFormChange(event, item)} className=' mt-0 text-black' style={{ height: 'unset', padding: '2px', width: '80%' }} />
                      </span>
                    </div>

                    <div className='d-flex flex-row align-items-center justify-content-around mt-2'>

                      <div style={{ width: '30%', color: "#66676c", fontSize: "14px", }}>
                        Level 1 <span style={{ color: 'red' }}>*</span>:
                        <Form.Select disabled={editFieldValue === item.id ? false : true} name="level1" id="" value={item.level1} onChange={(event) => handleSubFormChange(event, item)} className='mt-0 pr-2 py-0' style={{ marginTop: '0px' }}>
                          <option style={{ display: 'none', color: "#66676c", fontSize: "14px" }} value="">{item.level1}</option>
                          {
                            editFieldValue === item.id && metaData?.map(([key, val]) => (

                              <option value={key}>{key}</option>
                            ))
                          }
                        </Form.Select>
                      </div>


                      <div style={{ width: '30%', color: "#66676c", fontSize: "14px", }}>
                        Level 2:
                        <Form.Select disabled={editFieldValue === item.id ? false : true} name="level2" id="" value={item.level2} onChange={(event) => handleSubFormChange(event, item)} className='mt-0 pr-2 py-0' style={{ marginTop: '0px' }}>
                          <option style={{ display: 'none', color: "#66676c", fontSize: "14px" }} value="">{item.level2}</option>
                          {
                            editFieldValue === item.id && metaData?.map(([key, val]) => (
                              key === item.level1 ?
                                (Object.entries(val)?.map(([key, val]) => (
                                  <option value={key}>{key}</option>
                                ))) : ""
                            ))

                          }

                        </Form.Select>
                      </div >

                      <div className='d-flex flex-column' style={{ width: '30%', color: "#66676c", fontSize: "14px", }}>
                        Level 3:
                        <Form.Select disabled={editFieldValue === item.id ? false : true} name="level3" id="" value={item.level3} onChange={(event) => handleSubFormChange(event, item)} className='mt-0 pr-2 py-0' style={{ marginTop: '0px' }}>
                          <option style={{ display: 'none', color: "#66676c", fontSize: "14px" }} value="">{item.level3}</option>
                          {
                            editFieldValue === item.id && metaData?.map(([key, val]) => (
                              key === item.level1 ?
                                (Object.entries(val)?.map(([key, val]) => (
                                  key === item.level2 ?
                                    (val.toString().split(",").map((line) => (

                                      <option value={line}>{line}</option>))) : ""

                                ))) : ""
                            ))
                          }
                        </Form.Select>
                      </div>


                    </div>
                  </div>



                  <div style={{ width: '15%' }} className=' d-flex flex-column justify-content-around  flex-nowrap px-2'>
                    <div className=' d-flex flex-row justify-content-between'>

                      {editFieldValue !== item.id ? <img src={Edit} alt="" onClick={() => setFieldValueData(item)} style={{ marginRight: '5px' }} />
                        : <img src={Save} alt="" onClick={() => updateFormData(item)} style={{ marginTop: '10px' }} />}

                      <img src={deleteImg} alt="" onClick={() => setDeleteFlag(item)} width="28px" />
                      {item.rosterStatus === 'DRAFT' ?
                        <img src={post} alt="" onClick={() => postFormData(item)} width="28px" style={{ opacity: 1 }} />
                        : <img src={post} alt="" width="28px" style={{ opacity: 0.5, pointerEvents: 'none' }} />}
                    </div>
                  </div>


                </div>
              )) : <div> </div>}

          </Form>

        </div>
        <Modal show={isDelete} onHide={closeModal}>
          <Modal.Header closeButton> </Modal.Header>
          <Modal.Body>
            Are you sure you want delete <b>Roster</b> ?
          </Modal.Body>
          <Modal.Footer>
            <div className="displayflexforpopupdelete">
              <Button
                variant="secondary"
                className="yesBtninpopup"
                onClick={deleteFormData}
              >
                Yes
              </Button>
              <Button
                variant="secondary"
                className="noBtninpopup"
                onClick={closeModal}
              >
                No
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </LoadingOverlay>


    </div>
  )
}

export default RosterSchedule;