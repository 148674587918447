import React, { useEffect, useState } from 'react';
import { BrowserRouter,  Route } from 'react-router-dom';
import ForgotPassword from './components/ForgotPassword';
import Login from './components/Login';
import NavigationBar from './components/NavigationBar';
import Policy from './components/Policy';
import ProtectedRoute from './ProtectedRoute';
import SettingsPage from './components/Settings/UpdateProfile'


function App() {

  const [auth,setAuth] = useState(false);
  const checkAuth =() =>{
    setAuth(sessionStorage.getItem('token') ? true: false);
  }
  useEffect(()=>{
    checkAuth();
  },[]);
  return (
    <div className="App">
      <BrowserRouter>

        <div className="content">

          <Route exact path="/" component={Login} />
          <Route exact path="/privacy-policy" component={Policy} />
          <Route exact path="/forgotpassword" component={ForgotPassword} />
          <ProtectedRoute path="/employee" component={NavigationBar} />
          <ProtectedRoute path="/attendance" component={NavigationBar} />
          <ProtectedRoute path="/location" component={NavigationBar} />
          <ProtectedRoute path="/notification" component={NavigationBar} />
          <ProtectedRoute path="/expensesheet" component={NavigationBar}/>
          <ProtectedRoute path="/dashboard" component={NavigationBar}/>
          <ProtectedRoute path="/schedule" component={NavigationBar}/>
          <ProtectedRoute path="/roster" component={NavigationBar}/>
          <ProtectedRoute path="/settings" component={NavigationBar}/>
        </div>

      </BrowserRouter>
    </div>
  );
}

export default App;
