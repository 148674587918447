import React, { useEffect, useState } from "react";
import axios from "axios";
import "./style.css";
import Table from "react-bootstrap/Table";
import check from "../images/check.svg";
import cross from "../images/cross.svg";
import holiday from "../images/holiday.svg";
import weekEnd from "../images/weekEnd.svg";
import profile from "../images/Group34.png";
import Pagination from "react-js-pagination";
import { Modal, Button, Form } from "react-bootstrap";
import *  as constants from "../Utils/URLConstants";
import { useRef } from "react";

function Attendance() {
  // current page
  const [currentPage, setCurrentPage] = useState(1);

  const [isLoading, setIsLoading] = useState(0);
  // handle change event
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setIsLoading(isLoading + 1);
    // call API to get data based on pageNumber
  };


  const datesVal = (dates, val, holidays, weekEnds) => {

    var today = new Date();
    var dayNum = today.getDate();
    var monthNum = today.getMonth() + 1;
    var yearNum = today.getFullYear();

    if (year < yearNum || (month < monthNum && year == yearNum) || (val < dayNum && year == yearNum && month == monthNum)) {
      if (dates.includes(val)) {
        return (
          <td align="center">
            <img src={check}></img>
          </td>
        );
      } else if (holidays.includes(val)) {
        return (
          <td align="center">
            <img src={holiday}></img>
          </td>
        );
      } else if (weekEnds.includes(val)) {
        return (
          <td align="center">
            <img src={weekEnd}></img>
          </td>
        );
      } else {
        return (
          <td align="center">
            <img src={cross}></img>
          </td>
        );
      }
    } else {
      if (holidays.includes(val)) {
        return (
          <td align="center">
            <img src={holiday}></img>
          </td>
        );
      } else if (weekEnds.includes(val)) {
        return (
          <td align="center">
            <img src={weekEnd}></img>
          </td>
        );
      } else {
        return <td align="center"></td>;
      }
    }
  };
  var dateObj = new Date();
  const [searchYear, setSearchYear] = useState(dateObj.getFullYear())
  const [searchMonth, setSearchMonth] = useState(dateObj.getMonth() + 1);
  const [attendance, setAttendance] = useState([]);
  const [month, setMonth] = useState(dateObj.getMonth() + 1);
  const [year, setYear] = useState(dateObj.getFullYear());
  const [reload, setReload] = useState(false);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecordsInEmp, setTotalRecordsInEmp] = useState(0);
  const [checkSearch, setCheckSearch] = useState(false);
  let monthDates = Array.from(Array(new Date(year, month, 0).getDate()).keys());
  const [isDelete, setIsDelete] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [userNames, setUserNames] = useState([]);
  const searchRef = useRef(null);
  const [formFieldsDates, setFormFieldsDates] = useState(
    {
      startDate: '',
      endDate: ''
    }
  )

  const closeModal = () => {
    // setDeleteId(0);
    setFormFieldsDates({ startDate: '', endDate: '' })
    setIsSubmitted(false);
    setIsDelete(false);
  }
  const fetchattendance = () => {
    searchMonth && setMonth(searchMonth);
    searchYear && setYear(searchYear)
    if (checkSearch) {
      axios
        .get(constants.ATTENDANCE_VIEW, {
          params: {
            month: searchMonth,
            year: searchYear,
            pageNumber: 1,
            pageSize: 15,
            name: search
          },

          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setAttendance(res.data?.details);
          setCheckSearch(false);
          setTotalPages(res?.data?.pageableResponse.totalPages);
          setTotalRecordsInEmp(res?.data?.pageableResponse.totalElements);
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
    else {
      setSearch('');
      axios
        .get(constants.ATTENDANCE_VIEW, {
          params: {
            month: searchMonth,
            year: searchYear,
            pageNumber: currentPage,
            pageSize: 15,
          },

          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setAttendance(res.data?.details);

          setTotalPages(res?.data?.pageableResponse.totalPages);
          setTotalRecordsInEmp(res?.data?.pageableResponse.totalElements);
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  };


  const downloadData = (val) => {
    let data = {
      "startDate": formFieldsDates.startDate,
      "endDate": formFieldsDates.endDate,
      "userNames": userNames
    }
    if (val.startDate === '' || val.endDate === '') {
      setIsSubmitted(true);
    }
    else {
      axios
        .post(
          constants.DOWNLOAD_DATA, {},

          {

            data,

            headers: {

              Authorization: sessionStorage.getItem("token"),
              "Content-Type": "application/json",
            },
          }
        )
        .then(async (res) => {


          const link = document.createElement('a');
          link.href = await res.data;
          link.setAttribute('download', `Report--${+new Date()}.xlsx`);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          closeModal();

          setUserNames([]);
        })
        .catch((error) => {
          console.error("error", error);
        });
    }

  }



  const handleCheckboxChange = (event) => {
    const { name } = event.target;
    setUserNames(prevSelectedItems => {
      if (prevSelectedItems.includes(name)) {
        return prevSelectedItems.filter(item => item !== name);
      } else {
        return [...prevSelectedItems, name];
      }
    });
  };

 //Enter key logic for search
 function handleKeyPress(event) {

  if (event.key === 'Enter') {
    // Perform search functionality here
    handleSearch();
  }
}




  useEffect(() => {
    fetchattendance();
  }, [isLoading]);

  const handleSearch = () => {

    fetchattendance();
  };
  return (
    <div>
      <div id="dashboardlocation">
        <span
          style={{
            color: "#66676c",
            fontSize: "13px",
          }}
        >
          Dashboard &gt; Attendance
        </span>
      </div>
      <div className="attendtopsearch">
        <input
          type="text"
          id="attendSearch"
          placeholder="Search By Employee"
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            setCheckSearch(true);
          }}
          onKeyUp={handleKeyPress}
        />

        <input type="month" onChange={(e) => {
          if (e.target.value !== null) {
            const month_year = e.target.value.split('-');
            setSearch("");
            setSearchMonth(month_year[1]);
            setSearchYear(month_year[0])
          }

        }} className="MonthInAttendance" />

        <input
          type="button"
          id="attendsearchbtn"
          defaultValue="Search"
          onClick={handleSearch}
        />

        <input
          type="button"
          id="attendsearchbtn"
          className="bg-success"
          defaultValue="Export"
          
          onClick={() => setIsDelete(true)}
        />
      </div>
      <div className="attendDivision mapDivision">
        <Table striped bordered hover className=".p-9 .w-10 tableinAttendance">
          <thead>
            <tr className="tableheader">
              <th>Employee Name</th>
              {monthDates && monthDates.map(d => (<th>{d + 1}</th>))}
            </tr>
          </thead>
          <tbody>
            {attendance?.length === 0 ? <div style={{ position: 'absolute', top: '35vh', left: '60vh', fontSize: '20px', fontWeight: 'bold' }}>No User Found</div> : attendance?.map((item, i) => (
              <tr key={i}>
                <td className="wrapInAttendance">
                  <input type="checkbox" name={item.userName} checked={userNames.includes(item.userName)} onChange={handleCheckboxChange} className="" id={item.userName} style={{ margin: 'unset', width: 'unset' }} />
                  <div>
                    {item.imageUrl === null ? (
                      <img
                        src={profile}
                        style={{
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                          marginRight: "15px",
                        }}
                      />
                    ) : (
                      <img
                        src={item.imageUrl}
                        style={{
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                          marginRight: "15px",
                        }}
                      />
                    )}
                  </div>
                  <div>{item.fname}</div>
                </td>
                {monthDates && monthDates.map(d => (datesVal(item.dates, d + 1, item.holidays, item.weekEnds)))}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <div className="paginationInAttendance">
        <Pagination
          itemClass="page-item" // add it for bootstrap 4
          linkClass="page-link" // add it for bootstrap 4
          activePage={currentPage}
          itemsCountPerPage={15}
          totalItemsCount={totalRecordsInEmp}
          pageRangeDisplayed={totalPages}
          onChange={handlePageChange}
        />
      </div>

      <Modal show={isDelete} onHide={closeModal}>
        <Modal.Header closeButton> </Modal.Header>
        <Modal.Body>
          <div>
            <h5 style={{ textAlign: 'center', marginTop: 'unset' }}>Select Date Range</h5>
            <Form className="d-flex justify-content-around">
              <div className="attendanceFormControl">
                <span style={{ fontWeight: 'bold' }}>Start Date <span style={{ color: 'red' }}>*</span> :</span>
                <Form.Control type="date" name="" id="" style={{ width: 'unset', height: '60%', padding: '4px 25px' }} isInvalid={isSubmitted && formFieldsDates.startDate === ''} onChange={(e) => setFormFieldsDates({ ...formFieldsDates, startDate: e.target.value, endDate: '' })} />
              </div>

              <div className="attendanceFormControl">
                <span style={{ fontWeight: 'bold' }}>End Date <span style={{ color: 'red' }}>*</span> :</span>
                <Form.Control type="date" name="enddate" id="" style={{ width: 'unset', height: '60%', padding: '4px 25px' }} min={formFieldsDates.startDate.toString().slice(0, 10)} isInvalid={isSubmitted && formFieldsDates.endDate === ''} onChange={(e) => setFormFieldsDates({ ...formFieldsDates, endDate: e.target.value })} />
              </div>
            </Form>



          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="displayflexforpopupdelete">

            <Button
              variant="secondary"
              className="noBtninpopup"
              style={{ width: 'unset', marginLeft: '2%' }}
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              variant="secondary"
              className="yesBtninpopup"
              style={{ width: 'unset', marginLeft: '2%' }}
              onClick={() => downloadData(formFieldsDates)}
            >
              Download
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Attendance;
