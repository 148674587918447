import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import axios from "axios";
import * as constants from "../../Utils/URLConstants";
import ReactSnackBar from "react-js-snackbar";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import LoadingOverlay from 'react-loading-overlay';


function CreateScheduleTable({ cancelStatus, existScheduleName, isEdit }) {

  const defaultScheduleCreationData = [
    {
      "hours": {
        0: "NA",
        1: "NA",
        2: "NA",
        3: "NA",
        4: "NA",
        5: "NA",
        6: "NA",
        7: "NA",
        8: "NA",
        9: "NA",
        10: "NA",
        11: "NA",
        12: "NA",
        13: "NA",
        14: "NA",
        15: "NA",
        16: "NA",
        17: "NA",
        18: "NA",
        19: "NA",
        20: "NA",
        21: "NA",
        22: "NA",
        23: "NA"      },
      "dayOfWeek": "MONDAY"
    },
    {
      "hours": {
        0: "NA",
        1: "NA",
        2: "NA",
        3: "NA",
        4: "NA",
        5: "NA",
        6: "NA",
        7: "NA",
        8: "NA",
        9: "NA",
        10: "NA",
        11: "NA",
        12: "NA",
        13: "NA",
        14: "NA",
        15: "NA",
        16: "NA",
        17: "NA",
        18: "NA",
        19: "NA",
        20: "NA",
        21: "NA",
        22: "NA",
        23: "NA"
      },
      "dayOfWeek": "TUESDAY"
    },
    {
      "hours": {
        0: "NA",
        1: "NA",
        2: "NA",
        3: "NA",
        4: "NA",
        5: "NA",
        6: "NA",
        7: "NA",
        8: "NA",
        9: "NA",
        10: "NA",
        11: "NA",
        12: "NA",
        13: "NA",
        14: "NA",
        15: "NA",
        16: "NA",
        17: "NA",
        18: "NA",
        19: "NA",
        20: "NA",
        21: "NA",
        22: "NA",
        23: "NA"
      },
      "dayOfWeek": "WEDNESDAY"
    },
    {
      "hours": {
        0: "NA",
        1: "NA",
        2: "NA",
        3: "NA",
        4: "NA",
        5: "NA",
        6: "NA",
        7: "NA",
        8: "NA",
        9: "NA",
        10: "NA",
        11: "NA",
        12: "NA",
        13: "NA",
        14: "NA",
        15: "NA",
        16: "NA",
        17: "NA",
        18: "NA",
        19: "NA",
        20: "NA",
        21: "NA",
        22: "NA",
        23: "NA"
      },
      "dayOfWeek": "THURSDAY"
    },
    {
      "hours": {
        0: "NA",
        1: "NA",
        2: "NA",
        3: "NA",
        4: "NA",
        5: "NA",
        6: "NA",
        7: "NA",
        8: "NA",
        9: "NA",
        10: "NA",
        11: "NA",
        12: "NA",
        13: "NA",
        14: "NA",
        15: "NA",
        16: "NA",
        17: "NA",
        18: "NA",
        19: "NA",
        20: "NA",
        21: "NA",
        22: "NA",
        23: "NA"
      },
      "dayOfWeek": "FRIDAY"
    },
    {
      "hours": {
        0: "NA",
        1: "NA",
        2: "NA",
        3: "NA",
        4: "NA",
        5: "NA",
        6: "NA",
        7: "NA",
        8: "NA",
        9: "NA",
        10: "NA",
        11: "NA",
        12: "NA",
        13: "NA",
        14: "NA",
        15: "NA",
        16: "NA",
        17: "NA",
        18: "NA",
        19: "NA",
        20: "NA",
        21: "NA",
        22: "NA",
        23: "NA"
      },
      "dayOfWeek": "SATURDAY"
    },

    {
      "hours": {
        0: "NA",
        1: "NA",
        2: "NA",
        3: "NA",
        4: "NA",
        5: "NA",
        6: "NA",
        7: "NA",
        8: "NA",
        9: "NA",
        10: "NA",
        11: "NA",
        12: "NA",
        13: "NA",
        14: "NA",
        15: "NA",
        16: "NA",
        17: "NA",
        18: "NA",
        19: "NA",
        20: "NA",
        21: "NA",
        22: "NA",
        23: "NA"
      },
      "dayOfWeek": "SUNDAY"
    },
  ];
  const snackEnable = () => {
    setTimeout(() => {
      setShowSnackBar(false);
    }, 4000);
  }
  const [loading, setLoading] = useState(false);
  const [defaultData, setDefualtData] = useState(defaultScheduleCreationData);
  const [exisitngData, setExistingData] = useState(null);
  const [id, setId] = useState(null);
  let initialRowDataAPI = existScheduleName === null ? defaultData : exisitngData;
  const getScheduleDataByName = () => {
    if (existScheduleName !== null) {
      setLoading(true);
      axios
        .get(constants.GET_SCHEDULE_SEARCH_BY_NAME, {
          params: {
            name: existScheduleName
          },

          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          setId(result.data.id)
          setExistingData(result.data.details);
          setLoading(false);
        })
        .catch((error) => {
           setLoading(false);
          console.log("failed with error ", error);
        });
    }
  }
  useEffect(() => {
    getScheduleDataByName();
  }, []);


  const columnDefs = [
    {
      headerName: 'Day of Week',
      field: 'dayOfWeek',
      minWidth: 150,
      maxWidth: 200,
      pinned: 'left',
    },
    {
      headerName: "0",
      field: 'hours.0',
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,

      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },

    {
      headerName: "1",
      field: 'hours.1',
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,

      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },
    {
      headerName: "2",
      field: 'hours.2',
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,

      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },

    {
      headerName: "3",
      field: "hours.3",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,
      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },
    {
      headerName: "4",
      field: "hours.4",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,
      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },
    {
      headerName: "5",
      field: "hours.5",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,
      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },
    {
      headerName: "6",
      field: "hours.6",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,
      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },
    {
      headerName: "7",
      field: "hours.7",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,
      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },
    {
      headerName: "8",
      field: "hours.8",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,
      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },
    {
      headerName: "9",
      field: "hours.9",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,
      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },
    {
      headerName: "10",
      field: "hours.10",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,

      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },

    {
      headerName: "11",
      field: "hours.11",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,

      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },
    {
      headerName: "12",
      field: "hours.12",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,

      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },

    {
      headerName: "13",
      field: "hours.13",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,
      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },
    {
      headerName: "14",
      field: "hours.14",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,
      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },
    {
      headerName: "15",
      field: "hours.15",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,
      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },
    {
      headerName: "16",
      field: "hours.16",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,
      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },
    {
      headerName: "17",
      field: "hours.17",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,
      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },
    {
      headerName: "18",
      field: "hours.18",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,
      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },
    {
      headerName: "19",
      field: "hours.19",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,
      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },
    {
      headerName: "20",
      field: "hours.20",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,
      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        //placeholder: 'NA'
      },
      minWidth: 100,
    },
    {
      headerName: "21",
      field: "hours.21",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,
      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },
    {
      headerName: "22",
      field: "hours.22",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,
      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    },
    {
      headerName: "23",
      field: "hours.23",
      maxWidth: 60,
      editable: true,
      cellEditor: 'agSelectCellEditor',

      singleClickEdit: true,
      cellEditorParams: {
        values: ['OC', 'OD', 'OFF', 'NA'],
        suppressValues: true,
        placeholder: 'NA'
      },
      minWidth: 100,
    }
  ];

  const [emoji, setEmoji] = useState("");
  const [snackmessage, setSnackMessage] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);

  const [scheduleName, setScheduleName] = useState(existScheduleName);


  const createSchedule = (initialRowDataAPI) => {
    setLoading(true);
    axios
      .post(
        constants.CREATE_SCHEDULE,
        {
          "name": scheduleName,
          "isEnabled": true,
          "details": initialRowDataAPI
        },

        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setSnackMessage("Schedule Created Successfully !");
        setShowSnackBar(true);
        setEmoji("✔️");
        cancelStatus(false);
        snackEnable();
        setLoading(false);
      })
      .catch((error) => {
        setSnackMessage("Invalid Schedule Name");
        setShowSnackBar(true);
        setEmoji("❌");
        snackEnable();
        setLoading(false);
      });
  };

  const updateSchedule = (initialRowDataAPI) => {
    setLoading(true);
    axios
      .put(
        constants.CREATE_SCHEDULE,
        {
          "id": id,
          "name": scheduleName,
          "isEnabled": true,
          "details": initialRowDataAPI
        },

        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setSnackMessage("Schedule Updated Successfully !");
        setEmoji("✔️");
        setLoading(false);
        setShowSnackBar(true);
        cancelStatus(false);
        snackEnable();
      })
      .catch((error) => {
        setSnackMessage("Invalid Schedule Name :Schedule Name Exist in DB Please Provide new Schedule Name")
        setEmoji("❌");
        setLoading(false);
        snackEnable();
        setShowSnackBar(true);
      });
  };

  return (
    <div>
      <p>Dashboard &gt; Schedule &gt; Create Schedule</p>
      <ReactSnackBar autoHideDuration={3000} Icon={<span>{emoji}</span>} Show={showSnackBar}>
        {snackmessage}
      </ReactSnackBar>
      <LoadingOverlay active={loading} spinner text="Creating/Updating... " >
      <div className="bg-white p-3 rounded d-flex justify-content-between">
        <div>
          <input
            type="text"
            name=""
            id=""
            placeholder="Enter Schedule Name"
            value={scheduleName}
            onChange={e => { setScheduleName(e.target.value); setShowSnackBar(false) }}
            className="border border-black px-3 py-1 widthForInput rounded mx-4"
            style={{ all: 'unset' }}
          />
        </div>
        {/* Cancel and Save Buttons in header */}
        <div className=" w-25">
          <button className="btn btn-danger bg-white text-danger py-0 widthForButtons" onClick={e => {
            e.preventDefault();

            cancelStatus(false);
          }}>
            Cancel
          </button>
          <button className="btn btn-primary py-1 mx-4  pt-0 widthForButtons" onClick={e => {
            //  e.preventDefault();
            if (scheduleName === null || scheduleName === "") {
              setShowSnackBar(true);
              setSnackMessage("Schedule Name Should Not be Blank!")
              setEmoji("❌");
            }
            else {
              isEdit ? updateSchedule(initialRowDataAPI) : createSchedule(initialRowDataAPI);
            }
          }} >Save</button>
        </div>
      </div>

      <div className="ag-theme-alpine mt-3" style={{ height: '400px', width: '100%' }}>
        <AgGridReact
          rowData={isEdit ? exisitngData : defaultData}
          columnDefs={columnDefs}
          onCellValueChanged={params => {
            const obj = initialRowDataAPI.find((user) => {
              return user.dayOfWeek === params.data.dayOfWeek;
            });
            if (obj === undefined) {
              initialRowDataAPI.push(params.data);
            }
          }}
        />
      </div>
      </LoadingOverlay>
    </div>
  );
};

export default CreateScheduleTable;
