import React, { useEffect, useState } from "react";
// import { Tab, Tabs} from 'react-tabs';
import Tab from "./Tab";
import Tabs from "./Tabs";
import axios from "axios";
import msg_img from "../images/Notification_Msg_img.svg";
import LoadingOverlay from 'react-loading-overlay';

import { Modal, Button, Form } from "react-bootstrap";
import *  as constants from "../Utils/URLConstants";

function Notification() {
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [subject, setsubject] = useState("");
  const [body, setbody] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const maxFileSize = 8;
  const [validation,setValidation] = useState(0);
  const isHr=sessionStorage.getItem("isHr");

  const closeModal = () => {
    setIsOpen(false);
    setValidation(0);
  };
  const fileSizeMsg = {
    position:'relative',
    top:'20px',
    color:validation<=8?'':'red'
  }
  const sendmail = () => {
   
    let dataArray = new FormData();
    dataArray.append("message", body);
    dataArray.append("file", file);
    dataArray.append("userName", "");
    dataArray.append("title", subject);
  if(validation<=maxFileSize)
  {
    setValidation(0);
    setLoading(true);
    axios
    .post(constants.SEND_NOTIFICATON, dataArray, {
      headers: {
        Authorization: sessionStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      setIsOpen(false);
      setsubject("");
      setLoading(false);
      dataArray = "";
      setbody("");
    })
    .catch((error) => {
      console.error("error", error);
    });
  }
  
    
  };

  const loadNotifications = () => {
    axios
      .get(constants.NOTIFICATIONS, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setMessages(res.data);
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  useEffect(() => {
    loadNotifications();
  }, []);
  return (
    <div>

      <div className="notificationDetails">
        <div id="dashboardlocationaddemployee">
          <span style={{ color: "#66676c", fontSize: "13px" }}>
            Dashboard &gt; Notification
          </span>

          {isHr==='true'?
          <button
            className="createButton"
            onClick={(event) => {
              if (!event.defaultPrevented) {

                setIsOpen(true);
              }
            }}
          >
            Create Notification
          </button>:""
        }
        </div>

        <div>
          <Modal show={isOpen} onHide={closeModal}>
            <LoadingOverlay
              active={loading}
              spinner
              text="Sending Notification"

            >
              <Modal.Header closeButton>
                <Modal.Title>Notification</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group>
                  <label className="chexkboxinNotification">
                    Broadcast to all
                  </label>

                  <Form.Control
                    type="text"
                    className="formControlinEmpPopup"
                    placeholder="Subject"
                    onChange={(e) => setsubject(e.target.value)}
                  />

                  {/* {imgId !== 3 ? <Form.Control type="text" onChange={e => setsubject(e.target.value)} value={subject} className="formControlinEmpPopup" placeholder="Enter Subject" /> : ""} */}
                  <Form.Control
                    as="textarea"
                    className="formControlinEmpPopup"
                    onChange={(e) => setbody(e.target.value)}
                    placeholder="Enter Text"
                    style={{ minHeight: "100px" }}
                  />

                  <Form.Control
                    type="file"
                    // rows="3"
                    onChange={(e) => {
                      if(( (e.target.files[0].size/1024)/1024) <= maxFileSize) {
                        setValidation(3);
                        setFile(e.target.files[0])
                      }
                      else{
                        setFile("");
                        setValidation(9);
                      }
                    }}
                    // value={body}
                    className="formControlinEmpPopup"
                  />
                   
                    <p style={fileSizeMsg}>(Max File SIze: 8MB)</p>
                  

                </Form.Group>
              </Modal.Body>
              <Modal.Footer>

                <Button className="cancelBtninpopup" onClick={closeModal}>
                  Cancel
                </Button>


                <Button className="notificationBtninpopup" onClick={sendmail}>
                  Send Notification
                </Button>


              </Modal.Footer>
            </LoadingOverlay>
          </Modal>
        </div>
        <div className="TabsContentInNotification">
          {/* <p style={{display:'flex',justifyContent:'center',alignItems:'center'}}>Select Tab</p> */}
          <Tabs>
            <Tab
              value="Notification"
              header="Notification"
              class="tab-pane fade show active"
            >
              <div className="notify">


                {messages.map((item, i) => (
                  <div key={i}>
                    <p className="ContentInNotification">
                      <img src={msg_img} alt="img" />
                      <span className="MessageInNotification">
                        <b><u>Title</u>:-  {item.title}</b>
                        <br />
                        {item.message}
                        <br />
                        <b>{item.timeWords}</b>
                      </span>
                    </p>
                  </div>
                ))}
              </div>
              {/* <p>Request</p> */}
            </Tab>
            {/* <Tab
              value="My Request"
              header="My Request"
              className="MyRequestInNotification"
            >
              <div className="notify">
               
              </div>
            </Tab> */}
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default Notification;