import React from 'react'
import { Route,Redirect } from 'react-router-dom'

function ProtectedRoute({ component: Component, ...rest}) {
  return (
    <Route  
    {...rest}
     render = {(props)=>{
        if(sessionStorage.getItem('token'))
        {
           
        return <Component {...props} />;
        }
        if(!sessionStorage.getItem('token')){
            
            return <Redirect to = {{path:"/",state:{from: props.location}}} />
        } 
    }}/>

    
        

    
    
  )
}

export default ProtectedRoute