import Button from "react-bootstrap/Button";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import * as constants from "../Utils/URLConstants";
import ReactSnackBar from "react-js-snackbar";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import deleteImg from "../images/delete.svg";
import Multiselect from 'multiselect-react-dropdown';

const phoneRegExp = /^([+]?\d{1,3}[.-\s]?)?(\d{3}[.-]?){2}\d{4}?$/

const state = {
  options: [{ name: 'Option 1️', id: 1 }, { name: 'Option 2️', id: 2 }, { name: 'Option 3', id: 3 }, { name: 'Option 4', id: 4 }],
  Basics_Telecom: [{ label: "RF Engineering", value: "RF Engineering" }, { label: "RF Planning", value: "RF Planning" }, { label: "RF Optimization", value: "RF Optimization" }, { label: "Microwave Knowledge", value: "Microwave Knowledge" }, { label: "General Transmission", value: "General Transmission" }, { label: "General Fiber Knowledge", value: "General Fiber Knowledge" }, { label: "Networking Knowledge", value: "Networking Knowledge" }, { label: "NMS Design", value: "NMS Design" }, { label: "MPLS Concept", value: "MPLS Concept" }, { label: "WIFI Technology", value: "WIFI Technology" }, { label: "2G Technology Knowledge", value: "2G Technology Knowledge" }, { label: "3G Technology Knowledge", value: "3G Technology Knowledge" }, { label: "LTE Technology Knowledge", value: "LTE Technology Knowledge" }, { label: "Alarm Handling", value: "Alarm Handling" }, { label: "RTTS & F/O", value: "RTTS & F/O" }],
  Design: [{ label: "IBS Design Reviewing", value: "IBS Design Reviewing" }, { label: "IBS Designing Basics", value: "IBS Designing Basics" }, { label: "Ibwave", value: "Ibwave" }],
  BTS_Vendor: [{ label: "CISCO Switches", value: "CISCO Switches" }, { label: "Nokia BTS", value: "Nokia BTS" }, { label: "Ericsson RBS", value: "Ericsson RBS" }, { label: "Huawei BTS", value: "Huawei BTS" }],
  RF_Testing: [{ label: "TEMS WALK TESTING", value: "TEMS WALK TESTING" }, { label: "BENCHMARKING", value: "BENCHMARKING" }, { label: "Post Processing", value: "Post Processing" }, { label: "Post Processing Investigation", value: "Post Processing Investigation" }],
  Tech_Field_Skills: [{ label: "Antenna Knowledge", value: "Antenna Knowledge" }, { label: "Telecom Passive Knowledge", value: "Telecom Passive Knowledge" }, { label: "Telecom Passive Installation", value: "Telecom Passive Installation" }, { label: "RF Connector Splicing", value: "RF Connector Splicing" }, { label: "A/C Split Troubleshooting", value: "A/C Split Troubleshooting" }, { label: "A/C Stand Troubleshooting", value: "A/C Stand Troubleshooting" }, { label: "ABB HVAC Troubleshooting", value: "ABB HVAC Troubleshooting" }, { label: "HVAC Troubleshooting", value: "HVAC Troubleshooting" }, { label: "Delta Rectifier Troubleshooting", value: "Delta Rectifier Troubleshooting" }, { label: "Rectifier Troubleshooting", value: "Rectifier Troubleshooting" }, { label: "MDB troubleshooting", value: "MDB troubleshooting" }, { label: "Battery Testing", value: "Battery Testing" }, { label: "Alarms Testing", value: "Alarms Testing" }, { label: "Alarms Connection", value: "Alarms Connection" }, { label: "UPS Troubleshooting", value: "UPS Troubleshooting" }, { label: "Ferion Filling", value: "Ferion Filling" }, { label: "HVAC Board Level Repairing", value: "HVAC Board Level Repairing" }, { label: "HVAC PLC Programming", value: "HVAC PLC Programming" }],
  Basic_Instrumentation: [{ label: "PIM Testing", value: "PIM Testing" }, { label: "Site Master use", value: "Site Master use" }, { label: "Fiber Splicing", value: "Fiber Splicing" }, { label: "OTDR reading", value: "OTDR reading" }, { label: "Spectrum Analyzer Using", value: "Spectrum Analyzer Using" }, { label: "TEMS POCKET", value: "TEMS POCKET" }],
  Basics_Electrt: [{ label: "Electrical Knowledge", value: "Electrical Knowledge" }, { label: "Mechanical Knowledge", value: "Mechanical Knowledge" }, { label: "Electrical Designing", value: "Electrical Designing" }, { label: "Electrical Design Reading", value: "Electrical Design Reading" }],
  Cabling: [{ label: "Electrical Cabling", value: "Electrical Cabling" }, { label: "RF Cabling", value: "RF Cabling" }, { label: "Fiber Cabling", value: "Fiber Cabling" }],
  Repair: [{ label: "General Board Level Repairing", value: "General Board Level Repairing" }, { label: "UPS Board Level Repairing", value: "UPS Board Level Repairing" }, { label: "Rectifies Board Level Reparing", value: "Rectifies Board Level Reparing" }],
  Skills_and_Repairing: [{ label: "Electrical Tools using", value: "Electrical Tools using" }, { label: "AutoCAD Skills", value: "AutoCAD Skills" }, { label: "General Tools Using Skills", value: "General Tools Using Skills" }, { label: "Microsoft Office Skills", value: "Microsoft Office Skills" }, { label: "Driving Skills", value: "Driving Skills" }, { label: "Reporting and Documentation Skills", value: "Reporting and Documentation Skills" }, { label: "Arabic Language Skills", value: "Arabic Language Skills" }, { label: "English Language Skills", value: "English Language Skills" }, { label: "Aurdo Language Skills", value: "Aurdo Language Skills" }, { label: "Tango Language Skills", value: "Tango Language Skills" }, { label: "French Language Skills", value: "French Language Skills" }, { label: "Generator Skills", value: "Generator Skills" }, { label: "Air-con Skills", value: "Air-con Skills" }, { label: "HVAC Repairing", value: "HVAC Repairing" }, { label: "Rectifier Repairing", value: "Rectifier Repairing" }, { label: "Fire alarm Repairing", value: "Fire alarm Repairing" }, { label: "Alarms Repairing", value: "Alarms Repairing" }, { label: "Alarms Cleaning", value: "Alarms Cleaning" }, { label: "Shelter Cleaning", value: "Shelter Cleaning" }, { label: "Housekeeping", value: "Housekeeping" }],
  Evaluation: [{ label: "Attendance", value: "Attendance" }, { label: "Productivity", value: "Productivity" }, { label: "Quality Of Work", value: "Quality Of Work" }, { label: "Functional knowledge", value: "Functional knowledge" }, { label: "writing skills", value: "writing skills" }, { label: "Communication Skills", value: "Communication Skills" }, { label: "Preparation and follow-up", value: "Preparation and follow-up" }, { label: "Teamwork", value: "Teamwork" }, { label: "Solving problems", value: "Solving problems" }, { label: "Relations with staff", value: "Relations with staff" }, { label: "Relations with management", value: "Relations with management" }, { label: "Work behavior", value: "Work behavior" }, { label: "Career advancement", value: "Career advancement" }],
  AdvEva: [{ label: "Leadership team", value: "Leadership team" }, { label: "Decision-making", value: "Decision-making" }, { label: "Initiative and innovation", value: "Initiative and innovation" }]
};




const schema = yup.object().shape({
  userName: yup.string().required(),
  fname: yup.string().required(),
  mobileNumber: yup
    .string()
    .required()
    .matches(phoneRegExp, "Mobile number is not valid"),
  fieldMobile: yup
    .string()
    .required()
    .matches(phoneRegExp, "Mobile number is not valid"),
  emailAddress: yup.string().required().email(),
  section: yup.string().required(),
  jobTitle: yup.string().required(),
  department: yup.string().required(),
  region: yup.string().required(),
  reportingManager: yup.string().required()
});

function EditEmployee({
  userName,
  userImg,
  setCancelStatus,
  isReloadStatus,
  checkEmployeeCreated,
}) {

  const handleBasicSkillChange = (event) => {
    if (isEditMode) {
      if (event.target.value === "Basics Telecom") {
        setSubSkillOp(state.Basics_Telecom);
      } else if (event.target.value === "Design") {
        setSubSkillOp(state.Design);
      } else if (event.target.value === "BTS Vendor") {
        setSubSkillOp(state.BTS_Vendor);
      } else if (event.target.value === "RF Testing") {
        setSubSkillOp(state.RF_Testing);
      } else if (event.target.value === "Tech Field Skills") {
        setSubSkillOp(state.Tech_Field_Skills);
      } else if (event.target.value === "Basic Instrumentation") {
        setSubSkillOp(state.Basic_Instrumentation);
      } else if (event.target.value === "Basics Electrt") {
        setSubSkillOp(state.Basics_Electrt);
      } else if (event.target.value === "Cabling") {
        setSubSkillOp(state.Cabling);
      } else if (event.target.value === "Repair") {
        setSubSkillOp(state.Repair);
      } else if (event.target.value === "Skills and Repairing") {
        setSubSkillOp(state.Skills_and_Repairing);
      } else if (event.target.value === "Evaluation") {
        setSubSkillOp(state.Evaluation);
      } else {
        setSubSkillOp(state.AdvEva);
      }
    }
    SetBasicSkillSelected(event.target.value);
    multiselectRefTracker.current.resetSelectedValues(['1'])

  }

  const multiselectRefTracker = React.createRef();
  const skillSelectRefTracker = React.createRef();

  const fetchUserDetails = () => {

    axios
      .get(constants.GET_AUTH_USER_DETAILS, {
        params: { userName: userName },

        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then(async (result) => {
        await result.data;

        setCurrentEmpData(result.data);
        setFinalSkills(result.data.skills);

      })
      .catch((error) => {
        console.log("failed with error ", error);
      });
  };

  const fetchConfig = () => {

    axios
      .get(constants.GET_EMP_META_DATA, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then(async (result) => {
        await result.data;
        setJobTitleOp(result.data?.JOB_TITLE);
        setDepartmentOp(result.data?.DEPARTMENT);
        setRegionOp(result.data?.REGION);
      })
      .catch((error) => {
        console.log("failed with error ", error);
      });
  };
  const fetchSectionConfig = () => {

    axios
      .get(constants.GET_EMP_SECTION_META_DATA, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then(async (result) => {
        await result?.data;

        setSectionOp(result?.data);
      })
      .catch((error) => {
        console.log("failed with error ", error);
      });
  };
  useEffect(() => {
    fetchConfig();
    fetchSectionConfig();
    fetchUserDetails();
  }, []);

  const [loading, setLoading] = useState(false);
  const [errMsg, setErrmsg] = useState("");
  const [showSnackBar, setShowSnackBar] = useState(false);
  const snackEnable = () => {
    setTimeout(() => {
      setShowSnackBar(false);
    }, 4000);
  };
  const [jobTitleOp, setJobTitleOp] = useState("");
  const [departmentOp, setDepartmentOp] = useState("");
  const [regionOp, setRegionOp] = useState("");
  const [sectionOp, setSectionOp] = useState("");
  const [basicSkillOp, setBasicSkillOp] = useState("");
  const [subSkillOp, setSubSkillOp] = useState([]);
  const [basicSkillSelected, SetBasicSkillSelected] = useState("");
  const [finalSkills, setFinalSkills] = useState([]);
  const [currentEmpData, setCurrentEmpData] = useState({});
  const [reportingManagerdata, setReportingManagerData] = useState([]);
  const [filterSearch, setFilterSearch] = useState(false);
  const [userKey, setUserKey] = useState("");
  const [isEditMode, setIsEditMode] = useState(false);
  const isHr = sessionStorage.getItem("isHr");
  const addSelected = () => {
    const uniqueTags = [];
    finalSkills.forEach((a, b) => {
      if (uniqueTags.indexOf(a) === -1) {
        uniqueTags.push(a)
      }
    });

    multiselectRefTracker.current.getSelectedItems().map(option => {
      if (uniqueTags.indexOf(basicSkillSelected + "-" + option.value) === -1) {
        uniqueTags.push(basicSkillSelected + "-" + option.value)
      }
    });
    multiselectRefTracker.current.resetSelectedValues(['1'])
    setFinalSkills(uniqueTags);
  }

  const deleteSkill = (s) => {
    if (isEditMode) {
      const uniqueTags = [];
      finalSkills.forEach((a, b) => {
        if (a === s) {
          console.log("removing " + s);
        } else {
          uniqueTags.push(a)
        }
      });
      setFinalSkills(uniqueTags);
    }

  }

  const editEmployee = (values) => {
    if (userKey !== "") {

      values.reportingManager = userKey;
    }
    setLoading(true);
    axios
      .post(
        constants.UPDATE_AUTH_USER_DETAILS,
        values,
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        isReloadStatus(1);
        setLoading(false);
        setCancelStatus(false);
        checkEmployeeCreated("created");
      })
      .catch((error) => {
        setLoading(false);
        checkEmployeeCreated("rejected");
        setShowSnackBar(true);
        setErrmsg("Something Went Wrong!");
        snackEnable();
      });
  };

  const handleReportingManager = (e, jobTitle) => {
    if (e.length > 0) {
      setFilterSearch(true);
    }
    else {
      setFilterSearch(false);
    }
    axios.get(constants.GET_REPORT_MANAGER, {
      params: { jobTitle: jobTitle, name: e },
      headers: {
        "Authorization": sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    }).then((result) => {


      const values = Object.entries(result.data);

      setReportingManagerData(values);

    }).catch((error) => {
      console.log("failed with error ", error);
    })
  }

  const handleSelectName = (e, k, setFieldValue) => {

    setFieldValue('reportingManager', e.target.getAttribute('data-index'));
    setFilterSearch(false);
    setUserKey(k);
  }

  const handleRefreshReportingManager = (setFieldValue) => {
    setFieldValue('reportingManager', "");
    setUserKey("");
    setFilterSearch(false);
  }

  const handleResetSection = (setFieldValue) => {
    setFieldValue('section', "");
  }

  return (
    <Formik enableReinitialize={true} initialValues={currentEmpData}
      validationSchema={schema}
      onSubmit={(values, actions) => {
        values.skills = finalSkills;
        editEmployee(values);
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        setFieldValue,
      }) => (
        <LoadingOverlay active={loading} spinner text="Submitting Form... ">
          <ReactSnackBar Icon={<span>{"❌"}</span>} Show={showSnackBar}>
            {errMsg}
          </ReactSnackBar>
          <div className="addemployeeDetail">
            <div id="dashboardlocationaddemployee">
              <span
                style={{
                  color: "#66676c",
                  fontSize: "13px",
                  marginTop: "25px",
                }}
              >
                Dashboard &gt; Employee &gt;Edit Profile
              </span>
            </div>

            <p className="addemployeeprofile"><b>Employee Profile</b></p>

            <Form noValidate onSubmit={handleSubmit} className="formValidation">
              <Tabs>
                <Tab title="Profile" eventKey="home">
                  <div className="tabOneInAddEmployee">
                    <div class="row rows">
                      <InputGroup className="mb-1">
                        <div className="commonclassform">
                          <Form.Label className="formLabelEditScreen">ACES Id <span style={{ color: 'red' }}>*</span></Form.Label>
                          <Form.Control
                            readOnly={!isEditMode}
                            placeholder="ACES Id"
                            aria-label="ACES Id"
                            aria-describedby="basic-addon1"
                            value={values.userName}
                            onChange={handleChange}
                            isInvalid={!!errors.userName && touched.userName}
                            name="userName"
                            className={isEditMode ? "inputfieldinuserdetails" : "inputfieldinuserdetails"}
                          />
                          <Form.Control.Feedback
                            className="invalidmsg"
                            type="invalid"
                          >
                            {errors.userName}
                          </Form.Control.Feedback>
                        </div>
                        <div className="commonclassform">
                          <Form.Label className="formLabelEditScreen">Name <span style={{ color: 'red' }}>*</span></Form.Label>
                          <Form.Control
                            readOnly={!isEditMode}
                            placeholder="Name"
                            aria-label="name"
                            aria-describedby="basic-addon1"
                            value={values.fname}
                            onChange={handleChange}
                            isInvalid={!!errors.fname && touched.fname}
                            name="fname"
                            className={isEditMode ? "form-control" : "inputfieldinuserdetails"}
                          />
                          <Form.Control.Feedback
                            className="invalidmsg"
                            type="invalid"
                          >
                            {errors.fname}
                          </Form.Control.Feedback>
                        </div>
                        <div className="commonclassform">
                          <Form.Label className="formLabelEditScreen">Job Title <span style={{ color: 'red' }}>*</span></Form.Label>
                          {isEditMode ? <Form.Select
                            className={isEditMode ? "changeSelectInAddemp" : "editEmpSelectBox"}
                            style={{ marginTop: '20px' }}
                            onChange={(e) => {
                              handleChange("jobTitle")(e);
                              handleRefreshReportingManager(setFieldValue);
                            }}

                            aria-label="JobTitle"
                            aria-describedby="basic-addon1"
                            value={values.jobTitle}
                            isInvalid={!!errors.jobTitle && touched.jobTitle}
                            name="jobTitle" >

                            {Object.entries(jobTitleOp)?.map(([key, val]) => (

                              <option value={key}>{val}</option>
                            ))}

                          </Form.Select> :
                            <Form.Control
                              readOnly={true}
                              placeholder="jobTitle"
                              aria-label="name"
                              aria-describedby="basic-addon1"
                              value={values.jobTitle}
                              onChange={handleChange}
                              name="jobTitle"
                              className="inputfieldinuserdetails"
                            />
                          }
                          <Form.Control.Feedback
                            className="invalidmsg"
                            type="invalid"
                          >
                            {errors.jobTitle}
                          </Form.Control.Feedback>
                        </div>
                      </InputGroup>
                    </div>
                    <div class="row rows">
                      <InputGroup className="mb-1">
                        <div className="commonclassform">
                          <Form.Label className="formLabelEditScreen">Code Id</Form.Label>
                          <Form.Control readOnly={!isEditMode}
                            className={isEditMode ? "" : "inputfieldinuserdetails"}
                            placeholder="Code(Auto Generated)"
                            aria-label="CodeId"
                            aria-describedby="basic-addon1"
                            value={values.codeId}
                            onChange={handleChange}
                            isInvalid={!!errors.codeId && touched.codeId}
                            name="codeId"
                            disabled="true"
                          />
                          <Form.Control.Feedback
                            className="invalidmsg"
                            type="invalid"
                          >
                            {errors.codeId}
                          </Form.Control.Feedback>
                        </div>
                        <div className="commonclassform">
                          <Form.Label className="formLabelEditScreen">Mobile Number <span style={{ color: 'red' }}>*</span></Form.Label>
                          <Form.Control readOnly={!isEditMode}
                            placeholder="Mobile Number"
                            aria-label="name"
                            aria-describedby="basic-addon1"
                            value={values.mobileNumber}
                            onChange={handleChange}
                            isInvalid={!!errors.mobileNumber && touched.mobileNumber}
                            name="mobileNumber"
                            className={isEditMode ? "form-control" : "inputfieldinuserdetails"}
                          />
                          <Form.Control.Feedback
                            className="invalidmsg"
                            type="invalid"
                          >
                            {errors.mobileNumber}
                          </Form.Control.Feedback>
                        </div>
                        <div className="commonclassform">
                          <Form.Label className="formLabelEditScreen">Field Mobile(Voice) <span style={{ color: 'red' }}>*</span></Form.Label>
                          <Form.Control readOnly={!isEditMode}
                            placeholder="Field Mobile(Voice)"
                            aria-label="MobileNumber"
                            aria-describedby="basic-addon1"
                            value={values.fieldMobile}
                            onChange={handleChange}
                            isInvalid={
                              !!errors.fieldMobile && touched.fieldMobile
                            }
                            name="fieldMobile"
                            className={isEditMode ? "mobile" : "inputfieldinuserdetails"}
                          />
                          <Form.Control.Feedback
                            className="invalidmsg"
                            type="invalid"
                          >
                            {errors.fieldMobile}
                          </Form.Control.Feedback>
                        </div>
                      </InputGroup>
                    </div>
                    <div class="row rows">
                      <InputGroup className="mb-1">


                        <div className="commonclassform">
                          <Form.Label className="formLabelEditScreen">Field Mobile(Whatsapp)</Form.Label>
                          <Form.Control readOnly={!isEditMode}
                            placeholder="Field Mobile(whatsapp)"
                            aria-label="whatsapp"
                            value={values.whatsapp}
                            onChange={handleChange}
                            isInvalid={!!errors.whatsapp && touched.whatsapp}
                            name="whatsapp"
                            className={isEditMode ? "mobile" : "inputfieldinuserdetails"}
                          />

                          <Form.Control.Feedback
                            className="invalidmsg"
                            type="invalid"
                          >
                            {errors.whatsapp}
                          </Form.Control.Feedback>
                        </div>
                        <div className="commonclassform">
                          <Form.Label className="formLabelEditScreen">Email Address <span style={{ color: 'red' }}>*</span></Form.Label>
                          <Form.Control readOnly={!isEditMode}
                            placeholder="Email Address"
                            aria-label="EmailAddress"
                            aria-describedby="basic-addon1"
                            value={values.emailAddress}
                            onChange={handleChange}
                            isInvalid={
                              !!errors.emailAddress && touched.emailAddress
                            }
                            name="emailAddress"
                            className={isEditMode ? "" : "inputfieldinuserdetails"}
                          />
                          <Form.Control.Feedback
                            className="invalidmsg"
                            type="invalid"
                          >
                            {errors.emailAddress}
                          </Form.Control.Feedback>
                        </div>
                        <div className="genderflex">
                          <label style={{ height: "15px" }} className="gender">
                            Gender
                          </label>

                          <Form.Check readOnly={isEditMode}
                            className="gender"
                            type="radio"
                            id="1"
                            name="gender"
                            value={"M"}
                            label={"Male"}
                            checked={values.gender === "M"}
                            onChange={handleChange}
                          />
                          <Form.Check readOnly
                            className="gender"
                            type="radio"
                            id="2"
                            value={"F"}
                            name="gender"
                            label={"Female"}
                            checked={values.gender === "F"}
                            onChange={handleChange}
                          />
                        </div>
                      </InputGroup>

                    </div>

                    <div class="row rows">
                      <InputGroup className="mb-2">
                        <div className="commonclassform">
                          <Form.Label className="formLabelEditScreen">Department <span style={{ color: 'red' }}>*</span></Form.Label>
                          {isEditMode ? <Form.Select readOnly={!isEditMode}
                            className={isEditMode ? "changeSelectInAddemp" : "editEmpSelectBox"}
                            onChange={(e) => {
                              handleChange("department")(e);
                              handleResetSection(setFieldValue);
                            }}
                            aria-label="Department"
                            aria-describedby="basic-addon1"
                            value={values.department}
                            options={departmentOp}
                            isInvalid={!!errors.department && touched.department}
                            name="department" >
                            {Object.entries(departmentOp)?.map(([key, val]) => (

                              <option value={key}>{key}</option>
                            ))}


                          </Form.Select> :
                            <Form.Control
                              readOnly={true}
                              aria-label="department"
                              aria-describedby="basic-addon1"
                              value={values.department}
                              onChange={handleChange}
                              name="department"
                              className="inputfieldinuserdetails"
                            />}
                          <Form.Control.Feedback
                            className="invalidmsg"
                            type="invalid"
                          >
                            {errors.department}
                          </Form.Control.Feedback>
                        </div>
                        <div className="commonclassform">
                          <Form.Label className="formLabelEditScreen">Region <span style={{ color: 'red' }}>*</span></Form.Label>
                          {isEditMode ? <Form.Select readOnly={!isEditMode}
                            className={isEditMode ? "changeSelectInAddemp" : "editEmpSelectBox"}
                            aria-label="Region"
                            aria-describedby="basic-addon1"
                            value={values.region}
                            onChange={handleChange}
                            name="region"
                            options={regionOp}
                            isInvalid={!!errors.region && touched.region}
                          >
                            {Object.entries(regionOp)?.map(([key, val]) => (

                              <option value={key}>{key}</option>
                            ))}

                          </Form.Select> :
                            <Form.Control
                              readOnly={true}
                              aria-label="region"
                              aria-describedby="basic-addon1"
                              value={values.region}
                              onChange={handleChange}
                              name="region"
                              className="inputfieldinuserdetails"
                            />}
                          <Form.Control.Feedback
                            className="invalidmsg"
                            type="invalid"
                          >
                            {errors.region}
                          </Form.Control.Feedback>
                        </div>
                        <div className="commonclassform">
                          <Form.Label className="formLabelEditScreen">Section <span style={{ color: 'red' }}>*</span></Form.Label>
                          {isEditMode ? <Form.Select readOnly={!isEditMode}
                            className={isEditMode ? "changeSelectInAddemp" : "editEmpSelectBox"}
                            aria-label="Section"
                            aria-describedby="basic-addon1"
                            value={values.section}
                            onChange={handleChange}
                            name="section"

                            options={sectionOp}
                            isInvalid={!!errors.section && touched.section}
                          >
                            <option style={{ display: 'none' }} value="">Section &#42;</option>
                            {values.department && sectionOp && Object.entries(sectionOp)?.map(([key, sectionVal]) => {
                              if (values.department === key) {
                                return Object.entries(sectionVal)?.map(([newKey, newSectionVal]) => (
                                  <option value={newKey} key={newKey}>{newKey}</option>
                                ));
                              }
                            })}

                          </Form.Select> :
                            <Form.Control
                              readOnly={true}
                              aria-label="section"
                              aria-describedby="basic-addon1"
                              value={values.section}
                              onChange={handleChange}
                              name="section"
                              className="inputfieldinuserdetails"
                            />}
                          <Form.Control.Feedback
                            className="invalidmsg"
                            type="invalid"
                          >
                            {errors.section}
                          </Form.Control.Feedback>
                        </div>
                      </InputGroup>
                    </div>


                    <div class="row rows">
                      <InputGroup className="mb-1">
                        <div className="commonclassform">
                          <Form.Label className="formLabelEditScreen">Reporting Manager <span style={{ color: 'red' }}>*</span></Form.Label>
                          <Form.Control readOnly={!isEditMode}
                            className={isEditMode ? "" : "inputfieldinuserdetails"}
                            placeholder="Reporting Manager"
                            aria-label="Reporting Manager"
                            aria-describedby="basic-addon1"
                            value={values.reportingManager}
                            onChange={(e) => {
                              handleChange("reportingManager")(e);
                              handleReportingManager(e.currentTarget.value, values.jobTitle);

                            }}

                            isInvalid={!!errors.reportingManager && touched.reportingManager}
                            name="reportingManager"
                          />
                          {
                            filterSearch ? <div className="showTheUsersInAddemp">

                              {reportingManagerdata?.map(([key, val]) => (
                                <li data-index={val} className="hoverList" onClick={event => handleSelectName(event, key, setFieldValue)} key={key}>{val}</li>
                              ))}

                            </div> : ""
                          }
                          <Form.Control.Feedback
                            className="invalidmsg"
                            type="invalid"
                          >
                            {errors.reportingManager}
                          </Form.Control.Feedback>
                        </div>
                        <div className="commonclassform">
                          <Form.Label className="formLabelEditScreen">Iqma Number</Form.Label>
                          <Form.Control readOnly={!isEditMode}
                            placeholder="Iqma Number"
                            aria-label="iqamaNumber"
                            aria-describedby="basic-addon1"
                            value={values.iqamaNumber}
                            onChange={handleChange}
                            isInvalid={!!errors.iqamaNumber && touched.iqamaNumber}
                            name="iqamaNumber"
                            className={isEditMode ? "form-control" : "inputfieldinuserdetails"}
                          />
                          <Form.Control.Feedback
                            className="invalidmsg"
                            type="invalid"
                          >
                            {errors.iqamaNumber}
                          </Form.Control.Feedback>
                        </div>
                        <div className="commonclassform">
                          <Form.Label className="formLabelEditScreen">Iqama Expiry</Form.Label>
                          <Form.Control readOnly={!isEditMode}
                            type="date"
                            placeholder="Iqama Expiry"
                            aria-label="IqamaExpiry"
                            aria-describedby="basic-addon1"
                            value={values.iqamaExpiry}
                            onFocus="Iqama Expiry"
                            onChange={handleChange}
                            min={new Date().toISOString().slice(0, 10)}
                            name="iqamaExpiry"
                            className={isEditMode ? "" : "inputfieldinuserdetails"}
                          />
                          <Form.Control.Feedback
                            className="invalidmsg"
                            type="invalid"
                          >
                            {errors.mobileNumber}
                          </Form.Control.Feedback>
                        </div>
                      </InputGroup>
                    </div>


                    <div class="row rows ">
                      <InputGroup className="mb-2">
                        <div className="commonclassform">
                          <Form.Label className="formLabelEditScreen">Punch In</Form.Label>
                          {isEditMode ? <Form.Select readOnly={!isEditMode}
                            className={isEditMode ? "changeSelectInAddemp" : "editEmpSelectBox"}
                            aria-label="Punch In"
                            aria-describedby="basic-addon1"
                            value={values.punchIn}
                            onChange={handleChange}
                            name="punchIn"
                          >
                            <option style={{ display: 'none' }} value="">Punch In</option>
                            <option value="On-Site">On-Site</option>
                            <option value="Off-Site">Off-Site</option>


                          </Form.Select> : <Form.Control
                            readOnly={true}
                            aria-label="punchIn"
                            aria-describedby="basic-addon1"
                            value={values.punchIn}
                            onChange={handleChange}
                            name="punchIn"
                            className="inputfieldinuserdetails"
                          />}
                        </div>
                        <div className="commonclassform">
                          <Form.Label className="formLabelEditScreen">Punch Out</Form.Label>
                          {isEditMode ? <Form.Select readOnly={!isEditMode}
                            className={isEditMode ? "changeSelectInAddemp" : "editEmpSelectBox"}
                            aria-label="Punch Out"
                            aria-describedby="basic-addon1"
                            value={values.punchOut}
                            onChange={handleChange}
                            name="punchOut"
                          >
                            <option style={{ display: 'none' }} value="">Punch Out</option>
                            <option value="On-Site">On-Site</option>
                            <option value="Off-Site">Off-Site</option>


                          </Form.Select> : <Form.Control
                            readOnly={true}
                            aria-label="punchIn"
                            aria-describedby="basic-addon1"
                            value={values.punchIn}
                            onChange={handleChange}
                            name="punchIn"
                            className="inputfieldinuserdetails"
                          />}

                        </div>
                        <div className="commonclassform">

                        </div>
                      </InputGroup>
                    </div>

                  </div>
                  {isHr === 'true' ?
                    <div className="row rows">
                      {
                        isEditMode ? <Button
                          variant="danger"
                          type="submit"
                          className="submitBtn"
                        >
                          Submit

                        </Button> :
                          <Button
                            variant="danger"
                            className="submitBtn"
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEditMode(true)
                            }
                            }
                          >
                            Edit
                          </Button>
                      }
                      <Button
                        className="cancelBtn"
                        onClick={() => {
                          setCancelStatus(false);
                          setIsEditMode(false);
                        }}
                      >
                        Back
                      </Button>
                    </div> : <div className="row rows"> <Button
                      className="cancelBtn"
                      onClick={() => {
                        setCancelStatus(false);
                        setIsEditMode(false);
                      }}
                    >
                      Back
                    </Button></div>
                  }
                </Tab>

                <Tab title="Experties" eventKey="Profile">
                  <div className="formValidationintabtwo">
                    <div class="row rows">
                      <div className="displayMainFlexInAddemp">
                        <div className="displayFlexInAddemp">
                          <div>
                            <Form.Label className="formLabelEditScreen">Skills</Form.Label>
                            <Form.Select readOnly={isEditMode}
                              className={isEditMode ? "changeSelectInAddemp" : "editEmpSelectBox"}
                              aria-label="Section"
                              aria-describedby="basic-addon1"
                              value={{}}
                              onChange={(event) => handleBasicSkillChange(event)}
                              name="basicSkill"
                              options={basicSkillOp}
                              ref={skillSelectRefTracker}
                            >
                              <option style={{ display: 'none' }} value="">Basic Skill</option>
                              <option value="Basics Telecom">Basics Telecom</option>
                              <option value="Design">Design</option>
                              <option value="BTS Vendor">BTS Vendor</option>
                              <option value="RF Testing">RF Testing</option>
                              <option value="Tech Field Skills">Tech Field Skills</option>
                              <option value="Basic Instrumentation">Basic Instrumentation</option>
                              <option value="Basics Electrt">Basics Electrt</option>
                              <option value="Cabling">Cabling</option>
                              <option value="Repair">Repair</option>
                              <option value="Skills and Repairing">Skills and Repairing</option>
                              <option value="Evaluation">Evaluation</option>
                              <option value="Adv. Eva.">Adv. Eva.</option>
                            </Form.Select>
                          </div>
                          <br />
                          <div>
                            <Multiselect
                              options={subSkillOp} // Options to display in the dropdown
                              selectedValues={state.selectedValue} // Preselected value to persist in dropdown
                              displayValue="label" // Property name to display in the dropdown options
                              name="subSkill"
                              //onSelect={onSelect}
                              //onRemove={onRemove}
                              ref={multiselectRefTracker}
                            />
                          </div>
                          <div>
                            <button className="addBtnInEmp" onClick={addSelected} type="button">Add Item</button>
                          </div>
                        </div>
                        <div className="DisplayFlexInAddemp">
                          <br />
                          {finalSkills && finalSkills.map(a => (<div className="contentInAddemp"><p className="contentBox"> {a} </p><img src={deleteImg} onClick={() => deleteSkill(a)} /></div>))}
                          <div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row rows">
                      {
                        isEditMode ? <Button
                          variant="danger"
                          type="submit"
                          className="submitBtn"
                        >
                          Submit

                        </Button> :
                          <Button
                            variant="danger"
                            className="submitBtn"
                            onClick={(e) => {
                              e.preventDefault();
                              setIsEditMode(true)
                            }
                            }
                          >
                            Edit
                          </Button>
                      }


                      <Button
                        className="cancelBtn"
                        onClick={() => {

                          setCancelStatus(false);
                          setIsEditMode(false)
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </Form>
          </div>
        </LoadingOverlay>
      )}
    </Formik>
  );
}
export default EditEmployee;
