import React, { useState, useEffect } from 'react';
import axios from 'axios';
import profile from '../images/Group34.png';
import './log-in.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';
import { decode as base64_decode, encode as base64_encode } from 'base-64';
import { Link } from 'react-router-dom';
import *  as constants from "../Utils/URLConstants";

function Login(props) {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  let dateFormat = moment(new Date(), "ddd MMM DD YYYY HH:mm:ss ZZ")
  let reqDateFormat = dateFormat.format("DD MMM YYYY ddd")



  const handleEnter = (e) => {
    console.log(e.key);
    if (e.key === 'Enter') {
      if (!e.defaultPrevented) {
        handleLogin();
      }
    }
  }




  const handleLogin = async () => {

    setError(null);

    if (username === null || username === "") {
      setError("ACES Id : should not be blank");
    }
    else if (password === null || password === "") {
      setError("Password: should not be blank");
    }
    else {
      let encoded = base64_encode(`${username}:${password}`);
      axios.defaults.headers.common['Authorization'] = `Basic ${encoded}`;
      await axios.post(constants.LOGIN_URL).then(response => {
        let image = response.data.imageUrl === null ? profile : response.data.imageUrl;
        let name = response.data.name !== null ? response.data.name : "";
        let roles = response.data.roles !== null ? response.data.roles : [];
        let isHr = false;
        if (roles.indexOf("Hr Manager") > -1) {
          isHr = true;
        }
        let isReadOnlyUser = false;
        if (roles.indexOf("Employee") > -1 || roles.indexOf("Technician") > -1) {
          isReadOnlyUser = true;
        }

        if(roles.indexOf("Expense_Admin") !== -1){
          const isExpense_Admin = true;
          sessionStorage.setItem('Expense_Admin', isExpense_Admin.toString())
        }
       
        sessionStorage.setItem('token', response.headers.authorization)
        sessionStorage.setItem('date', reqDateFormat)
        sessionStorage.setItem('name', name)
        sessionStorage.setItem('image', image)
        sessionStorage.setItem('roles', roles)
        sessionStorage.setItem('isHr', isHr)
        sessionStorage.setItem('loggedInUserName', username)
        sessionStorage.setItem('isReadOnlyUser', isReadOnlyUser)
        if (roles[0] === ("Technician") && roles.length === 1) {
          setError("You are not authorized to login admin portal");
        } else {
          props.history.push(
            {
              pathname: '/dashboard',
            });
        }
      }).catch(error => {
        if (error.response.status === 401)
          setError("Invalid UserName or Password ");
        else setError("Something went wrong. Please try again later.");
      });
    }

  }

  return (
    <div>
      <link href="https://fonts.googleapis.com/css?family=Roboto&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css?family=Inter&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css?family=Poppins&display=swap" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap" rel="stylesheet"></link>
      <div class="pageBody">
        <div class="main">
          <div class="arch1">
            <span class="rl"></span>
            <span class="rl1"></span>
            <span class="rl2"></span>
            <span class="rl3"></span>
          </div>
          <h1 class="title">ACES</h1>
          <div class="line"></div>
          <span class="description">Advanced Communications & Electronic Systems Company</span>
        </div>
        <div class="form">
          <span class="form-bg"></span>
          <span class="form-head">Sign in to ACES</span>


          <input type="text" class="aces-id" placeholder="ACES id" id="Aces Id" value={username} onChange={(e) => setUserName(e.target.value)} />
          <input type="password" class="pass" placeholder="******" d="pwd" value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={(e) => handleEnter(e)} />
          <Link to="/forgotpassword" class="forget-pass">Forgot Password ?</Link>
          <p id='errMsg'>{error && <><small >{error}</small></>}</p>

          <button class="signin signin_btn" onClick={handleLogin}>Sign in</button>

        </div>
      </div>
    </div>
  );
}



export default Login;