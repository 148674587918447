import React, { useEffect, useState } from "react";
import axios from "axios";
import "../style.css";
import "./ExpenseSheet.css";
import Pagination from "react-js-pagination";
import *  as constants from "../../Utils/URLConstants";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Details from "./Details";
import * as moment from 'moment'
import { Modal, Button, Form } from "react-bootstrap";
import ReactSnackBar from "react-js-snackbar";
import ReportDetails from "./ReportDetails";
import ReportTab from "./ReportTab";
import LoadingOverlay from "react-loading-overlay";
import BootstrapSelect from "react-bootstrap-select-dropdown";
import { useRef } from "react";

function ExpenseSheet() {
  // current page
  const [currentPage, setCurrentPage] = useState(1);

  const [isLoading, setIsLoading] = useState(0);
  // handle change event


  const dateObj = new Date();
  const [searchYear, setSearchYear] = useState(null)
  const [searchMonth, setSearchMonth] = useState(null);
  const [month, setMonth] = useState(dateObj.getMonth() + 1);
  const [year, setYear] = useState(dateObj.getFullYear());
  const [cancelFlag, setCancelFlag] = useState(false);
  const [search, setSearch] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [checkSearch, setCheckSearch] = useState(false);
  let monthDates = Array.from(Array(new Date(year, month, 0).getDate()).keys());
  const [expenseSheetDetails, setExpenseSheetDetails] = useState(false);
  const [data, setData] = useState([]);
  const [trackerId, setTrackerId] = useState(0);
  const [ticket, setTicket] = useState(0);
  const [apporvalCycleDone, setApporvalCycleDone] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [emoji, setEmoji] = useState("");
  const [snackmessage, setSnackMessage] = useState("");
  const [requestType, setRequestType] = useState("");
  const [reportDetails, setReportDetails] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dropDownValue, setDropDownValue] = React.useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [empSearchData, setEmpSearchData] = useState([]);
  const [filterSearch, setFilterSearch] = useState(false);
  const [userKey, setUserKey] = useState("");
  const attendSearchRef = useRef(null);
  const [formFieldsDates, setFormFieldsDates] = useState(
    {
      reportType: '1',
      userName: '',
      startDate: '',
      endDate: '',
      fileType: 'PDF'
    }
  )
  const [isSubmitted, setIsSubmitted] = useState(false);

  const setCancel = (flag) => {
    setCancelFlag(true)
    setTrackerId(0);
    setRequestType("");
    setTicket("");
    setExpenseSheetDetails(flag);
  }
  //show snackbar
  const snackEnable = () => {
    setTimeout(() => {
      setShowSnackBar(false);
    }, 4000);
  }
  //checking status from details page
  const snackStatus = (condFlag) => {
    if (condFlag === "APPROVED") {
      setShowSnackBar(true);
      setSnackMessage("Successfully Approved.");

      snackEnable();
      setEmoji("✔️");
    }
    else {
      setShowSnackBar(true);
      setSnackMessage("Successfully Rejected.");

      snackEnable();
      setEmoji("✔️");
    }
  }
  const recallApi = (flag) => {
    flag && fetchDetails();
  }

  const fetchDetails = (val, setCurrPageToOne) => {
    if (search !== null) {
      let nameData = search && search;
      axios
        .get(constants.GET_CASHREQUEST_DATA, {
          params: {
            month: searchMonth,
            year: searchYear,
            pageNumber: setCurrPageToOne ? 1 : currentPage,
            recordedData: val,
            name: nameData
          },

          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setData(res.data);

          setTotalPages(res.data.pageableResponse.totalPages);
          setTotalRecords(res.data.pageableResponse.totalElements);
          setCheckSearch(false);
          setLoading(false);
        })
        .catch((error) => {
          console.error("error", error);
        });


    }
    else {
      axios
        .get(constants.GET_CASHREQUEST_DATA, {
          params: {
            pageNumber: currentPage,
            recordedData: val
          },

          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setData(res.data);
          setTotalPages(res.data.pageableResponse.totalPages);
          setTotalRecords(res.data.pageableResponse.totalElements);
          setCheckSearch(false);
          setLoading(false);
        })
        .catch((error) => {
          console.error("error", error);
        });
    }


  }


  const dropDownValueChangeApiCall = (val, setCurrPageToOne) => {

    axios
      .get(constants.GET_CASHREQUEST_DATA, {
        params: {
          pageNumber: setCurrPageToOne ? 1 : currentPage,
          recordedData: val
        },

        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setData(res.data);
        setTotalPages(res.data.pageableResponse.totalPages);
        setTotalRecords(res.data.pageableResponse.totalElements);
        setCheckSearch(false);
        setLoading(false);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }


  const handleSelectName = (e, k, val) => {
    setFormFieldsDates({ ...formFieldsDates, userName: val })
    setFilterSearch(false);
    setUserKey(k);
  }

  const handlePageChange = (pageNumber) => {
    setLoading(true);
    setCurrentPage(pageNumber);
    setIsLoading(isLoading + 1);
    // call API to get data based on pageNumber
  };

  const searchEmpName = (e) => {

    setFilterSearch(true)
    axios.get(constants.SEARCH_EMP_NAMES_ROSTER, {
      params: { name: e },
      headers: {
        "Authorization": sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    }).then((result) => {


      const values = Object.entries(result.data);
      setEmpSearchData(values);

    }).catch((error) => {
      console.log("failed with error ", error);
    })
  }

  //Enter key logic for search
  function handleKeyPress(event) {

    if (event.key === 'Enter') {
      // Perform search functionality here
      handleSearch();
    }
  }
  const handleSearch = () => {

    setCurrentPage(1);
    setLoading(true);
    fetchDetails(dropDownValue, 1);
  };
  useEffect(() => {
    fetchDetails(dropDownValue);
  }, [isLoading])
  const options = [
    {
      "labelKey": "false",
      "value": "Open Requests",
      "isSelected": true

    },
    {
      "labelKey": "true",
      "value": "Actioned History",
    }
  ]

  const handleDropdownValue = (e) => {

    // setLoading(true);
    setCheckSearch(false);
    setSearchMonth(null);
    setSearchYear(null);
    setSearch("");
    setCurrentPage(1);
    // console.log(e.selectedKey[0])
    if (!cancelFlag) {
      setDropDownValue(e.selectedKey[0])
      console.log(cancelFlag)
      dropDownValueChangeApiCall(e.selectedKey[0], 1);
    }
    setCancelFlag(false)




  }

  const
    downloadData = (val) => {

      if ((val.startDate === '' || val.endDate === '') || (val.userName === '' && val.reportType === "2")) {
        setIsSubmitted(true);
      }
      else if (val.reportType === "2") {
        axios
          .get(
            constants.DOWNLOAD_EXPENSE_SHEET_REPORT_TWO,
            {
              params: {

                "startDate": formFieldsDates.startDate,
                "endDate": formFieldsDates.endDate,
                "userName": userKey,
                "fileType": formFieldsDates.fileType,
                recordedData: dropDownValue
              },
              headers: {
                Authorization: sessionStorage.getItem("token"),
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (res) => {
            const link = document.createElement('a');
            link.href = await res.data;
            link.setAttribute('download', `Report--${+new Date()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            closeModal();
          })
          .catch((error) => {
            console.error("error", error);
          });
      }

      else {
        axios
          .get(
            constants.DOWNLOAD_EXPENSE_SHEET,
            {
              params: {

                "startDate": formFieldsDates.startDate,
                "endDate": formFieldsDates.endDate,
                "fileType": formFieldsDates.fileType,
                recordedData: dropDownValue
              },
              headers: {
                Authorization: sessionStorage.getItem("token"),
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (res) => {
            const link = document.createElement('a');
            link.href = await res.data;
            link.setAttribute('download', `Report--${+new Date()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            closeModal();
          })
          .catch((error) => {
            console.error("error", error);
          });
      }

    }


  const closeModal = () => {
    // setDeleteId(0);
    setFormFieldsDates({ startDate: '', endDate: '', fileType: 'PDF', reportType: '1' })
    setIsOpen(false);
    setIsSubmitted(false);
  }

  if (expenseSheetDetails) {
    return (

      <Details setCancel={setCancel} trackerIdNumber={trackerId} recallApi={recallApi} apporvalCycleDone={apporvalCycleDone} snackStatus={snackStatus} trackId={ticket} prettyCash={requestType} dropDown={dropDownValue} />
    )
  }
  else if (reportDetails) {
    return (

      <ReportDetails />
    )
  }
  else {
    return (
      <div>
        <ReactSnackBar Icon={<span>{emoji}</span>} Show={showSnackBar}>
          {snackmessage}
        </ReactSnackBar>
        <div id="dashboardlocation">
          <span
            style={{
              color: "#66676c",
              fontSize: "13px",
            }}
          >
            Dashboard &gt; Expense Sheet
          </span>
        </div>

        <Tabs className="tabsInExpenseSheet">
          <Tab title="ExpenseSheet" eventKey="expensesheet">
            <LoadingOverlay active={loading} spinner text="Loading Records... ">
              <div className="attendtopsearch">
                <input
                  type="search"
                  id="attendSearch"
                  placeholder="Search By Employee"
                  value={search}
                  className="text-black widthForInputExpenseSheet"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    setCheckSearch(true);
                  }}
                  ref={attendSearchRef}
                  onKeyUp={handleKeyPress}
                />

                <input type="month" onChange={(e) => {
                  if (e.target.value !== null) {
                    const month_year = e.target.value.split('-');


                    setSearchMonth(month_year[1]);
                    setSearchYear(month_year[0]);
                  }

                }} className="MonthInAttendance text-black widthForInputExpenseSheet" />

                <input
                  type="button"
                  id="attendsearchbtn"
                  className="searchBtnInExpenseSheet"
                  defaultValue="Search"
                  onClick={handleSearch}

                />
                 
                  { 
                    <div >
                      <BootstrapSelect  disabled ={ sessionStorage.getItem('Expense_Admin') == "true"  } options={options} placeholder="Tab" showTick={true} style={{ marginTop: '-4px', marginLeft: '5px' }} onChange={(e) => {
    
                        if (!e.defaultPrevented) {
                          handleDropdownValue(e);
                        }
                      }} />
                    
                    </div>
                     }
                  
                <input
                  type="button"
                  id="attendsearchbtn"
                  className="bg-success"
                  defaultValue="Export"
                  onClick={() => setIsOpen(true)}
                />
              </div>
              <div className="attendDivision mapDivision">
                {
                  dropDownValue === 'true' ?

                    <table class="table table-striped table-bordered" align="right">
                      <thead class="thead-dark" style={{ textAlign: 'center', fontSize: '15px' }}>
                        <tr>
                          <th scope="col" className="changeColor">Ticket No.</th>
                          <th scope="col" className="changeColor">Employee Name</th>
                          <th scope="col" className="changeColor">Date of Request</th>
                          <th scope="col" className="changeColor">Request Type</th>
                          <th scope="col" className="changeColor">Amount (SAR)</th>
                          <th scope="col" className="changeColor">Status</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody style={{ textAlign: 'center' }}>
                        {
                          data?.length === 0 || data?.length === 'undefined' ? <div style={{ position: 'absolute', top: '35vh', left: '60vh', fontSize: '20px', fontWeight: 'bold' }}>No Records Found</div> :
                            data?.cashAndExpenseDetailsList?.map(ele => (
                              <tr>
                                <th scope="row">{ele.requestCreateId}</th>
                                <td className="boldInExpanseDetails">{ele.employeeName}</td>
                                <td className="boldInExpanseDetails">{ele.requestedDate ? moment(ele?.requestedDate).format('DD-MM-YYYY') : ""}</td>
                                <td className="changeColor">{ele.requestType === 'CASH_REQUEST' ? 'Petty Cash ' : 'Expense Sheet '}</td>
                                <td className="changeColor fontBold" >{ele.amount}</td>
                                <td className="changeColor">{ele.requestStatus}</td>
                                <td onClick={(e) => {
                                  e.preventDefault();
                                  setTrackerId(ele.trackId);
                                  setTicket(ele.requestId);
                                  setRequestType(ele.requestType);
                                  setApporvalCycleDone(ele.requestStatus !== "PENDING" ? true : false)
                                  setExpenseSheetDetails(true);
                                }} className="viewDetails">View Details</td>
                              </tr>
                            ))

                        }

                      </tbody>
                    </table>


                    :
                    <table class="table table-striped table-bordered" align="right">
                      <thead class="thead-dark" style={{ textAlign: 'center', fontSize: '15px' }}>
                        <tr>
                          <th scope="col" className="changeColor">Ticket No.</th>
                          <th scope="col" className="changeColor">Employee Name</th>
                          <th scope="col" className="changeColor">Date of Request</th>
                          <th scope="col" className="changeColor">Request Type</th>
                          <th scope="col" className="changeColor">Amount (SAR)</th>
                          <th scope="col" className="changeColor">Status</th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody style={{ textAlign: 'center' }}>
                        {
                          data?.length === 0 || data?.length === 'undefined' ? <div style={{ position: 'absolute', top: '35vh', left: '60vh', fontSize: '20px', fontWeight: 'bold' }}>No Records Found</div> :
                            data?.cashAndExpenseDetailsList?.map(ele => (
                              <tr>
                                <th scope="row">{ele.requestCreateId}</th>
                                <td className="boldInExpanseDetails">{ele.employeeName}</td>
                                <td className="boldInExpanseDetails">{ele.requestedDate ? moment(ele?.requestedDate).format('DD-MM-YYYY') : ""}</td>
                                <td className="changeColor">{ele.requestType === 'CASH_REQUEST' ? 'Petty Cash ' : 'Expense Sheet '}</td>
                                <td className="changeColor fontBold">{ele.amount}</td>
                                <td className="changeColor">{ele.requestStatus}</td>
                                <td onClick={(e) => {
                                  e.preventDefault();
                                  setTrackerId(ele.trackId);
                                  setTicket(ele.requestId);
                                  setRequestType(ele.requestType);
                                  setApporvalCycleDone(ele.requestStatus === "PENDING" ? false : true)
                                  setExpenseSheetDetails(true);
                                }} className="viewDetails">View Details</td>
                              </tr>
                            ))

                        }
                      </tbody>
                    </table>
                }


              </div>
              <div className="paginationInAttendance">
                <Pagination
                  itemClass="page-item" // add it for bootstrap 4
                  linkClass="page-link" // add it for bootstrap 4
                  activePage={currentPage}
                  itemsCountPerPage={15}
                  totalItemsCount={totalRecords}
                  pageRangeDisplayed={totalPages}
                  onChange={handlePageChange}
                />
              </div>
            </LoadingOverlay>
          </Tab>
          {/* <Tab title="Report" eventKey="report">
            <ReportTab />
            

          </Tab> */}
        </Tabs>
        <Modal show={isOpen} onHide={closeModal}>
          <Modal.Header closeButton> </Modal.Header>
          <Modal.Body>
            <div>

              <div className="d-flex w-100 px-4">
                <Form.Select className="changeSelectInAddemp"
                  aria-label="Punch In"
                  aria-describedby="basic-addon1"
                  // value={values.punchIn}
                  onChange={(e) => {
                    setFormFieldsDates({ ...formFieldsDates, reportType: e.target.value, userName: '' })
                  }}
                  value={formFieldsDates.reportType}
                  name="reportType"
                  style={{ marginTop: 'unset', width: "45%" }}
                >
                  {/* <option style={{ display: 'none' }} value="">Report Type</option> */}
                  <option value="1">Report-1</option>
                  <option value="2">Report-2</option>



                </Form.Select>

                <span className='d-flex flex-column position-relative w-100'>
                  < Form.Control input type="text" name="userName" id="" disabled={formFieldsDates.reportType === '1'} isInvalid={isSubmitted && formFieldsDates.userName === '' && formFieldsDates.reportType === '2'} value={formFieldsDates.userName} required style={{ width: '100%', marginLeft: '10px', height: '60%', padding: '5px', marginTop: 'unset' }} placeholder='Enter Employee Name*' onChange={(e) => {
                    setFormFieldsDates({ ...formFieldsDates, userName: e.target.value })
                    searchEmpName(e.currentTarget.value);
                  }} />
                  {
                    filterSearch ?
                      <span className='bg-white border position-absolute' style={{ maxHeight: '340%', marginTop: '38px', marginLeft: '10px', overflow: 'scroll', overflowX: 'hidden', width: '100%' }}>
                        {empSearchData?.map(([key, val]) => (
                          <li data-index={val} className="hoverList" onClick={event => handleSelectName(event, key, val)} key={key}>{val}</li>
                        ))}

                      </span> : ''
                  }

                </span>
              </div>
              {/* <h5 style={{ textAlign: 'center', marginTop: 'unset' }}>Select Date Range</h5> */}
              <Form>
                <div className="d-flex justify-content-around">
                  <div className="attendanceFormControl">
                    <span style={{ fontWeight: 'bold' }}>Start Date <span style={{ color: 'red' }}>*</span> :</span>
                    <Form.Control type="date" name="" id="" style={{ width: 'unset', height: '60%', padding: '4px 25px' }} isInvalid={isSubmitted && formFieldsDates.startDate === ''} onChange={(e) => setFormFieldsDates({ ...formFieldsDates, startDate: e.target.value, endDate: '' })} />
                  </div>

                  <div className="attendanceFormControl">
                    <span style={{ fontWeight: 'bold' }}>End Date <span style={{ color: 'red' }}>*</span> :</span>
                    <Form.Control type="date" name="enddate" id="" style={{ width: 'unset', height: '60%', padding: '4px 25px' }} min={formFieldsDates.startDate.toString().slice(0, 10)} isInvalid={isSubmitted && formFieldsDates.endDate === ''} onChange={(e) => setFormFieldsDates({ ...formFieldsDates, endDate: e.target.value })} />
                  </div>
                </div>
                {formFieldsDates.reportType === '2' && <div className="attendanceFormControl d-flex w-100" style={{ margin: '30px 0px -10px 20px' }}>
                  <div style={{ marginRight: '15px', fontWeight: 'bold' }}>Document Type:</div>

                  <Form.Check
                    type="radio"
                    id="1"
                    name="pdf"
                    value={"PDF"}
                    label={"pdf"}
                    checked={formFieldsDates.fileType !== 'Excel'}
                    onChange={(e) => setFormFieldsDates({ ...formFieldsDates, fileType: e.target.value })}
                  // onChange={handleChange}
                  />
                  <Form.Check
                    type="radio"
                    id="1"
                    name="Excel"
                    value={"Excel"}
                    label={"excel"}
                    checked={formFieldsDates.fileType === 'Excel'}
                    className="pl-5"
                    style={{ marginLeft: '10px' }}
                    onChange={(e) => setFormFieldsDates({ ...formFieldsDates, fileType: e.target.value })}
                  />
                </div>}
              </Form>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="displayflexforpopupdelete">

              <Button
                variant="secondary"
                className="noBtninpopup"
                style={{ width: 'unset', marginLeft: '2%' }}
                onClick={closeModal}
              >
                Cancel
              </Button>
              <Button
                variant="secondary"
                className="yesBtninpopup"
                style={{ width: 'unset', marginLeft: '2%' }}
                onClick={() => downloadData(formFieldsDates)}
              >
                Download
              </Button>
            </div>
          </Modal.Footer>
        </Modal>

      </div>
    );
  }



}

export default ExpenseSheet;
