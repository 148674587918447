import { Button, Form } from "react-bootstrap";
import React, { useEffect, useState } from 'react'
import './ExpenseSheet.css';
import preview from "../../images/view-file.svg";
import axios from "axios";
import * as constants from "../../Utils/URLConstants";
import * as moment from 'moment'
import LoadingOverlay from "react-loading-overlay";
import { Accordion } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import StepProgress from "./StepProgress";


function Details({ setCancel, trackerIdNumber, recallApi, apporvalCycleDone, snackStatus, trackId, prettyCash,dropDown }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [approvedOrRejected, setApprovedOrRejected] = useState('APPROVED');
  const [notes, setNotes] = useState(null);
  const [comments, setComments] = useState([]);
  const [invalid, setInvalid] = useState(false);

  let i = 0;

  let steps = [
    "Requested",
    "",
    "Pending With Finance1",
    "Pending With Finance2",
    data?.ticketStatus === "REJECTED" ? "Rejected" : "Approved",
  ];
  const trackSteps = {
    1:'Requested',
    2:'Manager',
    3:'Finance1',
    4:'Finance2',
    5:'Approved'
  }
  let currentStep = 2;
  if (data?.mapOfResponsesAndUsers && data?.mapOfResponsesAndUsers.ROLE_Report_Manager && data?.mapOfResponsesAndUsers.ROLE_Report_Manager.actionTaken  === "REJECTED") {
    steps = [
      "Requested",
      "",
      "Rejected"
      
    ];
    currentStep = 3;
  } else if (data?.mapOfResponsesAndUsers && data?.mapOfResponsesAndUsers.ROLE_Finance_One && data?.mapOfResponsesAndUsers.ROLE_Finance_One.actionTaken  === "REJECTED") {
    steps = [
      "Requested",
      "",
      "",
      "Rejected"
      
    ];
    currentStep = 4;
  } else if (
    data?.mapOfResponsesAndUsers &&
    data?.mapOfResponsesAndUsers.ROLE_Finance_Two &&
    data?.mapOfResponsesAndUsers.ROLE_Finance_Two.actionTaken === "REJECTED"
  ) {
    steps = ["Requested", "", "", "", "Rejected"];
    currentStep = 5;
  }
  const departmentType = data?.departmentType;
  const StatusOfTicket = data?.ticketStatus;
  let departmentTypeKey = 1;

  for (const key in trackSteps) {
    if (trackSteps[key] === departmentType) {
      departmentTypeKey = parseInt(key);
      break;
    }
  }

   currentStep = StatusOfTicket === "APPROVED" ? (departmentTypeKey === 4 ? 5 : departmentTypeKey) : StatusOfTicket === "PENDING" ? (departmentTypeKey==4?4:departmentTypeKey):currentStep;

  let progressPollColor;

if ( data?.ticketStatus === 'APPROVED') {
  progressPollColor = '#00cc00';
  } else if (data?.ticketStatus === "REJECTED") {
  progressPollColor = 'red';
  }

  const fetchSingleUserDetails = () => {
    let value = "";
    axios
      .get(constants.GET_SINGLEUSER_DATA, {
        params: {
          trackerId: trackerIdNumber,
          ticketId: trackId
        },

        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        setData(res.data);
        const comments = Object.entries(res.data.mapOfResponsesAndUsers).map(
          ([key, value]) => ({
            actionedBy: value.userFullName,
          userEnteredComments: value.userEnteredComments
        }));

        setComments(comments);

  
        console.log(res.data)

      })
      .catch((error) => {
        console.error("error", error);
      });
  }

  useEffect(() => {
    fetchSingleUserDetails();
  }, [])

  const extraSize = (e) => {
    const textarea = document.querySelector('.textAreaInDetails');
    textarea.style.height = '66px';
    let scHeight = e.target.scrollHeight;
    textarea.style.height = `${scHeight}px`;
  }

  const sendData = () => {
    axios
      .post(
        constants.UPDATE_TRACKER_STATUS,
        {
          trackerId: trackerIdNumber,
          status: approvedOrRejected,
        additionalNotes: notes
        },
        {
          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        setCancel(false);
        setLoading(false);
        snackStatus(approvedOrRejected);
        recallApi(true);
      })
      .catch((err) => {
        console.error(err);
        })

  }

  const handleClickPreview = async (file) => {
    let fileName = file.split(".")[1];
    setLoading(true);
    await axios
      .get(constants.PREVIEW_DATA, {
        params: {
            "fileName": file,
            "env": constants.ENV,
        },
        responseType: "arraybuffer",
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        }
      )
      .then(response => {
        let type = fileName === 'pdf' ? "application/" + fileName : "image/" + fileName;
        const file = new Blob([response.data], { type: type });
        const fileURL = URL.createObjectURL(file);
        setLoading(false);
        window.open(fileURL);
      })
      .catch((error) => {
        setLoading(false);
      });

  }

  const downloadData = () => {
    setLoading(true);
    axios
      .get(constants.DOWNLOAD_EXPENSE_DETAILS_SHEET, {
        params: {
            "ticketId": trackId
        },
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then(async (res) => {
        const link = document.createElement('a');
        link.href = await res.data;
        link.setAttribute('download', `Report--${+new Date()}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setLoading(false);
      })
      .catch((error) => {
        console.error("error", error);
        setLoading(false);
      });
  }


  return (
    <div>
      <div id="dashboardlocation">
        <span
          style={{
            color: "#66676c",
            fontSize: "13px",
          }}
        >
          Dashboard &gt; Expense Sheet &gt; Details
        </span>
      </div>
      <LoadingOverlay
        active={loading}
        spinner
        text={'Submitting...'}

      >
        <div className="attendDivision mapDivision expanseSheetDetails">
          <Form>
            <div className="d-flex my-4  border p-2">
              {/* left side div */}
              <div className="w-50">
                <p><span className="boldInExpanseDetails">Employee Name</span> : <span className="changeColor">{data?.employeeName}</span></p>

                <p><span className="boldInExpanseDetails">ACES Id</span> : <span className="changeColor">{data?.acesId}</span></p>

                <div><span className="boldInExpanseDetails">Department</span> : <span className="changeColor">{data?.department}</span></div>
              </div>
              {/* rigth side div */}
              <div className="w-50">
                <p><span className="boldInExpanseDetails">Ticket Number</span> : <span className="changeColor">{data?.requestTicketId}</span></p>

                <p><span className="boldInExpanseDetails">Requested Date</span> : <span className="changeColor">{data?.requestedDate ? moment(data?.requestedDate, 'YYYY-MM-DD').format().split('T')[0] : ""}</span></p>

                <div><span  className="boldInExpanseDetails">Requested Amount :</span> <span >{data?.amount} SAR</span></div>
                
              </div>
            </div>
            <div>
              { <p className="boldInExpanseDetails">Approved / Pending / Rejected Status : </p> }
             { <StepProgress steps={steps} currentStep={currentStep} progressColor={progressPollColor} mapOfResponsesAndUsers={ data.mapOfResponsesAndUsers} Status={data?.ticketStatus}/> }
            </div>

            <div className="d-flex justify-content-between my-3">
               <p><span className="boldInExpanseDetails">Description:</span> <span>{data?.description}</span></p>
              <div>
                {prettyCash === 'EXPENSE_REQUEST' && <Button
                    className="exportbtnColor rounded-pill px-4"
                    onClick={() => {
                  downloadData()

                    }}
                style={{position:'unset'}}
                  >
                    Export
              </Button>}
              </div>
            </div>

            {

              prettyCash !== "CASH_REQUEST" ?
              <div className="mt-4">
                <p>
                  <span className="boldInExpanseDetails">Invoice List</span> :{" "}
                </p>
                <table class="table table-striped table-bordered">
                  <thead
                    class="thead-dark"
                    style={{ textAlign: "center", fontSize: "14px" }}
                  >
                    <tr>
                      <th scope="col" style={{ maxWidth: "5%" }}>
                        S.No.
                      </th>
                      <th scope="col" style={{ maxWidth: "10%" }}>
                        Cost Center
                      </th>
                      <th scope="col" style={{ maxWidth: "10%" }}>
                      Category
                    </th>

                    <th scope="col" style={{ maxWidth: "10%" }}>
                      Category Code
                    </th>

                      <th scope="col" style={{ maxWidth: "20%" }}>
                        VAT No
                      </th>

                      <th scope="col" style={{ maxWidth: "20%" }}>
                        Description
                      </th>
                      <th scope="col" style={{ maxWidth: "5%" }}>
                        Amount (SAR)
                      </th>
                      <th scope="col" style={{ maxWidth: "5%" }}>
                        VAT
                      </th>
                      <th scope="col" style={{ maxWidth: "5%" }}>
                        Total Amount(SAR)
                      </th>
                      <th scope="col">Download</th>
                    </tr>
                  </thead>
                  <tbody style={{ textAlign: "center" }}>
                    {data?.listOfInvoices?.map((ele) => (
                      <tr>
                        <th scope="row">{(i = i + 1)}</th>
                        <th scope="row">{ele.costCenter}</th>
                        <th scope="row">{ele.category}</th>
                        <th scope="row">{ele.categoryCode}</th>
                        <th scope="row">{ele.sellerVatNumber}</th>
                        <th scope="row">{ele.description}</th>
                        <th scope="">
                          {ele.actualBillAmount === null
                            ? 0
                            : ele.actualBillAmount}
                        </th>
                        <td className="">
                          {ele.vatAmount === null ? 0 : ele.vatAmount}
                        </td>
                        <td className="">
                          {ele.invoiceAmount === null ? 0 : ele.invoiceAmount}
                        </td>
                            <td className=""><a href={ele.invoiceURL} download>{ele.invoiceURL.replace(/^.*[\\\/]/, '')}</a>
                              <img src={preview} onClick={(event) => {
                                if (!event.defaultPrevented) { handleClickPreview(ele.invoiceURL.replace(/^.*[\\\/]/, '')) }
                              }} alt="Download" /></td>
                      </tr>
                    ))}
                    <tr>
                      <td colSpan="6">Total Amount(SAR) :</td>
                      <td className="fontBold">{data?.finalBillAmount}</td>
                      <td className="fontBold">{data?.vatAmount}</td>
                      <td className="fontBold">{data?.amount}</td>
                    </tr>
                  </tbody>
                </table>
                </div> : ""
            }
            <div className='d-flex mt-5'>

             

              {
                sessionStorage.getItem('Expense_Admin') !== "true" &&
                <div className="d-flex flex-column w-50">
                <p className="formControlInExpanseDetails"><span className="boldInExpanseDetails">Status </span> : <Form.Check type="radio" name="approvedOrRejected" style={{ marginLeft: '1%' }} value={"APPROVED"} checked={approvedOrRejected === "APPROVED"} onChange={e => setApprovedOrRejected(e.target.value)}></Form.Check><p style={{ marginLeft: '0.5%' }}>Approved</p>  <Form.Check type="radio" name="approvedOrRejected" style={{ marginLeft: '3%' }} value={"DECLINE"} checked={approvedOrRejected === "DECLINE"} onChange={e => setApprovedOrRejected(e.target.value)}></Form.Check><p style={{ marginLeft: '0.5%' }}>Rejected</p></p>

                <p style={{ marginBottom: '-46px',display:'flex' }}><span className="boldInExpanseDetails mt-3" >Notes {approvedOrRejected === "DECLINE" ? <span style={{ color: 'red' }}>*</span> : ''}:</span> <Form.Control
                  as="textarea" maxlength="500" className="textAreaInDetails mt-0" onKeyUp={(e) => extraSize(e)} placeholder="Add Notes..." style={{ position: 'relative',marginLeft:'1%',width:"50%" }} onChange={(e) => setNotes(e.target.value ? e.target.value : null)} name="notes" isInvalid={invalid && notes === null && approvedOrRejected !== "APPROVED"} /></p>



              </div>
              }
              <div className="w-50">
                {comments.length !== 0 ? (
                  <>
                    <span className="boldInExpanseDetails">Comments :</span>
                    <br />
                    <br />
                    {comments?.map((comment, index) => (
                      comment.userEnteredComments &&
                          <Accordion key={index}>
                            <Accordion.Item eventKey={index}>
                              <Accordion.Header>
                                {comment.actionedBy}
                              </Accordion.Header>
                              <Accordion.Body>
                                {comment.userEnteredComments}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        )
                    )}
                  </>
                ) : null}
              </div>
            </div>

            <div className="d-flex justify-content-center gap-3 pt-5">
              <Button
                className="changeCancelbtnColor rounded-pill px-5 py-1"
                onClick={(e) => {
                  if (!e.defaultPrevented) {
                    setCancel(false);
                  }
                }}
                style={{position:'unset'}}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                type="submit"
                className="changeSubmitbtnColor rounded-pill px-5 py-1"
                disabled={dropDown === 'true' || sessionStorage.getItem('Expense_Admin') === 'true'}
                onClick={(e) => {
                  e.preventDefault();
                  let allowApprove = 0;
                  if (
                    approvedOrRejected === "DECLINE" &&
                    (notes === null || notes === "")
                  ) {
                    setInvalid(true);
                    allowApprove = 1;
                  }
                  if (allowApprove === 0) {
                    setLoading(true);
                    sendData();
                  }
                }}
                style={{position:'unset'}}
                
              >
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </LoadingOverlay>
    </div>
  )
}

export default Details;