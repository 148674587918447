  import React, { useState, useEffect, useRef } from 'react';
  import { Formik, Form, Field } from 'formik';
  import * as Yup from 'yup';
  import { AgGridReact } from 'ag-grid-react';
  import 'ag-grid-community/styles/ag-grid.css';
  import 'ag-grid-community/styles/ag-theme-alpine.css';
  import axios from 'axios';
  import * as constants from '../../Utils/URLConstants';
  import LoadingOverlay from 'react-loading-overlay';

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Type is required'),
    key: Yup.string().trim()
      .required('Name is required')
      .matches(/^[A-Za-z_\s-]+$/, 'Name should contain only alphabets and underscores'),
    value: Yup.string().required('Description is required'),
    parentKey: Yup.string().when('name', {
      is: 'SECTION',
      then: Yup.string().required('Department is Required'),
      otherwise: Yup.string().nullable(),
    }),
  });


  const UpdateProfile = () => {
    const isHr = sessionStorage.getItem("isHr");
    const [departmentData, setDepartmentData] = useState([]);
    const [tableRowData, setTableRowData] = useState([]);
    const [selectFieldValue, setSelectFieldValue] = useState('REGION');
    const [parentKey,setParentKey] = useState('')
    const rowDataRef = useRef([]);
    let gridApi = useRef(null);
    const [loading, setLoading] = useState(false);

    const postDataToBackend = (values) => {
      setLoading(true);
      let data = [
        {
          name: selectFieldValue,
          key: values.key,
          value: values.value,
        
        },
      ];
      if (selectFieldValue === 'SECTION') {
        data[0].parentKey = parentKey;
      }
      console.log(data,'data');
      axios
        .post(constants.POST_FORM_DATA, data, {
          headers: {
            Authorization: sessionStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          getTableData(selectFieldValue, true);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    };

    const getTableData = (selectedValue, isFormSubmission = false) => {
    setLoading(true);
    const apiCalls = [];
    apiCalls.push(
      axios.get(constants.GET_TABLE_DATA + `/${selectedValue}`, {
        headers: {
          Authorization: sessionStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      })
    );
    
    if (selectedValue === "SECTION") {
      apiCalls.push(
        axios.get(constants.GET_TABLE_DATA + '/DEPARTMENT', {
          headers: {
            Authorization: sessionStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        })
      );
    }

    Promise.all(apiCalls)
    .then((results) => {
      if (isFormSubmission) {
        
        gridApi.current.paginationGoToFirstPage();
      }
      const data = results.map((result) =>
        result.data.map((item, index) => ({ sno: index + 1, ...item }))
      );
      const mergedData = data.reduce((acc, curr) => acc.concat(curr), []);
      let filteredData = [];

      if (selectedValue === "SECTION") {
        filteredData = mergedData.filter(item => item.name === "SECTION");
        let filteredData2 = mergedData.filter(item => item.name === "DEPARTMENT");
        const keys = filteredData2.map(obj => obj.key);
        setTableRowData(filteredData);
        setDepartmentData(keys);
      } else if (selectedValue === "DEPARTMENT") {
        filteredData = mergedData.filter(item => item.name === "DEPARTMENT");

        setTableRowData(filteredData);
      } else {
        filteredData = mergedData.filter(item => item.name === selectedValue);
        setTableRowData(filteredData);
      }

      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      console.log('Failed with error:', error);
    });
  };

    

    useEffect(() => {
      getTableData(selectFieldValue);
    }, [selectFieldValue]);

    const columnDefs = [
      { headerName: 'Sno', field: 'sno', width: 100, valueGetter: 'node.rowIndex + 1' },
      { headerName: 'Name', field: 'key', flex: 1 },
      { headerName: 'Description', field: 'value', flex: 1 },
      selectFieldValue === 'SECTION' && {headerName: 'Department',field:'parentKey',flex:1}
    ];

    const gridOptions = {
      rowData: rowDataRef.current,
      columnDefs: columnDefs,
      paginationPageSize: 5,
    };

    const onGridReady = (params) => {
      gridApi.current = params.api;
    };

    if (isHr === 'true') {
      return (
        <div>
          <span style={{ color: "#66676c", fontSize: "13px" }}>Dashboard &gt; Settings </span>
        
        <div className='w-100 rounded' style={{ height: '83vh', backgroundColor: '#fff', overflow: 'scroll' }}>
          
          <div className='position-absolute' style={{ top: '10px', right: '40px' }}></div>
          <h3 className='text-center'>Config Data</h3>
          <LoadingOverlay active={loading} spinner text='Loading Form... '>
            <div
              className='w-50 mx-auto p-3 rounded shadow-md mt-3'
              style={{ backgroundColor: '#f0f0f0' }}
            >
              <Formik
                initialValues={{
                  name: selectFieldValue,
                  key: '',
                  value: '',
                  parentKey:''
                }}
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm, setFieldValue }) => {
                  postDataToBackend(values);
                  resetForm();
                  setFieldValue('name',selectFieldValue);
                  setFieldValue('key', '');
                  setFieldValue('value', '');
                  if (selectFieldValue === 'SECTION') {
                    setFieldValue('parentKey','');
                    setParentKey(''); // Reset parentKey when submitting for SECTION
                  }
                }}
              >
                {({ errors, touched, setFieldValue }) => (
                  <Form>
                    <div className='form-row'>
                      <div className='form-group mb-3'>
                        <label htmlFor='name' style={{ width: 'max-content', height: 'unset' }}>
                          Type:
                        </label>
                        <Field
                          as='select'
                          name='name'
                          className='form-control px-2'
                          style={{ padding: '2px', marginTop: '-10px' }}
                          onChange={(e) => {
                            e.preventDefault();
                            const selectedValue = e.target.value;
                            setSelectFieldValue(selectedValue);
                            setFieldValue('name', selectedValue);
                            getTableData(selectedValue,true)
                          }}
                        >
                          <option value='REGION'>REGION</option>
                          <option value='DEPARTMENT'>DEPARTMENT</option>
                          <option value='SECTION'>SECTION</option>
                        </Field>
                        {errors.name && touched.name && (
                          <div className='text-danger'>{errors.name}</div>
                        )}
                      </div>

                      {selectFieldValue === 'SECTION' && (
                      <div className='form-group mb-3'>
                        <label htmlFor='parentKey' style={{ width: 'max-content', height: 'unset' }}>
                          Department: <span className='text-danger'>*</span>
                        </label>
                        <Field
                          as='select'
                          name='parentKey'
                          className='form-control px-2'
                          style={{ padding: '2px', marginTop: '-10px' }}
                          onChange={(e) => {
                            e.preventDefault();
                            const selectedValue = e.target.value;
                            setParentKey(selectedValue);
                            setFieldValue('parentKey',selectedValue);
                          }}
                          
                        >
                          <option value="" disabled>Select Department</option>
                          {departmentData?.map((key) => (
                            <option value={key} key={key}>
                              {key}
                            </option>
                          ))}
                        </Field>
                        {errors.parentKey && touched.parentKey && (
                          <div className='text-danger'>{errors.parentKey}</div>
                        )}


                      </div>
                    )}

                      <div className='form-group mb-3'>
                        <label htmlFor='key' style={{ width: 'max-content', height: 'unset' }}>
                          Name:
                        </label>
                        <Field
                          type='text'
                          name='key'
                          className='form-control px-2'
                          placeholder='Enter Name'
                          style={{ marginTop: '-10px', height: 'unset', padding: '2px' }}
                        />
                        {errors.key && touched.key && (
                          <div className='text-danger'>{errors.key}</div>
                        )}
                      </div>

                      <div className='form-group'>
                        <label htmlFor='value' style={{ width: 'max-content', height: 'unset' }}>
                          Description:
                        </label>
                        <Field
                          as='textarea'
                          name='value'
                          className='form-control'
                          style={{
                            height: '70px',
                            width: '100%',
                            resize: 'unset',
                            padding: '10px',
                            marginTop: '-10px',
                          }}
                        />
                        {errors.value && touched.value && (
                          <div className='text-danger'>{errors.value}</div>
                        )}
                      </div>
                    </div>
                    <div className='mt-2 d-flex justify-content-center gap-3'>
                      <button type='reset' className='btn btn-danger rounded-pill px-5' onClick={(e) => { getTableData("REGION"); setSelectFieldValue('REGION') }}>
                        Clear
                      </button>
                      <button type='submit' className='btn btn-primary rounded-pill px-5'>
                        Submit
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            {tableRowData.length > 0 && (
              <div
                className='ag-theme-alpine mt-2'
                style={{
                  height: '38vh',
                  width: '90%',
                  margin: 'auto',
                  borderRadius: '20px',
                }}
              >
                <AgGridReact
                  columnDefs={columnDefs}
                  rowData={tableRowData}
                  gridOptions={gridOptions}
                  pagination={true}
                  onGridReady={onGridReady}
                />
              </div>
            )}
          </LoadingOverlay>
        </div>
        </div>
      );
    }
    else {
      return (

        <div className='w-100 rounded' style={{ height: '83vh', backgroundColor: '#fff', overflow: 'scroll' }}>
          <div className='position-absolute' style={{ top: '10px', right: '40px' }}></div>
          <div><h3 className='text-center'>Access Denied !!!!</h3></div> </div>);
    }
  };

  export default UpdateProfile;
