import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import *  as constants from "../Utils/URLConstants";
import { useRef } from "react";

export default function MyLocation({ setThePostition, setUserDetails }) {

  const [address, setAddress] = React.useState("");
  const [count, setCount] = useState(0);
  const [reload, setReload] = useState(0);
  const [searchUser, setSearchUser] = useState("");
  const [showUsers, setShowUsers] = useState([]);
  const [addressSearch, setAddressSearch] = useState(false);
  const [filterSearch, setFilterSearch] = useState(false);
  const [keys, setKeys] = useState("");
  const [disable, setDisable] = useState(false);
  const [displayInput, setDisplayInput] = useState(false);
  const [dummyValue, setDummyValue] = useState("");
  const empNameRefFocus = useRef(null);
  const [latitudeAndLang, setLatitudeAndLang] = useState({
    lat: null,
    lng: null,
  });
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);

    setAddress(value);
    setLatitudeAndLang(latLng);
  };
  const getUserDetails = (e) => {

    setAddressSearch(true);
    setDisable(true);
    setDisplayInput(false);
    setSearchUser(e.target.value);

    if (searchUser === null) {
      setDisable(false);
    }
    else {
      setDisable(false);
    }
    if ((e.target.value.length) > 2) {
      setFilterSearch(true);


    }
    else {
      setFilterSearch(false);
    }
    setCount(count + 1);
  };

  const placeholderOptions = () => {
    axios
      .get(constants.GET_NAMES, {
        params: { name: searchUser },
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        const values = Object.entries(res.data);
        console.log(res.data);
        setShowUsers(values);

      })
      .catch((error) => {
        console.error("error", error);
      });
  };
  useEffect(() => {
    placeholderOptions();
  }, [count]);

  const handleSelectName = (e, k) => {
    setSearchUser(e.target.getAttribute('data-index'));
    setDisplayInput(false);
    empNameRefFocus.current.focus();
    setKeys(k);
    setFilterSearch(false);
  }


 //Enter key logic for search
 function handleKeyPress(event) {

  if (event.key === 'Enter') {
    console.log("Enter key")
    // Perform search functionality here
  
       submitHandler();
    
   
    //event.preventDefault();
  }
}

  const selectUserName = () => {

    axios
      .get(constants.GET_LOCATION, {
        params: { userName: keys },
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((res) => {

        setLatitudeAndLang.lat = res.data.lat;
        setLatitudeAndLang.lat = res.data.lng;
        // setKeys('');
        var userData = { lat: res.data.lat, lng: res.data.lng };
        if (userData.lat !== undefined) {
          setThePostition(userData);
        }
        else {
          alert(`No user found with ${searchUser}`)
        }
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  const submitHandler = () => {
    if (addressSearch && searchUser !== null) {
      selectUserName();
      setAddressSearch(false);
    } else if (latitudeAndLang.lat !== null) {
      setThePostition(latitudeAndLang);
    } else {
      alert("Type Something Before Search...");
    }
  };
  const setSomething = (e) => {
    setDummyValue(e.target.value);
    setSearchUser("");
  }

  return (
    <div>
      {/* <script src="https://maps.googleapis.com/maps/api/js?key=`AIzaSyC9tcZaM6oOsV94nndnT8IcZUmNRk_DiuM`&libraries=places"></script> */}
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <div className="topsearch">
              <input {...getInputProps({ placeholder: "Type address" })} hidden={!displayInput ? true : false} onClick={() => {setSearchUser("") 
             setFilterSearch(false);
            }} autoFocus onKeyUp={handleKeyPress}/>
              <input type="text" placeholder="Type address" hidden={displayInput ? true : false} value={dummyValue} onChange={setSomething} onClick={() => {

                setDisplayInput(true);
                setSearchUser("");
              }}
              />
              <input
                type="text"
                aria-autocomplete="list"
                aria-expanded={false}
                placeholder="Employee Name"
                onChange={getUserDetails}
                value={searchUser}
                onKeyUp={handleKeyPress}
                ref={empNameRefFocus}
              />

              <input
                type="button"
                id="searchbtn"
                defaultValue="Search"
                onClick={submitHandler}
              />
            </div>

            <div className="autocomplete-dropdown-container">
              {loading ? <div>...loading</div> : null}

              {suggestions.map((suggestion) => {
                const style = {
                  backgroundColor: suggestion.active ? "#41b6e6" : "#fff",
                  cursor: 'pointer'
                };

                return (
                  <div {...getSuggestionItemProps(suggestion, { style })}>
                    {suggestion.description}
                  </div>
                );
              })}
            </div>
            {filterSearch ? (

              <div className="showTheUsers autocomplete-dropdown">

                {showUsers?.map(([key, val]) => (

                  <li data-index={val} className="hoverList" onClick={event => handleSelectName(event, key)} key={key}>{val}</li>
                ))}
              </div>
            ) : (
              ""
            )}
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
}
