import React, { useState } from 'react'
import './ReportStyle.css'

function ReportDetails() {
 
  return (
    <div>
         <div id="dashboardlocation w-100">
        <span
          style={{
            color: "#66676c",
            fontSize: "13px",
            width:''
          }}
        >
          Dashboard &gt; Expense Sheet Report&gt; Report Details
        </span>
      </div>
     
        <div className="attendDivision mapDivision expanseSheetDetails ">
            <div className='backgroundColorForReport h-25 mt-3 rounded'>
                <div className=' d-flex  pt-4 justify-content-between'>
                    <p className='mx-3 w-50'>Cash Holder Name: <span className='fontWeightInReport'> ALSANIE, MOHAMMAD ABDULHAMEED M </span></p>
                    <p className='marginInTab w-25'>ACES ID: <span className='fontWeightInReport'> johnp</span></p>
                    <p className='marginInTab mx-5 w-25'>Exp. Sh. No.: <span className='fontWeightInReport'> 12345</span></p>
                </div>
                <div className='d-flex justify-content-evenly'>
                    <p className='mx-3'>Date Of Request: <span className='fontWeightInReport'>Johnp</span></p>
                    <p className='marginInTab mx-5'>Project Name: <span className='fontWeightInReport'> johnp</span></p>
               
            </div>
            </div>

            
        </div>
      
    </div>
  )
}

export default ReportDetails