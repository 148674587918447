import React, { useState, useEffect } from 'react'

import "./ScheduleStyle.css";
import CreateScheduleTable from './CreateScheduleTable'
import axios from "axios";
import * as constants from "../../Utils/URLConstants";
import deleteImg from "../../images/delete.svg";
import LoadingOverlay from 'react-loading-overlay';
import EditBtn from '../../images/Edit.svg';
import { Modal, Button } from "react-bootstrap";
import info from "../../images/info.svg"
import {
  Tooltip,
} from 'react-tippy';

import 'react-tippy/dist/tippy.css';

function ScheduleMainPage() {

  const [schedulePageFlag, setScheduleFlag] = useState(false);
  const [existScheduleName, setExistScheduleName] = useState(null);
  const [allSchedules, setAllSchedules] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [count, setCount] = useState(0);
  const [isDelete, setIsDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(0);

  const deleteScheduleById = () => {
    axios
      .delete(constants.DELETE_SCHEDULE, {
        params: { id: deleteId },

        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        closeModal();
        setCount(count + 1);

      })
      .catch((error) => {
        console.log("failed with error ", error);
      });
  };

  const getAllSchedules = async () => {
    setLoading(true);
    axios
      .get(constants.GET_ALL_SCHEDULES, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        setAllSchedules(result.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("failed with error ", error);
      });
  };

  useEffect(() => {
    getAllSchedules();
  }, [count]);

  const cancelStatus = (flagValue) => {
    setCount(count + 1);
    setScheduleFlag(flagValue);
  }

  const closeModal = () => {
    setDeleteId(0);
    setIsDelete(false);
  }


  if (schedulePageFlag) {
    return <CreateScheduleTable cancelStatus={cancelStatus} existScheduleName={existScheduleName} isEdit={isEdit} />
  }
  else {
    return (
      <div>
        <p style={{ color: "#66676c", fontSize: "13px" }}>Dashboard &gt; Schedule</p>
        <div className="bg-white p-3 rounded d-flex justify-content-between">
          <div>
            <input
              type="text"
              name=""
              id=""
              placeholder="Search text"
              className="border border-black px-2 py-1 widthForInput mx-4 rounded"
              style={{ all: 'unset', visibility: 'hidden' }}
            />
          </div>
          {/* Cancel and Save Buttons in header */}
          <div className="mx-5">
            <button onClick={(e) => {
              e.preventDefault()
              setExistScheduleName(null);
              setScheduleFlag(true);
              setIsEdit(false);
            }} className="btn btn-primary py-1">Create Schedule</button>
          </div>
        </div>


        <div className='mt-3' style={{ height: '65vh', overflow: 'scroll', overflowX: 'hidden' }}>
          {allSchedules !== null ?
            allSchedules.map((item, i) => (
              <div className='w-100 bg-white mt-3 py-2 px-5 rounded-4 border border-2 shadow-sm d-flex align-items-center'>
                <span className='fontWeight' style={{width:'35%' ,marginRight:'10%'}}>{item.name}</span>
                <span className='fontWeight' style={{width:'35%' ,marginRight:'10%'}}>{item.createdByFullName}({item.title})</span>
                <div className='d-flex justify-content-between' style={{ width: '10%' }}>
                  {/* <img src={deleteImg} alt="delImg" /> */}
                 
                 
                 {item.isEditable ?
                    <img src={EditBtn} alt="editImg" width="20px" onClick={(e) => {
                      e.preventDefault()
                      setExistScheduleName(item.name)
                      setScheduleFlag(true);
                      setIsEdit(true);
                    }}  style={{ opacity:1 }}/>
                    : <img src={EditBtn} alt="editImg" width="20px" onClick={(e) => {
                      e.preventDefault()
                      setExistScheduleName(item.name)
                      setScheduleFlag(true);
                      setIsEdit(true);
                    }}  style={{ opacity: 0.4, pointerEvents: 'none' }}/>}


{item.isEditable ?
                    <img src={deleteImg} alt="delImg" onClick={(e) => {
                      e.preventDefault()
                      //deleteScheduleById(item.id);
                      
                      setDeleteId(item.id);
                      setIsDelete(true);
                    }}  style={{ opacity: 1}}/>
                    :  <img src={deleteImg} alt="delImg" onClick={(e) => {
                      e.preventDefault()
                      //deleteScheduleById(item.id);
                      
                      setDeleteId(item.id);
                      setIsDelete(true);
                    }}  style={{ opacity: 0.4, pointerEvents: 'none' }}/>}




                  <Tooltip
                    html={
                      <div style={{ maxWidth: "1500px", overflowX: "auto" }}>
                        <table border="1px" width="250%" style={{ height: '200%' }} cellSpacing="3px">
                          <tr>
                            <th style={{ position: "sticky", left: 0, zIndex: 100, backgroundColor: 'white' }}></th>
                          
                            <th>0</th>
                            <th>1</th>
                            <th>2</th>
                            <th>3</th>
                            <th>4</th>
                            <th>5</th>
                            <th>6</th>
                            <th>7</th>
                            <th>8</th>
                            <th>9</th>
                            <th>10</th>
                            <th>11</th>
                            <th>12</th>
                            <th>13</th>
                            <th>14</th>
                            <th>15</th>
                            <th>16</th>
                            <th>17</th>
                            <th>18</th>
                            <th>19</th>
                            <th>20</th>
                            <th>21</th>
                            <th>22</th>
                            <th>23</th>
                          </tr>
                          {item.details.map((item, i) => (
                            <tr key={i}>

                              <th style={{ position: "sticky", left: 0, zIndex: 10, backgroundColor: 'white', textAlign: 'left', paddingLeft: '2%', marginTop: '10px' }}>{item.dayOfWeek}</th>
                              {Object.entries(item.hours).map(([key, value]) => (
                                <td key={key}>{value}</td>
                              ))}
                            </tr>
                          ))}
                        </table>
                      </div>
                    }
                    position="bottom-right"
                    theme="light"
                    interactive
                    arrow={true}
                  >
                    <img src={info} alt="infoImg" width="25px" />
                  </Tooltip>


                </div>

              </div>)) : <div>   <LoadingOverlay active={loading} spinner text="Loading Data... " /></div>}


        </div>

        <Modal show={isDelete} onHide={closeModal}>
          <Modal.Header closeButton> </Modal.Header>
          <Modal.Body>
            Are you sure you want delete <b>Schedule</b> ?
          </Modal.Body>
          <Modal.Footer>
            <div className="displayflexforpopupdelete">
              <Button
                variant="secondary"
                className="yesBtninpopup"
                onClick={deleteScheduleById}
              >
                Yes
              </Button>
              <Button
                variant="secondary"
                className="noBtninpopup"
                onClick={closeModal}
              >
                No
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }


}

export default ScheduleMainPage