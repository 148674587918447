import React from 'react'

function Policy() {
  return (
    <div className='mx-5 my-5 shadow-lg my-5 mx-5 rounded'>
       <div>
        <p className='h2 text-center pt-4'>Privacy-Policy</p>
        <hr />
       <p className='h4 fw-bold mx-2 my-4' style={{fontWeight:'bold'}}> Advanced Communications and Electronics Systems Co. Ltd.</p>


<li className='mb-4 mx-2'>Established in 1994, Advanced Communications and Electronics Systems (ACES)
 is a leading ISO-certified Neutral Host, Telecom, Power, ICT and Security & Managed services system integrator company,
 with operations across the GCC, wider MENA region and APAC, implementing the infrastructure of mega projects.</li>
 
 <li className='mb-4  mx-2'>ACES is a multi-faceted enterprise with a focus on developing and distributing total solutions in the areas of wireless communication,
 information technology systems, and network testing & monitoring. ACES operates as a Neutral Host (NH) Neutral Host Operator in Saudi Arabia 
 with Wholesale infrastructure license from CITC and India with UL/VNO Category B license from DoT. The company services the demand for IBS 
 infrastructure (In-Building Solutions) from telecom operators along with offering cost-effective IBS networks capable of accommodating several 
 mobile operators, thus reducing the investment costs of telecom operators along with improving the quality of service.</li>
 
 
 <li className='mb-4  mx-2'>ACES one app is a Work Force Management Application (WFMS) designed for the company employees to the schedules that are assigned to them. According to the schedules, employees can punch in and
 punch out to keep track of their attendance. During punch in and punch out, app requests for the authnication and their photo.</li>
 
 <li className='mb-4  mx-2'>Employees are requested about their current location and keeps track if there are at assigned location for the work. This requires to track online status and
 their location even when the app is in pause/close state.</li>
 
 <li className='mb-4  mx-2'>The main intention is gain the confidence of the client and how we slove the problem and keep track of the employee at the site location.</li>
 
 <li className='pb-4  mx-2'>None of the information is shared to other vendor, soley used with in the company.</li>
       </div>
    </div>
  )
}

export default Policy