import React, { useEffect, useState } from "react";
import BarChart from "./BarChart";
import Button from "react-bootstrap/Button";
import GroupUsers from "../images/GroupUsers.svg";
import AbsentUsers from "../images/AbsentUsers.svg";
import Table from "react-bootstrap/Table";
import svgimg from "../images/svgfile.svg";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useHistory } from "react-router-dom";
import axios from "axios";
import technician from "../images/electrician.png";
import *  as constants from "../Utils/URLConstants";

function Dashboard() {
  const letter = "J";
  const history = useHistory();
  const [locationData, setLocationData] = useState([]);
  const [profileData, setProfileData] = useState(null);
  const [userData, setUserData] = useState(null);
  const profileCompletion = () => {
    axios
      .get(constants.PROFILE_STATUS, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        setProfileData(result.data);

      })
      .catch((error) => {
        console.log("failed with error ", error);
      });

  }



  const userProfile = () => {
    axios
      .get(constants.IQMA_EXPIRY, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((result) => {

        setUserData(result.data);

      })
      .catch((error) => {
        console.log("failed with error ", error);
      });

  }


  useEffect(() => {
    profileCompletion();
    userProfile();
  }, []);

  const gotoLocation = () => {
    axios
      .get(constants.LOCATION, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        setLocationData(result.data);
      })
      .catch((error) => {
        console.log("failed with error ", error);
      });
  };
  useEffect(() => {
    gotoLocation();
  }, []);

  return (
    <div>
      <div id="dashboardlocation">
        <span style={{ color: "#66676c", fontSize: "13px" }}>
          Dashboard &gt; Dashboard
        </span>
      </div>
      <div className="dashboardPage">
        <div className="divone">
          <BarChart></BarChart>

          {/* <hr className="hrtag2" /> */}
        </div>

        <div className="divtwo">
          <div className="twoone">
            <p className="requesttextAndCommon">Invoice</p>
            <div className="pendingCompletedText">
              {/* <p className="textandButtonindashboard">
                <p>114</p> <Button className="openButton">Open</Button>
              </p>
              <p className="verticalline">
                <hr />
              </p>
              <p className="textandButtonindashboard">
                <p>24</p> <Button className="pendingBtn">Pending</Button>
              </p> */}
              <p></p>
            </div>
          </div>
          <div className="twotwo">
            <p className="requesttextAndCommon">My Team</p>
            <div className="usersAndButton">
              <p>
                <img src={GroupUsers} />

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ position: "relative", left: "-20px" }}
                  width="39.22"
                  height="39.22"
                  viewBox="0 0 39.22 39.22"
                >
                  <g
                    id="Group_617"
                    data-name="Group 617"
                    transform="translate(0 0)"
                  >
                    <circle
                      id="Ellipse_240"
                      data-name="Ellipse 240"
                      cx="19.61"
                      cy="19.61"
                      r="19.61"
                      transform="translate(0 0)"
                      fill="#c9ecff"
                    />
                    <text
                      id="R"
                      transform="translate(12.282 27.351)"
                      fill="#029ceb"
                      font-size="23"
                      font-family="Roboto-Medium, Roboto"
                      font-weight="500"
                      letter-spacing="0.02em"
                    >
                      <tspan x="0" y="0">
                        {letter}
                      </tspan>
                    </text>
                  </g>
                </svg>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style={{ position: "relative", left: "-35px" }}
                  width="38.54"
                  height="38.54"
                  viewBox="0 0 38.54 38.54"
                >
                  <g
                    id="Group_618"
                    data-name="Group 618"
                    transform="translate(0 0)"
                  >
                    <circle
                      id="Ellipse_240"
                      data-name="Ellipse 240"
                      cx="19.27"
                      cy="19.27"
                      r="19.27"
                      transform="translate(0 0)"
                      fill="#f8869b"
                    />
                    <text
                      id="S"
                      transform="translate(12.693 25.451)"
                      fill="#e50c35"
                      font-size="21"
                      font-family="Roboto-Medium, Roboto"
                      font-weight="500"
                      letter-spacing="0.02em"
                    >
                      <tspan x="0" y="0">
                        {letter}
                      </tspan>
                    </text>
                  </g>
                </svg>
              </p>
              <Button
                className="viewButton"
                onClick={(event) => {
                  if (!event.defaultPrevented) {
                    history.push("/employee");
                  }
                }}
              >
                View More
              </Button>
            </div>
          </div>
          <div className="twothree">
            <p className="requesttextAndCommon">Absent on Yesterday</p>
            <div className="usersAndButton">
              <p>
                <img src={AbsentUsers} />
              </p>
              <Button
                className="viewButton"
                onClick={(event) => {
                  if (!event.defaultPrevented) {
                    sessionStorage.setItem("absent", true);
                    history.push("/employee");
                  }
                }}
              >
                View More
              </Button>
            </div>
          </div>
        </div>
        <div className="divthree">
          <p className="requesttextAndCommon CommonFlexinDashboardButtons">
            <p>Live Location</p>
          </p>
          <div className="allUsersInDiv3">
            {locationData?.map((name) => (
              <div
                className="usersInDashboardDiv3"
                onClick={(event) => {
                  if (!event.defaultPrevented) {
                    const latLng = [
                      {
                        id: 1,
                        lat: name.lat,
                        lng: name.lng,
                      },
                    ];

                    sessionStorage.setItem("lat", JSON.stringify(latLng));
                    history.push("/location");
                  }
                }}
              >
                <p className="CmpNameInDashbord">{name.province}</p>

                <p>
                  <svg
                    id="Group_31918"
                    style={{ position: "relative", left: "50px" }}
                    className="usersInDashborddiv3"
                    data-name="Group 31918"
                    xmlns="http://www.w3.org/2000/svg"
                    width="41.626"
                    height="41.626"
                    viewBox="0 0 41.626 41.626"
                  >
                    <circle
                      id="Ellipse_240"
                      data-name="Ellipse 240"
                      cx="20.813"
                      cy="20.813"
                      r="20.813"
                      transform="translate(0 0)"
                      fill="#4791fc"
                    />
                    <text
                      id="_10"
                      data-name="+10"
                      transform="translate(7.024 25.443)"
                      fill="#fff"
                      font-size="15"
                      font-family="Roboto-Medium, Roboto"
                      font-weight="500"
                      letter-spacing="0.02em"
                    >
                      <tspan x="0" y="0">
                        +{name.count}
                      </tspan>
                    </text>
                  </svg>

                  <img src={GroupUsers} className="usersInDashborddiv3" />
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="divfour">
          <div className="fourone">
            <p className="requesttextAndCommon CommonFlexinDashboardButtons">
              <p>Iqama Expiry</p>
            </p>
            <div className="tableinDahboard">

              <Table hover>
                <tbody>
                  {
                    userData?.map((item, i) => (
                      <tr key={i}>
                        <td>
                          {
                            item.imageUrl !== null ?
                              <img
                                src={item.imageUrl}
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "50%",
                                  marginRight: '55px',
                                }}
                              />
                              :
                              <img
                                src={technician}
                                style={{
                                  width: "30px",
                                  height: "30px",
                                  borderRadius: "50%",
                                }}
                              />
                          }
                        </td>
                        <td className="nameInDashboard">{item.name}</td>
                        <td className="mobileInDashboard">{item.phone}</td>
                        <td className="dateInDashboard">{item.iqamaExpiry}</td>
                      </tr>

                    ))
                  }
                </tbody>
              </Table>


            </div>
          </div>
          <div className="fourtwo">
            <p className="requesttextAndCommon CommonFlexinDashboardButtons">
              <p>Profile Completion</p>

            </p>

            <p className="userProgressBar">
              <div className="tableinDahboard2">
                <Table hover>
                  <tbody>
                    {profileData?.map((item, i) => (
                      <tr key={i}>
                        <td style={{ width: "30%" }}>{item.name}</td>
                        <td style={{ width: "60%" }}>
                          <ProgressBar
                            now={item.percentage}
                            variant={
                              item.percentage < "50"
                                ? "danger"
                                : item.percentage >= 50 && item.percentage <= 75
                                  ? "success"
                                  : "secondary"
                            }
                            className="ProgressBarinDashboard"
                          />
                        </td>
                        <td style={{ width: "20%" }}>{item.percentage}%</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;