import React, { useState } from 'react';
import axios from 'axios';
import './log-in.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ReactSnackBar from 'react-js-snackbar';
import *  as constants from "../Utils/URLConstants";
import Form from 'react-bootstrap/Form';
import {  Oval} from 'react-loader-spinner';
import { FastField } from 'formik';

function ForgotPassword(props) {
  const [userName, setUserName] = useState('');
  const [otp, setOtp] = useState('');
  const [Password, setPassword] = useState("");
  const [cnfPassword, setCnfPassword] = useState("");
  const [error, setError] = useState(null);
  const [otpMsg, setOtpMsg] = useState("Send OTP")
  const [otpCheck, setOtpCheck] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [emoji, setEmoji] = useState("");
  const [snackmessage, setSnackMessage] = useState("");
  const [loading,setLoading] = useState(false);
  const [submitLoading,setSubmitLoading] = useState(false);

  const snackEnable = () => {
    setTimeout(() => {
      setShowSnackBar(false);
    }, 4000);
  }
  const userNameStyle = {
    position: "absolute",
    right: "13%",
    top: otpCheck ? "23vh" : "48vh",
    height: "6%",
    width: "26%",
    marginTop:'unset'

  }

  const otpStyle = {
    position: "absolute",
    right: "13%",
    top: "35vh",
    height: "6%",
    width: "26%",
    marginTop:'unset',
    display: otpCheck ? 'block' : 'none'
  }
  const passwordStyle = {
    position: "absolute",
    right: "13%",
    top: "43vh",
    height: "6%",
    marginTop:'unset',
    width: "26%",
    display: otpCheck ? 'block' : 'none'
  }
  const cnfPassStyle = {
    position: "absolute",
    right: "13%",
    top: "52vh",
    height: "6%",
    marginTop:'unset',
    width: "26%",
    display: otpCheck ? 'block' : 'none'
  }
  const submitStyle = {

    position: "absolute",
    top: "67vh",
    display: otpCheck ? 'block' : 'none',
    width: '12%',
    height: '6%',
    background: submitLoading?'white': 'rgba(229, 12, 53, 1)',
    right: '13%',
    border: submitLoading?'white':'1.0625px solid rgba(112, 112, 112, 1)',
    fontFamily: 'Roboto',
    fontSize: '1.188rem',
    color: '#FFFFFF',

    borderRadius: '39px',
    // overflow: hidden;
  }
  const cancelBtnStyle = {

    position: "absolute",
    top: "67vh",
    // display:otpCheck?'block':'none',
    width: '12%',
    height: '6%',
    background: 'rgba(229, 12, 53, 1)',
    right: otpCheck ? '27%' : '21%',
    border: '1.0625px solid rgba(112, 112, 112, 1)',
    fontFamily: 'Roboto',
    fontSize: '1.188rem',
    color: '#FFFFFF',
    borderRadius: '39px',
  }
  const sentOtpStyle = {
    position: "absolute",
    right: otpCheck ? "-7%" : "-8%",
    top: otpCheck ? "30vh" : "55vh",
    height: "6%",
    width: "26%",
    cursor: 'pointer',
    color: 'blue'

  }

  const resetPassword = {
    position: 'absolute',
    top: otpCheck ? '15vh' : '40vh',
    width:"30%",
    left: "61%"
  }

  const handleEnter = (e) => {

    if (e.key === 'Enter') {
      if (!e.defaultPrevented) {
        handleResetPassword();

      }
    }
  }

  const handleOtp = () => {
    setError(null);
    setLoading(true);
    setOtpMsg("");
    if (userName === null || userName === "") {
      setLoading(false);
      setError("ACES Id : should not be blank");
    }
    else {
      axios.post(constants.GENERATE_OTP, {},
        {
          headers: {
            userName: userName,
          },
        }
      ).then(() => {
        setOtpMsg("Resend Now ?");
        setOtpCheck(true);
        setLoading(false);
        setShowSnackBar(true);
        setSnackMessage("OTP Sent Successfully.");
        snackEnable();
        setEmoji("✔️");
      }).catch((err) => {
        console.error(err);
        setLoading(false);
        setError("No user Found with: " + userName);
      })
    }
  }

  const handleResetPassword = () => {
    setSubmitLoading(true);
    setError(null);
    if (otp === null || otp === "") {
      setSubmitLoading(false);
      setError("Otp : should not be blank");
    }
    else if (Password === null || Password === "") {
      setSubmitLoading(false);
      setError("Password : should not be blank");
    }
    else if (cnfPassword === null || cnfPassword === "") {
      setSubmitLoading(false);
      setError("Confirm Password : should not be blank");
    }
    else if (Password !== cnfPassword) {
      setSubmitLoading(false);
      setShowSnackBar(true);
      setSnackMessage("Both password need to be the same");
      snackEnable();
      setEmoji("❌");
    }
    else if (Password.length < 8) {
      setSubmitLoading(false);
      setShowSnackBar(true);
      setSnackMessage("Password should be 8 chars minimum.");
      snackEnable();
      setEmoji("❌");
    }
    else {

      axios.post(constants.UPDATE_PASSWORD, {
        userName: userName,
        password: Password,
        otp: otp
      }).then((res) => {
        setSubmitLoading(false);
        setSnackMessage('Password changed Successfully!');
        props.history.push('/');
      }).catch((err) => {
        setSubmitLoading(false);
        setShowSnackBar(true);
        setSnackMessage("Invalid OTP. Enter Correct OTP");
        snackEnable();
        setEmoji("❌");
        console.error(err);
      })
    }

  }
  // const handlePassword = (e) =>{
  //   setPassword(e.target.value);
  //   if(e.target.value.length<8)
  //   {
  //     setCheckLength(false);
  //     console.log("first");
  //     setError("Password is too short - should be 8 chars minimum.");
  //   }
  //   else{
  //     setCheckLength(true);
  //     setError("");
  //   }
  // }

  return (
    <div>
      <ReactSnackBar Icon={<span>{emoji}</span>} Show={showSnackBar}>
        {snackmessage}
      </ReactSnackBar>
      <div class="pageBody">
        <div class="main">
          <div class="arch1">
            <span class="rl"></span>
            <span class="rl1"></span>
            <span class="rl2"></span>
            <span class="rl3"></span>
          </div>
          <h1 class="title">ACES</h1>
          <div class="line"></div>
          <span class="description">Advanced Communications & Electronic Systems Company</span>
        </div>
        <div class="form">
          <span class="form-bg"></span>
          <span class="form-head" style={resetPassword}>Reset Password</span>
          <Form>

            <Form.Control readOnly={otpCheck ? true : false} type="text" class="aces-id" placeholder="Enter UserName(ACES Id)" id="Aces Id" value={userName} onChange={(e) => setUserName(e.target.value)} style={userNameStyle} />
            <p style={sentOtpStyle} onClick={loading?"":handleOtp} >{otpMsg}</p>

            <Form.Control type="text" class="aces-id" placeholder="Enter OTP" id="User Id" value={otp} onChange={(e) => setOtp(e.target.value)} style={otpStyle} />
            <Form.Control type="password" class="aces-id" placeholder="Enter Password" value={Password} onChange={(e) => {
              setPassword(e.target.value);
            }} style={passwordStyle} />
            <Form.Control type="password" class="aces-id" placeholder="Re-Enter Password" value={cnfPassword} onChange={(e) => setCnfPassword(e.target.value)} style={cnfPassStyle} onKeyUp={handleEnter} />
            <p id='errMsg'>{error && <><small >{error}</small></>}</p>
          </Form>
          <Oval
            height={otpCheck?25:30}
            width={otpCheck?30:45}
            color="blue"
            wrapperStyle={sentOtpStyle}
            wrapperClass=""
            visible={loading}
            ariaLabel='oval-loading'
            secondaryColor="#FFFFFF"
            strokeWidth={4}
            strokeWidthSecondary={4}

          />
{
            submitLoading?
            <Oval
            height={45}
            width={45}
            color="blue"
            wrapperStyle={submitStyle}
            wrapperClass=""
            visible={submitLoading}
            ariaLabel='oval-loading'
            secondaryColor="#FFFFFF"
            strokeWidth={4}
            strokeWidthSecondary={4}

          />:  <button style={submitStyle} onClick={(e) => 
            submitLoading ? " " : handleResetPassword()
          }>Submit</button>
            
            
          }
        
          <button style={cancelBtnStyle} onClick={(e) => {
            if (!e.defaultPrevented) {
              if (otpCheck) {
                setError("");
                setOtpMsg('Send OTP')
                setOtpCheck(false)
              }
              else {
                props.history.push('/');
              }
            }
          }}>Cancel</button>
        </div>
      </div>
    </div>
  );
}
export default ForgotPassword;