
import React, { useEffect, useState } from 'react'
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import BootstrapSelect from 'react-bootstrap-select-dropdown';
import { Button, Form } from 'react-bootstrap';
import * as constants from "../../Utils/URLConstants";
import Axios from 'axios';

function ReportTab() {
    // const [activeTab,setActiveTab] = useState(false);
    // const selectedIcon = (e) =>{
    //     console.log(e)
    //     setActiveTab(true);
    // }
    const [count,setCount] = useState(0);
    const [deptSearch,setDeptSearch] = useState("");
  const [empNameSearch,setEmpNameSearch] = useState("");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [focusedInput, setFocusedInput] = useState(null);
  const handleChange = (event) => {
    setDropDownValue(event.target.value);
    console.log(event.target.value);
  };
   const [dropDownValue, setDropDownValue] = React.useState('');
  const options = [
    {
      "labelKey": "ALL_EMP_REPORT",
      "value": "All Employee in Company",
      
    },
    {
      "labelKey": "DEPARTMENT_REPORT",
      "value": "All Employee in Department",
    },
    {
      "labelKey": "EMPLOYEE_REPORT",
      "value": "By Employee Name",
      
    },
    {
      "labelKey": "rep1",
      "value": "report 4",
      
    },
  ]


  const ApiCall=()=>{
    Axios.get(constants.GET_All_EMP_DATA, {
      params: { 
        userName:'kiranp',
        startDate:'2023-02-01',
        endDate:'2023-03-30'
       },
      headers: {
        "Authorization": sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    }).then((result) => {
        console.log(result.data);
    }).catch((err)=>{
      console.error(err);
    })
  }


  useEffect(()=>{
      ApiCall();
  },[count])
  let handleBackend = e =>{
    setDropDownValue(e.selectedKey[0]);
    setCount(count+1);
  }
  return (
    <div>
        <div className="bg-white d-flex justify-content-between topBar position-relative">
          {/* Select */}
        <BootstrapSelect options={options}  placeholder="Reports" onChange={e=>handleBackend(e)} style={{marginTop:'6px'}}/>
  
                {/* Datepicker */}
                <div className=''>
                <DateRangePicker
                  startDate={startDate}
                  startDateId="s_id"
                  endDate={endDate}
                  endDateId="e_id"
                  onDatesChange={({ startDate, endDate }) => { setStartDate(startDate); setEndDate(endDate)}}
                  focusedInput={focusedInput}
                  onFocusChange={e => setFocusedInput(e)}
                  displayFormat="DD/MM/YYYY"
                />
                </div>


        {/* Department name */}
        {
          dropDownValue === "DEPARTMENT_REPORT" ?
          <div className='dropdown-1'>
                <div className='position-relative'>
                <input class="form-control form-edit" type="search" placeholder="Department Name"/>
             {/* <div className=' bg-danger position-absolute w-100 overflow-scroll' style={{zIndex:'10',maxHeight:'500%'}}>
             <p>John</p>
             <p>John</p>
             <p>John</p>
             <p>John</p>
             <p>John</p>
             <p>John</p>
             <p>John</p>
             <p>John</p>            
             </div> */}
                </div>
          </div>:''
        }
                
       
       {/* Employee name */}
       {
        dropDownValue === "EMPLOYEE_REPORT" ||  dropDownValue === "rep1" ? 
        <div className='dropdown-1'>
        <div className='position-relative'>
        <input class="form-control form-edit" type="search" placeholder="Employee Name"/>
     {/* <div className=' bg-danger position-absolute w-100 overflow-y-scroll overflow-x-hidden' style={{zIndex:'10',maxHeight:'500%'}}>
        <p>John</p>

     </div> */}
        </div>
        </div>:''
       }
               

      {/* Search */}
                <input
                  type="button"
                  className="reportTabSearchBtn"
                  defaultValue="Search"
                  // onClick={handleSearch}
                />
            </div>
            <div className="attendDivision mapDivision">



              {/* Table 1 */}
              {
                dropDownValue!=="rep1"?
                <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                   {dropDownValue === "company"?
                   <th scope="col" className='changeColor small'>Department</th> :''
                   } 
                    {dropDownValue === "company" || dropDownValue === "DEPARTMENT_REPORT"?<>
                   <th scope="col" className='changeColor small'>Employee number</th>
                   <th scope="col" className='changeColor small'>Employee name</th> </>:''
                   } 
                   
                    <th scope="col" className='changeColor small'>Receive petty cash</th>
                    <th scope="col" className='changeColor small'>Approve petty cash</th>
                    <th scope="col" className='changeColor small'>Balance in Financial</th>
                    <th scope="col" className='changeColor small'>Pending petty cash in a direct manger</th>
                    <th scope="col" className='changeColor small'>Pending petty cash in a head department</th>
                    <th scope="col" className='changeColor small'>Pending petty cash in a financial</th>
                  </tr>
                </thead>
                <tbody>
              
                  <tr>
                  {dropDownValue === "company"?
                    <td className='small text-center'>CME</td>:''
                  }
                     {dropDownValue === "company" || dropDownValue === "DEPARTMENT_REPORT"?<>
                     <td className='small text-center'>Larry</td>
                    <td className='small text-center'>the Bird</td>
                     </>:''}
                  
                    <td className='small text-center'>Balireddy John Prakash </td>
                    <td className='small text-center'>Larry</td>
                    <td className='small text-center'>the Bird</td>
                    <td className='small text-center'>@twitter</td>
                    <td className='small text-center'>Larry</td>
                    <td className='small text-center'>the Bird</td>
                   
                  </tr>
              
                  
                </tbody>
                          </table>
                          :''
              }
           
{/* Table - 2 */}
           {
                 dropDownValue === "rep1"?
                 <table class="table table-striped table-bordered">
                 <thead>
                   <tr>
                  
                    <th scope="col" className='changeColor small'>S.No</th> 
                  
                    
                     <th scope="col" className='changeColor small text-center'>Vat no</th>
                     <th scope="col" className='changeColor small text-center'>Invoice no.</th>
                     <th scope="col" className='changeColor small text-center'>Supplier name</th>
                     <th scope="col" className='changeColor small text-center'>Date</th>
                     <th scope="col" className='changeColor small text-center'>Amount</th>
                     <th scope="col" className='changeColor small text-center'>Vat</th>
                     <th scope="col" className='changeColor small text-center'>Total amount</th>
                     <th scope="col" className='changeColor small text-center'>Category</th>
                     <th scope="col" className='changeColor small text-center'>Description</th>
                     <th scope="col" className='changeColor small text-center'>Cost center</th>
                     <th scope="col" className='changeColor small text-center'>Remarks</th>
                   </tr>
                 </thead>
                 <tbody>
               
                   <tr>
                 
                     <td scope='row text-center'>001</td>
                    
                      <td className='small text-center'>Larry</td>
                     <td className='small text-center'>9502419692</td>
                   
                   
                     <td className='small text-center text-nowrap'>Balireddy John Prakash </td>
                     <td className='small text-center text-nowrap'>29-04-2023</td>
                     <td className='small text-center'>the Bird</td>
                     <td className='small text-center'>@twitter</td>
                     <td className='small text-center'>Larry</td>
                     <td className='small text-center'>the Bird</td>
                     <td className='small text-center w-25'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fuga, facere? Molestias minima illo, facere eveniet in praesentium sapiente repellat unde est rem voluptas asperiores aut iste fugiat? Doloribus, aut aliquid!</td>
                     <td className='small text-center'>Larry</td>
                     <td className='small text-center w-25'>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fuga, facere? Molestias minima illo, facere eveniet in praesentium sapiente repellat unde est rem voluptas asperiores aut iste fugiat? Doloribus, aut aliquid!</td>
                    
                   </tr>
               
                   
                 </tbody>
                 <tfoot>
                  <tr>
                    <td className='text-nowrap FontBold'>Vat Tax</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                   
                    <td className='FontBold'>424$</td>
                  </tr>
                 </tfoot>
                 <tfoot>
                  <tr>
                    <td className='text-nowrap FontBold'>Total</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                   
                    <td className='FontBold'>800$</td>
                  </tr>
                 </tfoot>
                           </table>:''
           }

            </div>
    </div>
  )
}

export default ReportTab

