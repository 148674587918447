import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { GoogleMap, useLoadScript, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api";
import searchimg from '../images/searchimg.png';
import technician from '../images/electrician.png';
import './style.css';
import LocatonSearch from './LocatonSearch';
import * as constants from "../Utils/URLConstants";
import MyLocation from './MyLocation';

const customizeMap = {
  width: '97%',
  height: '80%',
  borderRadius: '10px',
  marginLeft: '20px',
  marginTop: '20px'
};

function Location() {
  const [activeMarker, setActiveMarker] = useState(null);
  const [reload, setReload] = useState(false);
  const [markers, setMarkers] = useState([]);
  const [filterTrue, setFilterTrue] = useState(false);
  const [filterUserDetails, setFilterUserDetails] = useState('');
  const mapRef = useRef(null);
  const [position, setPosition] = useState({
    lat: 24.757359649439728,
    lng: 46.674179099828635
  });
  const [centralPosition, setCentralPosition] = useState({
    lat: 24.757359649439728,
    lng: 46.674179099828635
  });
  const [initZoom, setInitZoom] = useState(10);

  const setUserDetails = (latLng) => {
    setFilterUserDetails(latLng);
    setFilterTrue(true);
  };

  if (sessionStorage.getItem('lat')) {
    const loc = JSON.parse(sessionStorage.getItem('lat'));
    position.lat = loc[0].lat;
    position.lng = loc[0].lng;
    sessionStorage.removeItem('lat');
  }

  const setThePostition = (latLng) => {
    setPosition(latLng);
    setInitZoom(13);
  };

  const fetchLocation = () => {
    axios.get(constants.LOCATION_DATA, {
      headers: {
        "Authorization": sessionStorage.getItem('token'),
        'Content-Type': 'application/json'
      }
    })
      .then((res) => {
        const updatedMarkers = res.data.map((marker, index) => ({
          ...marker,
          lat: marker.lat + (Math.random() * 0.0001), // Add a small random offset to the latitude
          lng: marker.lng + (Math.random() * 0.0001)  // Add a small random offset to the longitude
        }));
        setMarkers(updatedMarkers);
        setReload(true);
      })
      .catch((error) => {
        console.error("error", error)
      })
  };

  useEffect(() => {
    fetchLocation();
  }, [reload]);

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  function handleCenter() {
    if (!mapRef.current) return;
    const newPos = mapRef.current.getCenter().toJSON();
    setPosition(newPos);
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "YOUR_GOOGLE_MAPS_API_KEY"
  });

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(position);
map.fitBounds(bounds);
    // setMap(map);
  }, []);
    return (
        isLoaded ? (
            <div>
                <div id="dashboardlocation">
                    <span style={{ color: '#66676c', fontSize: '13px' }}>Dashboard &gt; Location</span>
                </div>
                <div className="mapDivision">
                    <div className="topsearch">


                    </div>

                    <div id='RealMap'>

                        <MyLocation setThePostition={setThePostition} setUserDetails={setUserDetails} />
                        {/* <LocatonSearch apiKey={`AIzaSyC9tcZaM6oOsV94nndnT8IcZUmNRk_DiuM`} className="locationinlocation" /> */}


                        <GoogleMap
                            className="RealGoogleMap"
                            // onLoad={handleLoad}
                            onDragEnd={handleCenter}
                            center={position}
                            onClick={() => setActiveMarker(null)}
                            mapContainerStyle={customizeMap}
                            zoom={initZoom}
                            initialCenter={
                                centralPosition
                            }

                        >


                            {
                                filterTrue ?
                                    markers.filter(store => store.fullName.toLowerCase().includes(filterUserDetails.toLowerCase())).map((store) => (

                                        <Marker
                                        key={store.id}
                                        position={{
                                          lat: store.lat,
                                          lng: store.lng
                                        }}
                                        icon={{
                                          url: store.status === 'AWAY' ? 'https://wfms-aces-object.oss-me-east-1.aliyuncs.com/ui/away.svg' : 'https://wfms-aces-object.oss-me-east-1.aliyuncs.com/ui/marker-green.svg',
                                          fillColor: 'FFFFFF',
                                          scale: 7,
                                        }}
                                        label={{
                                          text: store.name,
                                          color: "white",
                                          fontWeight: "bold",
                                          fontSize: "16px"
                                        }}
                                        title={store.fullName}
                                        onClick={() => handleActiveMarker(store.id)}
                                      >
                                        {activeMarker === store.id && (
                                          <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                            <div className='UserInfo'>
                                              {store?.imageUrl === null ? (
                                                <div>
                                                  <img className="employeeImageinlocationpopup" src={technician} />
                                                </div>
                                              ) : (
                                                <div>
                                                  <img className="employeeImageinlocationpopup" src={store?.imageUrl} />
                                                </div>
                                              )}
                                              <p className="">
                                                {store?.status === "AWAY" ? (
                                                  <div className='Away'>{store?.status}</div>
                                                ) : (
                                                  <div className='Online'>{store?.status}</div>
                                                )}
                                              </p>
                                              <p className="userNameinLocation">{store?.fullName}</p>
                                              <p className="">{store?.skill}</p>
                                              <p className="mobilenumberinlocationpopup">{store?.phone}</p>
                                            </div>
                                            <div className="AvailabilityStatus">
                                              {markers
                                                .filter((user) => user.lat === store.lat && user.lng === store.lng)
                                                .map((user) => (
                                                  <div key={user.id}>
                                                    {user.name}: {user.status}
                                                  </div>
                                                ))}
                                            </div>
                                          </InfoWindow>
                                        )}
                                      </Marker>
                                      

                                    ))
                                    :
                                    markers.map((store) => (
                                        <Marker
                                            key={store.id}
                                            position={{
                                                lat: store.lat,
                                                lng: store.lng
                                            }}
                                            icon={{
                                                url: store.status === 'AWAY' ? 'https://wfms-aces-object.oss-me-east-1.aliyuncs.com/ui/away.svg' : 'https://wfms-aces-object.oss-me-east-1.aliyuncs.com/ui/marker-green.svg',
                                                fillColor: 'FFFFFF',
                                                scale: 7,
                                            }}
                                            label={{
                                                text: store.name,
                                                color: "white",
                                                fontWeight: "bold",
                                                fontSize: "16px"
                                            }}
                                            //  label={store.name}
                                            title={store.fullName}
                                            onClick={() => handleActiveMarker(store.id)}
                                        >
                                            {activeMarker === store.id ? (
                                                <InfoWindow onCloseClick={() => setActiveMarker(null)}>
                                                    <div className='UserInfo'>

                                                        {store?.imageUrl === null ? <div>
                                                            <img className="employeeImageinlocationpopup"
                                                                src={technician} /> </div> : <div>
                                                            <img className="employeeImageinlocationpopup"
                                                                src={store?.imageUrl} /> </div>}

                                                        <p className=""> {
                                                            store?.status === "AWAY" ? <div className='Away'>{store?.status}</div> : <div className='Online'> {store?.status}</div>
                                                        }</p>
                                                        <p className="userNameinLocation">
                                                            {
                                                                store?.fullName
                                                            }</p>

                                                        <p className="">
                                                            {
                                                                store?.skill
                                                            }
                                                        </p>
                                                        <p className="mobilenumberinlocationpopup">
                                                            {
                                                                store?.phone
                                                            }</p>
                                                    </div>
                                                </InfoWindow>
                                            ) : null}
                                        </Marker>

                                    ))
                            }
                        </GoogleMap>
                    </div>
                </div>
            </div>) : null
    );
}

export default Location;
