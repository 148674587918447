import React, { Component, Children } from "react";
import PropTypes from "prop-types";
import _ from "underscore";

export default class Tabs extends Component {
    constructor(props) {
        super(props);
        this.state = {};

    }

    isSelected(tab) {

        if (this.state.value === undefined)
            return tab.props.value === "Notification";
        else
            return tab.props.value === this.state.value;
    }

    selectTab(e, value) {
        this.setState({ value });
        this.props.onChange(e, value);

    }

    getHeader(tabs) {
        return tabs.map((tab, i) => {
            const style = this.isSelected(tab)
                ? activeTabHeaderStyle
                : tabHeaderStyle;

            return (
                <span
                    key={tab.props.value}
                    onClick={e => this.selectTab(e, tab.props.value)}


                    style={i === 0 ? style : Object.assign({}, style, sideHeaderStyle)}
                >
                    {tab.props.header}
                </span>
            );
        });
    }

    render() {
        const { children } = this.props;
        const tabs = Children.toArray(children);

        return (
            <div>
                <div style={tabsHeaderStyle}>{this.getHeader(tabs)}</div>
                <div>
                    <div style={tabsContentStyle}>
                        {_.find(tabs, tab => this.isSelected(tab))}
                    </div>
                </div>
            </div>

        );
    }
}

Tabs.defaultProps = {
    onChange: _.noop
};

Tabs.propTypes = {
    children: PropTypes.node,
    onChange: PropTypes.func
};

// Style
const borderStyle = "1px solid rgba(0,0,0,0.15)";
// const tabsStyle = {
//   boxShadow: "0 10px 40px -20px rgba(0,0,0,0.35)",
//   border: borderStyle,
//   borderRadius: "8px",
//   width:'100%',
//   height:'100%',
//   overFlow:'scroll',

// };

const tabsHeaderStyle = {
    display: "flex",
    justifyContent: "space-between",
    backgroundColor: "transparent",

    overflow: "hidden",
    width: "15%",
    position: 'relative',
    top: sessionStorage.getItem('isHr')?'-41px':'-53px'
};

const tabHeaderStyle = {
    flex: "1",
    padding: "10px",
    backgroundColor: '#fff',
    marginRight: '10px',
    textAlign: "center",
    cursor: "pointer",
    borderRadius: "8px 8px 0px 0",
    borderBottom: '2px solid white'
};

const activeTabHeaderStyle = Object.assign({}, tabHeaderStyle, {
    backgroundColor: "#23D6A0",
    borderBottom: "none",
    cursor: "auto"
});

const sideHeaderStyle = { borderLeft: borderStyle };

const tabsContentStyle = {
    padding: "10px",
    borderTop: "none",

    borderRadius: "0 0 8px 8px"
};
