import React, { useEffect, useRef, useState } from 'react';
import Highcharts from 'highcharts';
import axios from 'axios';
import *  as constants from "../Utils/URLConstants";

function App() {
    const refContainer = useRef(null);
    const [dataSource, setDataSource] = useState([]);
    const [attendance, setAttendance] = useState({});
    const [type, setType] = useState('');

    const [reload, isReload] = useState(false);

    const handleType = (e) => {

        setType(e.target.value);
    }
    const fetchattendance = () => {
        axios.get(constants.ATTENDANCE_REPORT, {
            params: { type: type },

            headers: {
                "Authorization": sessionStorage.getItem('token'),
                'Content-Type': 'application/json'
            }
        }).then((result) => {

            setAttendance({ ...result.data });
            isReload(true);


        }).catch((error) => {
            console.log("failed with error ", error);
        })



    }
    useEffect(() => {
        fetchattendance();

    }, [reload, type])

    useEffect(() => {
        const chart = Highcharts.chart(refContainer.current, {
            chart: {
                type: 'column',
                height: '80%'
            },  // subtitle of the chart
            title: {
                text: null
            },
            xAxis: {
                categories: attendance.names,
                crosshair: true
            },
            yAxis: {
                min: 0,
            },
            tooltip: {
                headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                    '<td style="padding:0"><b>{point.y}</b></td></tr>',
                footerFormat: '</table>',
                shared: true,
                useHTML: true
            },
            credits: {
                enabled: false
            },
            series: dataSource // set of the data
        });

        if (dataSource.length > 0) {
            chart.hideLoading();
        }
        else {
            chart.showLoading();
        }

    }, [dataSource, attendance, type]);
    useEffect(() => {
        setDataSource([{
            name: 'Present',
            color: '#02BC77',
            data: attendance.totalWorking
        }, {
            name: 'Absence',
            color: "#FF2366",
            data: attendance.totalAbsent
        }]);

    }, [reload, type, attendance]);

    return (
        <div>
            <div className="firstdivtopcontent">
                <p id="attendancenameindashboard">Attendance</p>
                <p>
                    <select onChange={handleType} onLoad={handleType}>
                        <option value="D">Day</option>
                        <option value="M">Month</option>
                        <option value="Y">Year</option>
                    </select>
                </p>
            </div>
            {/* <hr className="hrtag" /> */}
            <div ref={refContainer} />

        </div>
    );
}

export default App;
