import React, { Component, useState, useEffect } from "react";
import axios from "axios";
import arrow from "../images/arrow-down.svg";
import technician from "../images/electrician.png";
import Row from "react-bootstrap/Row";
import sms from "../images/smsImage.svg";
import Roaster from "../images/roaster.svg";
import message from "../images/message.svg";
import notify from "../images/notify.svg";
import deleteImg from "../images/delete.svg";
import { Modal, Button, Form, Tabs, ModalBody } from "react-bootstrap";
import AddEmployee from "./AddEmployee";
import EditEmployee from "./EditEmployee";
import Pagination from "react-js-pagination";
import LoadingOverlay from 'react-loading-overlay';
import *  as constants from "../Utils/URLConstants";
import ReactSnackBar from "react-js-snackbar";
import setting from '../images/setting.svg';
import Addbtn from '../images/Addbtn.svg';
import EditBtn from '../images/EditBtn.svg';
import { useRef } from "react";

function Employees() {






  const [isOpen, setOpen] = useState(false);
  const [employeeData, setemployeeData] = useState({});
  const [isViewIcon, setIsViewIcon] = useState(false);
  const [tempData, settempData] = useState({});
  const [subject, setsubject] = useState("");
  const [isConfirm, setIsConfirm] = useState(false);

  const [isaddEmployee, setIsAddEmployee] = useState(false);
  const [body, setbody] = useState("");
  const [imgId, setImgId] = useState(0);
  const [deleteUsername, setDeleteUsername] = useState("");
  const [currUserName, setCurrUserName] = useState("");
  const [imgUrl, setImgUrl] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecordsInEmp, setTotalRecordsInEmp] = useState(0);
  const [isLoading, setIsLoading] = useState(0);
  const [search, setSearch] = useState("");
  const [idSearch, setIdSearch] = useState("");
  const [idSearchApiFlag, setIdSearchApiFlag] = useState(false);
  const [nameSearchApiFlag, setNameSearchApiFlag] = useState(false);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [showSnackBar, setShowSnackBar] = useState(false);
  const [emoji, setEmoji] = useState("");
  const [snackmessage, setSnackMessage] = useState("");
  // const maxFileSize = 8388608;
  const maxFileSize = 8;
  const isHr = sessionStorage.getItem("isHr");
  const [validation, setValidation] = useState(0);
  const [roasterType, setRoasterType] = useState("OD");
  const [metaDataMC, setMetaDataMC] = useState([]);
  const [metaDataZone, setMetaDataZone] = useState([]);
  const [editFlag, setEditFlag] = useState(true);
  const [editFieldValue, setEditFieldValue] = useState(0);
  const [getSchedules, setGetSchedules] = useState([]);
  const [isInvalidFlag, setInvalidFlag] = useState(false);
  const [isFieldTouched, setIsFieldTouched] = useState(false);
  const [addFieldsCount, setAddFieldsCount] = useState(0);
  const [currSelectedZone, setcurrSelectedZone] = useState("");
  const [filterAbsent, setFilterAbsent] = useState(false);
  const [isInvalidUpdateFlag, setInvalidUpdateFlag] = useState(false);
  const [idSearchEmp, setIdSearchEmp] = useState(null);
  const [nameSearchEmp, setNameSearchEmp] = useState(false);
  const [keys, setKeys] = useState("");
  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);
  const [filterSearch, setFilterSearch] = useState(false);
  //enter key func.
  const idSearchRef = useRef(null);
  const searchRef = useRef(null);
  //end of enter
  const [formFields, setFormFields] = useState([
    {
      rosterType: '',
      rosterStartDate: '',
      rosterEndDate: '',
      rosterStartTime: '',
      rosterEndTime: '',
      zone: '',
      maintenanceCenter: '',

    }

  ]);


  const handleFormChange = (event, index) => {
    if (event.target.name === 'rosterType') {
      setRoasterType(event.target.value)
    }
    let data = [...formFields];
    if (event.target.name === 'zone') {
      data[index][event.target.name] = event.target.value;
      data[index]["maintenanceCenter"] = "";
    }
    else {
      data[index][event.target.name] = event.target.value;
    }
    setFormFields(data);
  }

  const addFields = () => {
    let object = {
      rosterType: '',
      rosterStartDate: '',
      rosterEndDate: '',
      rosterStartTime: '',
      rosterEndTime: '',
      zone: '',
      maintenanceCenter: '',

    }
    setFormFields([...formFields, object]);
  }

  // const removeFields = (index) => {
  //     let data = [...formFields];
  //     data.splice(index,1);
  //     setFormFields(data);
  // }


  //On Call On Duty


  const setCancelStatus = (index) => {
    setIsAddEmployee(index);
    setIsViewIcon(index);
  };

  const checkEmployeeCreated = (status) => {
    if (status === "created") {
      setShowSnackBar(true);
      setSnackMessage("Employee Created/Updated Successfully!");

      snackEnable();
      setEmoji("✔️");
    }
  }

  const handleSubFormChange = (event, val) => {

    let newState = getSchedules.map(value => {
      if (value.scheduleId === val.scheduleId && event.target.value !== "") {

        if (event.target.name === 'zone') {
          setcurrSelectedZone(event.target.value)
          value.maintenanceCenter = "";
        }
        return { ...value, [event.target.name]: event.target.value }

      }
      return value;
    });
    setGetSchedules(newState);
    setIsFieldTouched(true);
  }


  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);

    setIsLoading(isLoading + 1);
  };

  const isReloadStatus = () => {
    setIsLoading(isLoading + 1);
  };
  const closeModal = () => {
    setOpen(false);
    setEditFieldValue(0);
    setAddFieldsCount(0);
    setValidation(0);
    setIsConfirm(false);
    setIsAddEmployee(false);
    setIsViewIcon(false);
    setCurrUserName("");
    setImgUrl("");
    setsubject("");
    setbody("");
    setEditFlag(true);
    setRoasterType("OD");
    setInvalidFlag(false);
    setInvalidUpdateFlag(false);
    setFormFields([
      {
        rosterType: '',
        rosterStartDate: '',
        rosterEndDate: '',
        rosterStartTime: '',
        rosterEndTime: '',
        zone: '',
        maintenanceCenter: '',

      }

    ]);
  };
  const openModal = (id, edata) => {
    setImgId(id);
    settempData(edata);
    setOpen(true);
    if (id === 4) {
      //Get Call
      axios.get(constants.GET_OD_OC, {
        params: { name: edata.userName },
        headers: {
          "Authorization": sessionStorage.getItem('token'),
          'Content-Type': 'application/json'
        }
      }).then((result) => {

        setGetSchedules(result.data.rosterDetails);

      }).catch((error) => {
        console.log("failed with error ", error);
      })
    }

  };

  const confirmModal = (userName) => {
    setIsConfirm(true);
    setDeleteUsername(userName);
  };

  const viewUserDetails = (userName) => {
    setIsViewIcon(true);

    setCurrUserName(userName);
  };

  const textFieldTitle = () => {
    if (imgId == 1) {
      return "Email";
    } else if (imgId == 2) {
      return "Notification";
    } else if (imgId == 3) {
      return "Message";
    }
    else {
      return "Roster";
    }
  };

  const textFieldValue = (edate) => {

    if (imgId == 1) {
      return edate.email;
    } else if (imgId == 2) {
      return edate.fullName;
    } else if (imgId == 3) {
      return edate.mobile;
    }
  };

  const sendmail = () => {


    let dataArray = new FormData();

    dataArray.append("message", body);
    dataArray.append("file", file);
    if (validation <= 8) {
      setValidation(0);
      if (imgId === 1) {
        setLoadingEmail(true);
        dataArray.append("subject", subject);
        dataArray.append("to", tempData.email);

        axios
          .post(
            constants.SEND_EMAIL,
            dataArray,
            {
              headers: {
                Authorization: sessionStorage.getItem("token"),
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            setOpen(false);
            setShowSnackBar(true);
            setSnackMessage("Email Sent Successfully!");
            setLoadingEmail(false);
            snackEnable();
            setEmoji("✔️");
            setsubject("");
            setbody("");

            dataArray = "";
          })
          .catch((error) => {
            setOpen(false);
            setShowSnackBar(true);
            setSnackMessage("Something Went Wrong!");
            setLoadingEmail(false);
            snackEnable();
            setEmoji("❌");
            setsubject("");
            setbody("");
            dataArray = "";
          });
      } else if (imgId === 2) {
        setLoadingEmail(true);
        dataArray.append("title", subject);
        dataArray.append("userName", tempData.userName);
        axios
          .post(
            constants.SEND_NOTIFICATON,
            dataArray,
            {
              headers: {
                Authorization: sessionStorage.getItem("token"),
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then((res) => {
            setLoading(false);
            setOpen(false);
            setLoadingEmail(false);
            setShowSnackBar(true);
            setSnackMessage("Notification Sent Successfully!");
            snackEnable();
            setEmoji("✔️");
            setsubject("");
            dataArray = "";
            setbody("");
          })
          .catch((error) => {
            console.error("error", error);
            setLoading(false);
            setOpen(false);
            setLoadingEmail(false);
            setsubject("");
            dataArray = "";
            setbody("");
            setShowSnackBar(true);
            setSnackMessage("Something Went Wrong!");
            snackEnable();
            setEmoji("❌");
          });
      }
      else if (imgId === 4) {
        let allowUpdate = 0;
        const newState = formFields.map((obj, index) => {

          if (obj.rosterStartDate === "" && obj.rosterEndDate === "" && obj.zone === "" && obj.rosterStartTime === ""
            && obj.rosterEndTime === "" && obj.maintenanceCenter === "") {
            setInvalidFlag(false)
          }
          else if (obj.rosterStartDate === "" || obj.rosterEndDate === "" || obj.zone === "" || obj.rosterStartTime === ""
            || obj.rosterEndTime === "" || (obj.maintenanceCenter === "" && roasterType === "OD")) {
            setInvalidFlag(true);
            allowUpdate = 1;
          }
          else {
            return { ...obj, rosterType: roasterType };
          }
        });


        if (getSchedules?.length > 0) {
          getSchedules.map(val => {
            if ((val.maintenanceCenter === "" || val.maintenanceCenter === null) && val.rosterType === "OD") {
              setInvalidUpdateFlag(true)
              allowUpdate = 1;
            }

          })
        }
        var ODOCPost = [...newState, ...getSchedules];

        if (allowUpdate === 0 && (!newState.includes(undefined) || (getSchedules?.length > 0 && isFieldTouched))) {

          axios
            .post(
              constants.OD_OC_UPDATE,
              {
                userName: tempData.userName,
                rosterDetails: ODOCPost
              },
              {
                headers: {
                  Authorization: sessionStorage.getItem("token"),
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {

              setOpen(false);
              setEditFieldValue(0);
              dataArray = "";
              let object = {
                rosterType: '',
                rosterStartDate: '',
                rosterEndDate: '',
                rosterStartTime: '',
                rosterEndTime: '',
                zone: '',
                maintenanceCenter: '',

              }
              setAddFieldsCount(0);
              setFormFields([object]);
              setRoasterType("OD");
              setShowSnackBar(true);
              setSnackMessage("Schedule create/update Successfully!");
              snackEnable();
              setEmoji("✔️");
              setInvalidFlag(false)
              setInvalidUpdateFlag(false);
            })
            .catch((error) => {
              dataArray = "";
              setAddFieldsCount(0);
              setShowSnackBar(true);
              setSnackMessage("Something Went Wrong!");
              snackEnable();
              setEmoji("❌");
            });
        }
        else {
          setShowSnackBar(true);
          setSnackMessage("Please Provide Mandatory Values!");
          snackEnable();
          setEmoji("❌");
        }
      }
      else {
        setLoading(false);
        setOpen(false);
        setsubject("");
        setbody("");
        setShowSnackBar(true);
        setSnackMessage("Development in progress!");
        snackEnable();
        setEmoji("❌");
      }
    }
    else {
      setShowSnackBar(true);
      setSnackMessage("File Size Is Large");
      snackEnable();
      setEmoji("❌");

    }

  };



  //Enter key logic for search
  function handleKeyPress(event) {

    if (event.key === 'Enter') {
      // Perform search functionality here
      if (idSearch !== "" || search !== "") {
        console.log('ok enter');
        handleSearchEvent();
      }

      //event.preventDefault();
    }
  }
  const handleSearchEvent = () => {
    if (search !== "" || idSearch !== "") {
      console.log("Coming here")
      fetchEmplyeeDetails(true);
    }
    else{
      fetchEmplyeeDetails(false);
    }
  };

  const addEmployee = () => setIsAddEmployee(true);

  const userData = async () => {
    if (sessionStorage.getItem("absent")) {
      sessionStorage.removeItem("absent");
      setFilterAbsent(true);
      return true;
    }
    else {
      return filterAbsent;
    }
  };

  const fourthstyle = {
    position: imgId === 4 ? 'absolute' : '',
    left: imgId === 4 ? '-60%' : '',
    width: imgId === 4 ? '245%' : ''
  }
  const fetchEmplyeeDetails = async (isSearch=false) => {
    setLoading(true);
    const absentFlag = await userData();
    if (idSearchApiFlag && isSearch) {
      axios
        .get(constants.GET_EMPLOYEE, {
          params: {
            isAbsent: absentFlag,
            userName: keys,
          },

          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          setemployeeData(result.data);
          setLoading(false);
          setIdSearch("");
          setTotalPages(result?.data?.pageableResponse.totalPages);
          setTotalRecordsInEmp(result?.data?.pageableResponse.totalElements);
          setKeys("");
          setIdSearchApiFlag(false);
        })
        .catch((error) => {
          console.log("failed with error ", error);
        });


    } else if (nameSearchApiFlag && isSearch) {
      axios
        .get(constants.GET_EMPLOYEE, {
          params: {

            isAbsent: absentFlag,
            userName: keys,
          },

          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          setemployeeData(result.data);
          setLoading(false);
          setTotalPages(result?.data?.pageableResponse.totalPages);
          setTotalRecordsInEmp(result?.data?.pageableResponse.totalElements);
          setNameSearchApiFlag(false);
          setKeys("");
          setSearch("");
          setNameSearchEmp(false);
        })
        .catch((error) => {
          console.log("failed with error ", error);
        });
    } else {
      setIdSearch("");
      setSearch("");
      axios
        .get(constants.GET_EMPLOYEE, {
          params: {

            isAbsent: absentFlag,
          },

          headers: {
            Authorization: sessionStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          console.log("result.data", result.data)
          setemployeeData(result.data);
          setLoading(false);

          setTotalPages(result?.data?.pageableResponse.totalPages);
          setTotalRecordsInEmp(result?.data?.pageableResponse.totalElements);
        })
        .catch((error) => {
          console.log("failed with error ", error);
        });
    }
  };




  const searchEmp = () => {
    axios
      .get(constants.GET_EMPLOYEE_USERNAME_SEARCH, {
        params: {
          userName: idSearch
        },

        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        let values = Object.entries(result.data);
        setIdSearchEmp(values);

      })
      .catch((error) => {
        console.log("failed with error ", error);
      });

  }

  const searchEmp2 = () => {

    axios
      .get(constants.GET_EMPLOYEE_SEARCH, {
        params: {
          name: search
        },

        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        let values = Object.entries(result.data);
        // console.log(result.data);console.log(values);
        setIdSearchEmp(values);
      })
      .catch((error) => {
        console.log("failed with error ", error);
      });


  }
  useEffect(() => {
    searchEmp();
  }, [count]);

  useEffect(() => {
    searchEmp2();
  }, [count2]);

  const snackEnable = () => {
    setTimeout(() => {
      setShowSnackBar(false);
    }, 4000);
  }

  const fileSizeMsg = {
    position: 'relative',
    top: '20px',
    color: validation <= 8 ? '' : 'red'
  }
  const deleteEmployeeDetails =() => {
    axios
      .delete(constants.DELETE_EMPLOYEE, {
        params: { userName: deleteUsername },

        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        setIsConfirm(false);
        setShowSnackBar(true);
        setSnackMessage(deleteUsername + " Deleted Successfully!");
        snackEnable();
        setEmoji("✔️");
        setNameSearchApiFlag(false);
        setIdSearchApiFlag(false);
        setKeys("");
        setSearch("");
        setIdSearch("")
       fetchEmplyeeDetails(false);
      })
      .catch((error) => {
        console.log("failed with error ", error);
        //changed
        setIsConfirm(false);
        setIsLoading(isLoading + 1);
        setShowSnackBar(true);
        setSnackMessage("Something Went Wrong!");
        snackEnable();
        setEmoji("❌");
      });
  };

  const loadMetaData = () => {
    axios
      .get(constants.GET_META_DATA, {
        headers: {
          Authorization: sessionStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((result) => {

        //setMetaDataMC(Object.entries(result.data.MC));
        setMetaDataZone(Object.entries(result.data));
      })
      .catch((error) => {
        console.log("failed with error ", error);
      });
  }
  useEffect(() => {
    fetchEmplyeeDetails(false);
    loadMetaData();
  }, [isLoading]);



  const setFieldValueData = (val) => {
    setEditFieldValue(val.scheduleId);
    setcurrSelectedZone(val.zone);
  }


  const getUserDetails = (e) => {
    setIdSearch(e.target.value);
    setIdSearchApiFlag(true);
    setSearch("");
    searchEmp();
    if ((e.target.value.length) > 3) {
      setFilterSearch(true);
    }
    else {
      setFilterSearch(false);
    }
    setCount(count + 1);
  }


  const handleSelectName = (e, k) => {
    setIdSearch(e.target.getAttribute('data-index'));
    setKeys(k);
    setFilterSearch(false);
    setNameSearchEmp(false);
    idSearchRef.current.focus();
  }

  const handleSelectForName = (e, k) => {

    setSearch(e.target.getAttribute('data-index'));
    setKeys(k);
    setNameSearchEmp(false);
    setFilterSearch(false);
    searchRef.current.focus();
  }


  const getUserDetailsForName = (e) => {
    setSearch(e.target.value);
    searchEmp2();
    setNameSearchApiFlag(true);
    setFilterSearch(false);
    setIdSearch("");
    if ((e.target.value.length) > 2) {
      setNameSearchEmp(true);
    }
    else {
      setNameSearchEmp(false);
    }
    setCount2(count2 + 1);
  }
  const fetchDataFromServer = (edata, index) => {
    return (
      edata !== null ?


        <div class="col-xl-3 col-md-12 mb-2 justify-content-center rounded-2">
          <div class="card">
            <div class="card-body px-2 pt-2 pb-1">
              <div class="d-flex justify-content-between p-md-1">
                <div class="d-flex flex-row">
                  <div class="">



                    {edata.imageUrl === null ? (
                      <div>
                        <img
                          className="employeeImage  rounded-circle mb-2"
                          style={{ width: '60px', height: '46px' }}
                          src={technician}
                          onClick={(event) => {
                            if (!event.defaultPrevented) {
                              viewUserDetails(edata.userName);
                              setImgUrl(edata.imageUrl)
                            }
                          }}
                        />


                      </div>
                    ) : (


                      <img
                        className="employeeImage rounded-circle mb-2"
                        src={edata.imageUrl}
                        style={{ width: '40px', height: '40px', marginLeft: '5px' }}
                        onClick={(event) => {

                          if (!event.defaultPrevented) {
                            viewUserDetails(edata.userName);
                            setImgUrl(edata.imageUrl)
                          }
                        }}
                      />
                    )}
                  </div>
                  <div >
                    <p className="mb-0">{edata.name}</p>
                    <p class="mb-0 small jobTitleText">{edata.jobTitle}</p>
                  </div>
                </div>
                {
                  isHr === 'true' ?
                    <div class="align-self-start">
                      <img src={deleteImg} alt="delete" onClick={(event) => {
                        if (!event.defaultPrevented) {
                          confirmModal(edata.userName);
                        }
                      }} id="deleteImage1" />
                    </div> : ''
                }


              </div>
              <div className="buttons xxl-mt-2" style={{ marginLeft: '-5%', marginBottom: '0px' }}>
                <button className="empBtn viewDetailsBtn">
                  <img
                    id="1"
                    src={sms}
                    className="image"
                    onClick={(event) => {
                      if (!event.defaultPrevented) {
                        openModal(1, edata);
                      }
                    }}
                  />
                </button>
                <button className="empBtn delBtn">
                  <img
                    id="2"
                    src={notify}
                    className="image"
                    onClick={(event) => {
                      if (!event.defaultPrevented) {
                        openModal(2, edata);
                      }
                    }}
                  />
                </button>
                <button className="empBtn delBtn" hidden={!(sessionStorage.getItem("loggedInUserName") == edata.reportingManager || isHr == 'true')}>
                  <img
                    id="4"
                    src={Roaster}
                    className="image"
                    onClick={(event) => {
                      if (!event.defaultPrevented) {
                        openModal(4, edata)
                      }
                    }}
                  />
                </button>
                <button className="empBtn delBtn">
                  <img

                    src={setting}
                    className="deleteImage"
                    onClick={(event) => {
                      if (!event.defaultPrevented) {
                        viewUserDetails(edata.userName)
                      }
                    }}
                  />
                </button>
              </div>
              <div>
                <Modal show={isOpen} onHide={closeModal}>
                  <div className="modal-content" style={fourthstyle}>
                    <LoadingOverlay
                      active={loadingEmail}
                      spinner
                      text={imgId === 1 ? 'Sending Email...' : imgId === 2 ? 'Sending Notification...' : 'Sending Message In Progress...'}

                    >
                      <Modal.Header closeButton className="headerroaster" style={{ borderBottom: imgId === 4 ? '1px solid black' : '0' }}>
                        <Modal.Title>{textFieldTitle()}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Form.Group>
                          {
                            imgId !== 4 ?
                              <Form.Control
                                type="text"

                                value={textFieldValue(tempData)}
                                className="formControlinEmpPopup"
                                placeholder="Yamen"

                              /> : ''
                          }


                          {imgId !== 3 && imgId !== 4 ? (
                            <Form.Control
                              type="text"
                              onChange={(e) => setsubject(e.target.value)}
                              value={subject}
                              className="formControlinEmpPopup"
                              placeholder="Enter Subject"
                            />
                          ) : (
                            ""
                          )}
                          {imgId !== 4 ?
                            <Form.Control
                              as="textarea"
                              rows="3"
                              onChange={(e) => setbody(e.target.value)}
                              value={body}
                              style={{ marginTop: '15px' }}
                              placeholder="Enter Text"
                            /> : ''
                          }

                          {
                            imgId === 1 || imgId === 2 ?
                              <Form.Control
                                type="file"

                                // rows="3"
                                onChange={(e) => {


                                  if (((e.target.files[0].size / 1024) / 1024) <= maxFileSize) {
                                    setValidation(3);
                                    setFile(e.target.files[0])
                                  }
                                  else {
                                    setFile("");
                                    setValidation(9);
                                  }

                                }}
                                // value={body}
                                className="fileInEmpPopup"
                              />
                              :
                              ""

                          }
                          {
                            imgId === 1 || imgId === 2 ? <p style={fileSizeMsg}>(Max File SIze: 8MB)</p> : ""
                          }
                          {
                            imgId === 4 ? <div className="roaster">

                              <Form >
                                <div className="oncallOnduty">
                                  <div className="oncallOnDutySub">
                                    <Form.Check
                                      className="gender"
                                      type="radio"
                                      name="rosterType"
                                      value={"OD"}
                                      label={"On Duty"}
                                      checked={roasterType === "OD"}
                                      onChange={(event) => handleFormChange(event, index)}
                                    />
                                    <Form.Check
                                      className="gender"
                                      type="radio"
                                      value={"OC"}
                                      name="rosterType"
                                      label={"On Call"}
                                      checked={roasterType === "OC"}
                                      onChange={(event) => handleFormChange(event, index)}
                                    />
                                  </div>
                                </div>
                                {
                                  formFields.map((form, index) => {
                                    return (
                                      <>


                                        <div className="addShift">
                                          <Form.Label>Add Shift</Form.Label>
                                          <p className="ondutyDate"><b className="boldInOnDuty">Start Date <span style={{ color: 'red' }}>*</span>:</b>  <Form.Control min={new Date().toISOString().slice(0, 10)} isInvalid={isInvalidFlag && form.rosterStartDate === ""} className="formControlChange" type="date" name="rosterStartDate" onChange={(event) => handleFormChange(event, index)} value={form.rosterStartDate} /></p>
                                          <p className="ondutyDate"><b className="boldInOnDuty">End Date <span style={{ color: 'red' }}>*</span>:</b><Form.Control min={new Date().toISOString().slice(0, 10)} type="date" isInvalid={isInvalidFlag && form.rosterEndDate === ""} className="formControlChange" name="rosterEndDate" onChange={(event) => handleFormChange(event, index)} value={form.rosterEndDate} /></p>
                                          <p className="ondutyDate"><b className="boldInOnDuty">Zone <span style={{ color: 'red' }}>*</span>:</b> <Form.Select isInvalid={isInvalidFlag && form.zone === ""} value={form.zone} className="dateinAddShift selectInAddShift"
                                            onChange={(event) => handleFormChange(event, index)}
                                            name="zone" >
                                            <option style={{ display: 'none' }} value="">Zone</option>
                                            {
                                              metaDataZone?.map(([key, val]) => (

                                                <option value={key}>{key}</option>
                                              ))
                                            }

                                          </Form.Select></p>
                                          {
                                            addFieldsCount === index ? <Form.Label onClick={(e) => {
                                              e.preventDefault();
                                              setAddFieldsCount(addFieldsCount + 1)
                                              addFields();
                                            }}><img src={Addbtn} /></Form.Label> : <Form.Label></Form.Label>
                                          }


                                        </div>
                                        <div className="currentShift">
                                          <Form.Label></Form.Label>
                                          <p className="ondutyDate"><b className="boldInOnDuty">Start Time <span style={{ color: 'red' }}>*</span>:</b><Form.Control required isInvalid={isInvalidFlag && form.rosterStartTime === ""} type="time" className="formControlChange" onChange={(event) => handleFormChange(event, index)} name="rosterStartTime" value={form.rosterStartTime} /></p>
                                          <p className="ondutyDate"><b className="boldInOnDuty">End Time <span style={{ color: 'red' }}>*</span>:</b> <Form.Control required isInvalid={isInvalidFlag && form.rosterEndTime === ""} type="time" className="formControlChange" onChange={(event) => handleFormChange(event, index)} name="rosterEndTime" value={form.rosterEndTime} /></p>
                                          <p className="ondutyDate"><b className="boldInOnDuty">Area <span style={{ display: roasterType === 'OD' ? '' : 'none', color: 'red' }}>*</span>:</b> <Form.Select required isInvalid={isInvalidFlag && roasterType === 'OD' && form.maintenanceCenter === ""} value={form.maintenanceCenter}
                                            onChange={(event) => handleFormChange(event, index)} className="dateinAddShift selectInAddShift"
                                            name="maintenanceCenter">
                                            <option style={{ display: 'none' }} value="">MC</option>
                                            {
                                              metaDataZone?.map(([key, val]) => (
                                                key === formFields[index].zone ?
                                                  (val.toString().split(",").map((line) => (

                                                    <option value={line}>{line}</option>
                                                  ))) : ""
                                              ))
                                            }


                                          </Form.Select></p>
                                          <Form.Label></Form.Label>
                                        </div>

                                      </>
                                    )
                                  })
                                }
                                <div>
                                  <Form.Label style={{ width: '100%', marginTop: '1%' }}>Current Shift</Form.Label>
                                </div>

                                {
                                  getSchedules.map(val => {

                                    return (

                                      <div className="onDutyShow">
                                        <div className="onDutyLabel">
                                          <Form.Label>{val.rosterType === "OD" ? "On Duty" : "On Call"}</Form.Label>
                                          <p className="ondutyDate"><b className="boldInOnDuty">Start Date:</b> <Form.Control min={new Date().toISOString().slice(0, 10)} className="formControlChange" type="date" name="rosterStartDate" readOnly={editFieldValue === val.scheduleId ? false : true} onChange={(event) => handleSubFormChange(event, val)} value={val.rosterStartDate} /></p>
                                          <p className="ondutyDate"><b className="boldInOnDuty">End Date:</b> <Form.Control min={new Date().toISOString().slice(0, 10)} className="formControlChange" type="date" name="rosterEndDate" readOnly={editFieldValue === val.scheduleId ? false : true} onChange={(event) => handleSubFormChange(event, val)} value={val.rosterEndDate} /></p>
                                          <p className="ondutyDate"><b className="boldInOnDuty">Zone:</b> &nbsp;<Form.Select value={val.zone} readOnly={editFieldValue === val.scheduleId ? false : true}
                                            onChange={(event) => handleSubFormChange(event, val)} className="dateinAddShift selectInAddShift"
                                            name="zone" >
                                            <option style={{ display: 'none' }} value="">{val.zone}</option>
                                            {
                                              editFieldValue === val.scheduleId && metaDataZone?.map(([key, val]) => (

                                                <option value={key}>{key}</option>
                                              ))
                                            }


                                          </Form.Select></p>
                                          <Form.Label onClick={() => setFieldValueData(val)}><img src={EditBtn} /></Form.Label>
                                        </div>
                                        <div className="onDutySecondRow">
                                          <Form.Label></Form.Label>
                                          <p className="ondutyDate"><b className="boldInOnDuty">Start Time:</b> &nbsp; <Form.Control type="time" className="formControlChange" onChange={(event) => handleSubFormChange(event, val)} name="rosterStartTime" value={val.rosterStartTime} readOnly={editFieldValue === val.scheduleId ? false : true} /></p>
                                          <p className="ondutyDate"><b className="boldInOnDuty">End Time:</b> <Form.Control type="time" className="formControlChange" onChange={(event) => handleSubFormChange(event, val)} name="rosterEndTime" value={val.rosterEndTime} readOnly={editFieldValue === val.scheduleId ? false : true} /> </p>
                                          <p className="ondutyDate"><b className="boldInOnDuty">Area:</b> &nbsp; <Form.Select value={val.maintenanceCenter}
                                            onChange={(event) => handleSubFormChange(event, val)} className="dateinAddShift selectInAddShift" readOnly={editFieldValue !== val.scheduleId}
                                            name="maintenanceCenter" required isInvalid={isInvalidUpdateFlag && val.rosterType === 'OD' && (val.maintenanceCenter === null || val.maintenanceCenter === "")}>
                                            <option value="" selected style={{ display: 'none' }}>{val.maintenanceCenter}</option>
                                            {
                                              editFieldValue === val.scheduleId && metaDataZone?.map(([key, val]) => (
                                                key === currSelectedZone ?
                                                  (val.toString().split(",").map((line) => (

                                                    <option value={line}>{line}</option>
                                                  ))) : ""
                                              ))
                                            }


                                          </Form.Select></p>
                                          <Form.Label></Form.Label>
                                        </div>
                                      </div>
                                    )

                                  })

                                }

                              </Form>

                            </div> : ''
                          }

                        </Form.Group>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button className="cancelBtninpopup" onClick={loading ? "" : closeModal}>
                          Cancel
                        </Button>
                        <Button
                          className={
                            imgId === 2 ? "notificationBtninpopup" : "emailBtninpopup"
                          }
                          onClick={sendmail}
                        >

                          {

                            imgId === 1
                              ? "Send Mail"
                              : imgId === 2
                                ? "Send Notification"
                                : imgId === 4 ? "Save"
                                  : "Send"
                          }
                        </Button>
                      </Modal.Footer>
                    </LoadingOverlay>
                  </div>
                </Modal>
              </div>
              <Modal show={isConfirm} onHide={closeModal}>
                <Modal.Header closeButton> </Modal.Header>
                <Modal.Body>
                  Are you sure you want delete <b>Profile</b> ?
                </Modal.Body>
                <Modal.Footer>
                  <div className="displayflexforpopupdelete">
                    <Button
                      variant="secondary"
                      className="yesBtninpopup"
                      onClick={deleteEmployeeDetails}
                    >
                      Yes
                    </Button>
                    <Button
                      variant="secondary"
                      className="noBtninpopup"
                      onClick={closeModal}
                    >
                      No
                    </Button>
                  </div>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>


        : <div><b style={{ position: 'absolute', top: '45vh', left: '60vh', fontSize: '20px', fontWeight: 'bold' }}>No users Found</b></div>
    );
  };
  if (!isaddEmployee && !isViewIcon) {
    return (
      <LoadingOverlay
        active={loading}
        spinner
        text='Loading Employees...'

      >
        <ReactSnackBar Icon={<span>{emoji}</span>} Show={showSnackBar}>
          {snackmessage}
        </ReactSnackBar>
        <div>
          <div id="dashboardlocation">
            <span style={{ color: "#66676c", fontSize: "13px" }}>
              Dashboard &gt; Employee
            </span>


          </div>

          {/* tabs place */}

          <div className="employeeSearch">
            <div className="position-relative">
              <input
                type="text"
                className="empFieldID text-black"
                placeholder="ACES ID"
                value={idSearch}
                onChange={getUserDetails}
                onKeyUp={handleKeyPress}
                ref={idSearchRef}

              />
              {
                filterSearch ?
                  <div className="position-absolute bg-white w-100 border overflowInEmpSearch overflow-auto">

                    {
                      idSearchEmp?.map(([key, val]) => (
                        <p className="hoverList" data-index={key} onClick={event => handleSelectName(event, key)} key={key}>{key}</p>
                      ))
                    }


                  </div> : ''
              }
            </div>
            <div className="position-relative">
              <input
                type="text"
                id=""
                className="empName text-black"
                placeholder="Employee Name"
                value={search}
                onChange={getUserDetailsForName}
                onKeyUp={handleKeyPress}
                ref={searchRef}
              />

              {
                nameSearchEmp ?
                  <div className="position-absolute bg-white w-100 border overflowInEmpSearch overflow-auto z-10">

                    {
                      idSearchEmp?.map(([key, val]) => (
                        <p className="hoverList" data-index={val} onClick={event => handleSelectForName(event, key)} key={key}>{val}</p>
                      ))
                    }


                  </div> : ''
              }


            </div>

            <div>
              <input
                type="button"
                id=""
                className="empSearchBtn"
                defaultValue="Search"
                onClick={handleSearchEvent}
              />
              {isHr === 'true' ? <input
                type="button"
                id=""
                className="addEmployeeBtn"
                defaultValue="+  Add Employee"
                onClick={addEmployee}
              /> : ""
              }
            </div>
          </div>
          <div className="firstGridHeight">

            <div className="container-fluid position-relative">
              <div className=" w-100">


                <div className=" row" style={{ float: 'none', margin: '1% auto', width: '40%' }}>

                  {

                    employeeData.userDetails?.length === 0 ?
                      <div style={{ position: 'absolute', top: '45vh', left: '60vh', fontSize: '20px', fontWeight: 'bold' }}>No Users Found !!</div> :
                      employeeData.userDetails?.filter((ele) => (ele?.level === 0)).map((edata, index) => (
                        <div class="d-flex justify-content-center p-0">
                          <div class="col-xl-6 col-md-12">
                            <div class="card">
                              <div class="card-body px-2 pt-2 pb-1">
                                <div class="d-flex justify-content-between p-md-1">
                                  <div class="d-flex flex-row">
                                    <div class="">
                                      <img src={technician} alt="img" className="roundrd-circle" style={{ width: '45px', height: '40px' }} />
                                    </div>
                                    <div >
                                      <p className="mb-0">{edata.name}</p>
                                      <p class="mb-0 small jobTitleText">{edata.jobTitle}</p>
                                    </div>
                                  </div>
                                  {
                                    isHr === 'true' ?
                                      <div class="align-self-start">
                                        <img src={deleteImg} alt="delete" onClick={(event) => {
                                          if (!event.defaultPrevented) {
                                            confirmModal(edata.userName);
                                          }
                                        }} id="deleteImage2" />
                                      </div> : ''
                                  }

                                </div>
                                <div className="buttons xxl-mt-2" style={{ marginLeft: '-5%', marginBottom: '0px' }}>
                                  <button className="empBtn viewDetailsBtn">
                                    <img
                                      id="1"
                                      src={sms}
                                      className="image"
                                      onClick={(event) => {
                                        if (!event.defaultPrevented) {
                                          console.log("first clicked", edata)
                                          openModal(1, edata);
                                        }
                                      }}
                                    />
                                  </button>
                                  <button className="empBtn delBtn">
                                    <img
                                      id="2"
                                      src={notify}
                                      className="image"
                                      onClick={(event) => {
                                        if (!event.defaultPrevented) {
                                          openModal(2, edata);
                                        }
                                      }}
                                    />
                                  </button>

                                  <button className="empBtn delBtn">
                                    <img

                                      src={setting}
                                      className="deleteImage"
                                      onClick={(event) => {
                                        if (!event.defaultPrevented) {
                                          viewUserDetails(edata.userName)
                                        }
                                      }}
                                    />
                                  </button>
                                </div>
                                <div>
                                  <Modal show={isOpen} onHide={closeModal}>
                                    <div className="modal-content" style={fourthstyle}>
                                      <LoadingOverlay
                                        active={loadingEmail}
                                        spinner
                                        text={imgId === 1 ? 'Sending Email...' : imgId === 2 ? 'Sending Notification...' : 'Sending Message In Progress...'}

                                      >
                                        <Modal.Header closeButton className="headerroaster" style={{ borderBottom: imgId === 4 ? '1px solid black' : '0' }}>
                                          <Modal.Title>{textFieldTitle()}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                          <Form.Group>
                                            {
                                              imgId !== 4 ?
                                                <Form.Control
                                                  type="text"

                                                  value={textFieldValue(tempData)}
                                                  className="formControlinEmpPopup"
                                                  placeholder="Yamen"

                                                /> : ''
                                            }


                                            {imgId !== 3 && imgId !== 4 ? (
                                              <Form.Control
                                                type="text"
                                                onChange={(e) => setsubject(e.target.value)}
                                                value={subject}
                                                className="formControlinEmpPopup"
                                                placeholder="Enter Subject"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {imgId !== 4 ?
                                              <Form.Control
                                                as="textarea"
                                                rows="3"
                                                onChange={(e) => setbody(e.target.value)}
                                                value={body}
                                                style={{ marginTop: '15px' }}
                                                placeholder="Enter Text"
                                              /> : ''
                                            }

                                            {
                                              imgId === 1 || imgId === 2 ?
                                                <Form.Control
                                                  type="file"

                                                  // rows="3"
                                                  onChange={(e) => {


                                                    if (((e.target.files[0].size / 1024) / 1024) <= maxFileSize) {
                                                      setValidation(3);
                                                      setFile(e.target.files[0])
                                                    }
                                                    else {
                                                      setFile("");
                                                      setValidation(9);
                                                    }

                                                  }}
                                                  // value={body}
                                                  className="fileInEmpPopup"
                                                />
                                                :
                                                ""

                                            }
                                            {
                                              imgId === 1 || imgId === 2 ? <p style={fileSizeMsg}>(Max File SIze: 8MB)</p> : ""
                                            }
                                            {
                                              imgId === 4 ? <div className="roaster">

                                                <Form >
                                                  <div className="oncallOnduty">
                                                    <div className="oncallOnDutySub">
                                                      <Form.Check
                                                        className="gender"
                                                        type="radio"
                                                        name="rosterType"
                                                        value={"OD"}
                                                        label={"On Duty"}
                                                        checked={roasterType === "OD"}
                                                        onChange={(event) => handleFormChange(event, index)}
                                                      />
                                                      <Form.Check
                                                        className="gender"
                                                        type="radio"
                                                        value={"OC"}
                                                        name="rosterType"
                                                        label={"On Call"}
                                                        checked={roasterType === "OC"}
                                                        onChange={(event) => handleFormChange(event, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  {
                                                    formFields.map((form, index) => {
                                                      return (
                                                        <>


                                                          <div className="addShift">
                                                            <Form.Label>Add Shift</Form.Label>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">Start Date <span style={{ color: 'red' }}>*</span>:</b>  <Form.Control min={new Date().toISOString().slice(0, 10)} isInvalid={isInvalidFlag && form.rosterStartDate === ""} className="formControlChange" type="date" name="rosterStartDate" onChange={(event) => handleFormChange(event, index)} value={form.rosterStartDate} /></p>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">End Date <span style={{ color: 'red' }}>*</span>:</b><Form.Control min={new Date().toISOString().slice(0, 10)} type="date" isInvalid={isInvalidFlag && form.rosterEndDate === ""} className="formControlChange" name="rosterEndDate" onChange={(event) => handleFormChange(event, index)} value={form.rosterEndDate} /></p>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">Zone <span style={{ color: 'red' }}>*</span>:</b> <Form.Select isInvalid={isInvalidFlag && form.zone === ""} value={form.zone} className="dateinAddShift selectInAddShift"
                                                              onChange={(event) => handleFormChange(event, index)}
                                                              name="zone" >
                                                              <option style={{ display: 'none' }} value="">Zone</option>
                                                              {
                                                                metaDataZone?.map(([key, val]) => (

                                                                  <option value={key}>{key}</option>
                                                                ))
                                                              }

                                                            </Form.Select></p>
                                                            {
                                                              addFieldsCount === index ? <Form.Label onClick={(e) => {
                                                                e.preventDefault();
                                                                setAddFieldsCount(addFieldsCount + 1)
                                                                addFields();
                                                              }}><img src={Addbtn} /></Form.Label> : <Form.Label></Form.Label>
                                                            }


                                                          </div>
                                                          <div className="currentShift">
                                                            <Form.Label></Form.Label>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">Start Time <span style={{ color: 'red' }}>*</span>:</b><Form.Control required isInvalid={isInvalidFlag && form.rosterStartTime === ""} type="time" className="formControlChange" onChange={(event) => handleFormChange(event, index)} name="rosterStartTime" value={form.rosterStartTime} /></p>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">End Time <span style={{ color: 'red' }}>*</span>:</b> <Form.Control required isInvalid={isInvalidFlag && form.rosterEndTime === ""} type="time" className="formControlChange" onChange={(event) => handleFormChange(event, index)} name="rosterEndTime" value={form.rosterEndTime} /></p>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">Area <span style={{ display: roasterType === 'OD' ? '' : 'none', color: 'red' }}>*</span>:</b> <Form.Select required isInvalid={isInvalidFlag && roasterType === 'OD' && form.maintenanceCenter === ""} value={form.maintenanceCenter}
                                                              onChange={(event) => handleFormChange(event, index)} className="dateinAddShift selectInAddShift"
                                                              name="maintenanceCenter">
                                                              <option style={{ display: 'none' }} value="">MC</option>
                                                              {
                                                                metaDataZone?.map(([key, val]) => (
                                                                  key === formFields[index].zone ?
                                                                    (val.toString().split(",").map((line) => (

                                                                      <option value={line}>{line}</option>
                                                                    ))) : ""
                                                                ))
                                                              }


                                                            </Form.Select></p>
                                                            <Form.Label></Form.Label>
                                                          </div>

                                                        </>
                                                      )
                                                    })
                                                  }
                                                  <div>
                                                    <Form.Label style={{ width: '100%', marginTop: '1%' }}>Current Shift</Form.Label>
                                                  </div>

                                                  {
                                                    getSchedules.map(val => {

                                                      return (

                                                        <div className="onDutyShow">
                                                          <div className="onDutyLabel">
                                                            <Form.Label>{val.rosterType === "OD" ? "On Duty" : "On Call"}</Form.Label>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">Start Date:</b> <Form.Control min={new Date().toISOString().slice(0, 10)} className="formControlChange" type="date" name="rosterStartDate" readOnly={editFieldValue === val.scheduleId ? false : true} onChange={(event) => handleSubFormChange(event, val)} value={val.rosterStartDate} /></p>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">End Date:</b> <Form.Control min={new Date().toISOString().slice(0, 10)} className="formControlChange" type="date" name="rosterEndDate" readOnly={editFieldValue === val.scheduleId ? false : true} onChange={(event) => handleSubFormChange(event, val)} value={val.rosterEndDate} /></p>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">Zone:</b> &nbsp;<Form.Select value={val.zone} readOnly={editFieldValue === val.scheduleId ? false : true}
                                                              onChange={(event) => handleSubFormChange(event, val)} className="dateinAddShift selectInAddShift"
                                                              name="zone" >
                                                              <option style={{ display: 'none' }} value="">{val.zone}</option>
                                                              {
                                                                editFieldValue === val.scheduleId && metaDataZone?.map(([key, val]) => (

                                                                  <option value={key}>{key}</option>
                                                                ))
                                                              }


                                                            </Form.Select></p>
                                                            <Form.Label onClick={() => setFieldValueData(val)}><img src={EditBtn} /></Form.Label>
                                                          </div>
                                                          <div className="onDutySecondRow">
                                                            <Form.Label></Form.Label>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">Start Time:</b> &nbsp; <Form.Control type="time" className="formControlChange" onChange={(event) => handleSubFormChange(event, val)} name="rosterStartTime" value={val.rosterStartTime} readOnly={editFieldValue === val.scheduleId ? false : true} /></p>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">End Time:</b> <Form.Control type="time" className="formControlChange" onChange={(event) => handleSubFormChange(event, val)} name="rosterEndTime" value={val.rosterEndTime} readOnly={editFieldValue === val.scheduleId ? false : true} /> </p>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">Area:</b> &nbsp; <Form.Select value={val.maintenanceCenter}
                                                              onChange={(event) => handleSubFormChange(event, val)} className="dateinAddShift selectInAddShift" readOnly={editFieldValue !== val.scheduleId}
                                                              name="maintenanceCenter" required isInvalid={isInvalidUpdateFlag && val.rosterType === 'OD' && (val.maintenanceCenter === null || val.maintenanceCenter === "")}>
                                                              <option value="" selected style={{ display: 'none' }}>{val.maintenanceCenter}</option>
                                                              {
                                                                editFieldValue === val.scheduleId && metaDataZone?.map(([key, val]) => (
                                                                  key === currSelectedZone ?
                                                                    (val.toString().split(",").map((line) => (

                                                                      <option value={line}>{line}</option>
                                                                    ))) : ""
                                                                ))
                                                              }


                                                            </Form.Select></p>
                                                            <Form.Label></Form.Label>
                                                          </div>
                                                        </div>
                                                      )

                                                    })

                                                  }

                                                </Form>

                                              </div> : ''
                                            }

                                          </Form.Group>
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <Button className="cancelBtninpopup" onClick={loading ? "" : closeModal}>
                                            Cancel
                                          </Button>
                                          <Button
                                            className={
                                              imgId === 2 ? "notificationBtninpopup" : "emailBtninpopup"
                                            }
                                            onClick={sendmail}
                                          >

                                            {

                                              imgId === 1
                                                ? "Send Mail"
                                                : imgId === 2
                                                  ? "Send Notification"
                                                  : imgId === 4 ? "Save"
                                                    : "Send"
                                            }
                                          </Button>
                                        </Modal.Footer>
                                      </LoadingOverlay>
                                    </div>
                                  </Modal>
                                </div>
                                <div>
                                  <Modal show={isConfirm} onHide={closeModal}>
                                    <Modal.Header closeButton> </Modal.Header>
                                    <Modal.Body>
                                      Are you sure you want delete <b>Profile</b> ?
                                    </Modal.Body>
                                    <Modal.Footer>
                                      <div className="displayflexforpopupdelete">
                                        <Button
                                          variant="secondary"
                                          className="yesBtninpopup"
                                          onClick={deleteEmployeeDetails}
                                        >
                                          Yes
                                        </Button>
                                        <Button
                                          variant="secondary"
                                          className="noBtninpopup"
                                          onClick={closeModal}
                                        >
                                          No
                                        </Button>
                                      </div>
                                    </Modal.Footer>
                                  </Modal>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))

                  }

                  {
                    employeeData.userDetails?.some(item => item["level"] === 1) ?
                      <div class="d-flex justify-content-center" style={{ height: ' 50px' }}>
                        <div class="vr"></div>
                      </div> : ''
                  }
                </div>
              </div>


              <div className=" w-100">


                <div className=" row" style={{ float: 'none', margin: '0 auto', width: '40%', marginTop: '-13px' }}>

                  {

                    employeeData.userDetails?.length === 0 ?
                      <div style={{ position: 'absolute', top: '45vh', left: '60vh', fontSize: '20px', fontWeight: 'bold' }}>No Users Found !!</div> :
                      employeeData.userDetails?.filter((ele) => (ele?.level === 1)).map((edata, index) => (
                        <div class="d-flex justify-content-center p-0">
                          <div class="col-xl-6 col-md-12">
                            <div class="card">
                              <div class="card-body px-2 pt-2 pb-1">
                                <div class="d-flex justify-content-between p-md-1">
                                  <div class="d-flex flex-row">
                                    <div class="">
                                      <img src={technician} alt="img" className="roundrd-circle" style={{ width: '45px', height: '40px' }} />
                                    </div>
                                    <div >
                                      <p className="mb-0">{edata.name}</p>
                                      <p class="mb-0 small jobTitleText">{edata.jobTitle}</p>
                                    </div>
                                  </div>
                                  {
                                    isHr === 'true' ?
                                      <div class="align-self-start">
                                        <img src={deleteImg} alt="delete" onClick={(event) => {

                                          if (!event.defaultPrevented) {
                                            confirmModal(edata.userName);
                                          }
                                        }} id="deleteImage3" />
                                      </div> : ''
                                  }

                                </div>
                                <div className="buttons xxl-mt-2" style={{ marginLeft: '-5%', marginBottom: '0px' }}>
                                  <button className="empBtn viewDetailsBtn">
                                    <img
                                      id="1"
                                      src={sms}
                                      className="image"
                                      onClick={(event) => {
                                        if (!event.defaultPrevented) {
                                          openModal(1, edata);
                                        }
                                      }}
                                    />
                                  </button>
                                  <button className="empBtn delBtn">
                                    <img
                                      id="2"
                                      src={notify}
                                      className="image"
                                      onClick={(event) => {
                                        if (!event.defaultPrevented) {
                                          openModal(2, edata);
                                        }
                                      }}
                                    />
                                  </button>

                                  <button className="empBtn delBtn">
                                    <img

                                      src={setting}
                                      className="deleteImage"
                                      onClick={(event) => {
                                        if (!event.defaultPrevented) {
                                          viewUserDetails(edata.userName)
                                        }
                                      }}
                                    />
                                  </button>
                                </div>
                                <div>
                                  <Modal show={isOpen} onHide={closeModal}>
                                    <div className="modal-content" style={fourthstyle}>
                                      <LoadingOverlay
                                        active={loadingEmail}
                                        spinner
                                        text={imgId === 1 ? 'Sending Email...' : imgId === 2 ? 'Sending Notification...' : 'Sending Message In Progress...'}

                                      >
                                        <Modal.Header closeButton className="headerroaster" style={{ borderBottom: imgId === 4 ? '1px solid black' : '0' }}>
                                          <Modal.Title>{textFieldTitle()}</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                          <Form.Group>
                                            {
                                              imgId !== 4 ?
                                                <Form.Control
                                                  type="text"

                                                  value={textFieldValue(tempData)}
                                                  className="formControlinEmpPopup"
                                                  placeholder="Yamen"

                                                /> : ''
                                            }


                                            {imgId !== 3 && imgId !== 4 ? (
                                              <Form.Control
                                                type="text"
                                                onChange={(e) => setsubject(e.target.value)}
                                                value={subject}
                                                className="formControlinEmpPopup"
                                                placeholder="Enter Subject"
                                              />
                                            ) : (
                                              ""
                                            )}
                                            {imgId !== 4 ?
                                              <Form.Control
                                                as="textarea"
                                                rows="3"
                                                onChange={(e) => setbody(e.target.value)}
                                                value={body}
                                                style={{ marginTop: '15px' }}
                                                placeholder="Enter Text"
                                              /> : ''
                                            }

                                            {
                                              imgId === 1 || imgId === 2 ?
                                                <Form.Control
                                                  type="file"

                                                  // rows="3"
                                                  onChange={(e) => {


                                                    if (((e.target.files[0].size / 1024) / 1024) <= maxFileSize) {
                                                      setValidation(3);
                                                      setFile(e.target.files[0])
                                                    }
                                                    else {
                                                      setFile("");
                                                      setValidation(9);
                                                    }

                                                  }}
                                                  // value={body}
                                                  className="fileInEmpPopup"
                                                />
                                                :
                                                ""

                                            }
                                            {
                                              imgId === 1 || imgId === 2 ? <p style={fileSizeMsg}>(Max File SIze: 8MB)</p> : ""
                                            }
                                            {
                                              imgId === 4 ? <div className="roaster">

                                                <Form >
                                                  <div className="oncallOnduty">
                                                    <div className="oncallOnDutySub">
                                                      <Form.Check
                                                        className="gender"
                                                        type="radio"
                                                        name="rosterType"
                                                        value={"OD"}
                                                        label={"On Duty"}
                                                        checked={roasterType === "OD"}
                                                        onChange={(event) => handleFormChange(event, index)}
                                                      />
                                                      <Form.Check
                                                        className="gender"
                                                        type="radio"
                                                        value={"OC"}
                                                        name="rosterType"
                                                        label={"On Call"}
                                                        checked={roasterType === "OC"}
                                                        onChange={(event) => handleFormChange(event, index)}
                                                      />
                                                    </div>
                                                  </div>
                                                  {
                                                    formFields.map((form, index) => {
                                                      return (
                                                        <>


                                                          <div className="addShift">
                                                            <Form.Label>Add Shift</Form.Label>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">Start Date <span style={{ color: 'red' }}>*</span>:</b>  <Form.Control min={new Date().toISOString().slice(0, 10)} isInvalid={isInvalidFlag && form.rosterStartDate === ""} className="formControlChange" type="date" name="rosterStartDate" onChange={(event) => handleFormChange(event, index)} value={form.rosterStartDate} /></p>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">End Date <span style={{ color: 'red' }}>*</span>:</b><Form.Control min={new Date().toISOString().slice(0, 10)} type="date" isInvalid={isInvalidFlag && form.rosterEndDate === ""} className="formControlChange" name="rosterEndDate" onChange={(event) => handleFormChange(event, index)} value={form.rosterEndDate} /></p>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">Zone <span style={{ color: 'red' }}>*</span>:</b> <Form.Select isInvalid={isInvalidFlag && form.zone === ""} value={form.zone} className="dateinAddShift selectInAddShift"
                                                              onChange={(event) => handleFormChange(event, index)}
                                                              name="zone" >
                                                              <option style={{ display: 'none' }} value="">Zone</option>
                                                              {
                                                                metaDataZone?.map(([key, val]) => (

                                                                  <option value={key}>{key}</option>
                                                                ))
                                                              }

                                                            </Form.Select></p>
                                                            {
                                                              addFieldsCount === index ? <Form.Label onClick={(e) => {
                                                                e.preventDefault();
                                                                setAddFieldsCount(addFieldsCount + 1)
                                                                addFields();
                                                              }}><img src={Addbtn} /></Form.Label> : <Form.Label></Form.Label>
                                                            }


                                                          </div>
                                                          <div className="currentShift">
                                                            <Form.Label></Form.Label>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">Start Time <span style={{ color: 'red' }}>*</span>:</b><Form.Control required isInvalid={isInvalidFlag && form.rosterStartTime === ""} type="time" className="formControlChange" onChange={(event) => handleFormChange(event, index)} name="rosterStartTime" value={form.rosterStartTime} /></p>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">End Time <span style={{ color: 'red' }}>*</span>:</b> <Form.Control required isInvalid={isInvalidFlag && form.rosterEndTime === ""} type="time" className="formControlChange" onChange={(event) => handleFormChange(event, index)} name="rosterEndTime" value={form.rosterEndTime} /></p>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">Area <span style={{ display: roasterType === 'OD' ? '' : 'none', color: 'red' }}>*</span>:</b> <Form.Select required isInvalid={isInvalidFlag && roasterType === 'OD' && form.maintenanceCenter === ""} value={form.maintenanceCenter}
                                                              onChange={(event) => handleFormChange(event, index)} className="dateinAddShift selectInAddShift"
                                                              name="maintenanceCenter">
                                                              <option style={{ display: 'none' }} value="">MC</option>
                                                              {
                                                                metaDataZone?.map(([key, val]) => (
                                                                  key === formFields[index].zone ?
                                                                    (val.toString().split(",").map((line) => (

                                                                      <option value={line}>{line}</option>
                                                                    ))) : ""
                                                                ))
                                                              }


                                                            </Form.Select></p>
                                                            <Form.Label></Form.Label>
                                                          </div>

                                                        </>
                                                      )
                                                    })
                                                  }
                                                  <div>
                                                    <Form.Label style={{ width: '100%', marginTop: '1%' }}>Current Shift</Form.Label>
                                                  </div>

                                                  {
                                                    getSchedules.map(val => {

                                                      return (

                                                        <div className="onDutyShow">
                                                          <div className="onDutyLabel">
                                                            <Form.Label>{val.rosterType === "OD" ? "On Duty" : "On Call"}</Form.Label>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">Start Date:</b> <Form.Control min={new Date().toISOString().slice(0, 10)} className="formControlChange" type="date" name="rosterStartDate" readOnly={editFieldValue === val.scheduleId ? false : true} onChange={(event) => handleSubFormChange(event, val)} value={val.rosterStartDate} /></p>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">End Date:</b> <Form.Control min={new Date().toISOString().slice(0, 10)} className="formControlChange" type="date" name="rosterEndDate" readOnly={editFieldValue === val.scheduleId ? false : true} onChange={(event) => handleSubFormChange(event, val)} value={val.rosterEndDate} /></p>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">Zone:</b> &nbsp;<Form.Select value={val.zone} readOnly={editFieldValue === val.scheduleId ? false : true}
                                                              onChange={(event) => handleSubFormChange(event, val)} className="dateinAddShift selectInAddShift"
                                                              name="zone" >
                                                              <option style={{ display: 'none' }} value="">{val.zone}</option>
                                                              {
                                                                editFieldValue === val.scheduleId && metaDataZone?.map(([key, val]) => (

                                                                  <option value={key}>{key}</option>
                                                                ))
                                                              }


                                                            </Form.Select></p>
                                                            <Form.Label onClick={() => setFieldValueData(val)}><img src={EditBtn} /></Form.Label>
                                                          </div>
                                                          <div className="onDutySecondRow">
                                                            <Form.Label></Form.Label>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">Start Time:</b> &nbsp; <Form.Control type="time" className="formControlChange" onChange={(event) => handleSubFormChange(event, val)} name="rosterStartTime" value={val.rosterStartTime} readOnly={editFieldValue === val.scheduleId ? false : true} /></p>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">End Time:</b> <Form.Control type="time" className="formControlChange" onChange={(event) => handleSubFormChange(event, val)} name="rosterEndTime" value={val.rosterEndTime} readOnly={editFieldValue === val.scheduleId ? false : true} /> </p>
                                                            <p className="ondutyDate"><b className="boldInOnDuty">Area:</b> &nbsp; <Form.Select value={val.maintenanceCenter}
                                                              onChange={(event) => handleSubFormChange(event, val)} className="dateinAddShift selectInAddShift" readOnly={editFieldValue !== val.scheduleId}
                                                              name="maintenanceCenter" required isInvalid={isInvalidUpdateFlag && val.rosterType === 'OD' && (val.maintenanceCenter === null || val.maintenanceCenter === "")}>
                                                              <option value="" selected style={{ display: 'none' }}>{val.maintenanceCenter}</option>
                                                              {
                                                                editFieldValue === val.scheduleId && metaDataZone?.map(([key, val]) => (
                                                                  key === currSelectedZone ?
                                                                    (val.toString().split(",").map((line) => (

                                                                      <option value={line}>{line}</option>
                                                                    ))) : ""
                                                                ))
                                                              }


                                                            </Form.Select></p>
                                                            <Form.Label></Form.Label>
                                                          </div>
                                                        </div>
                                                      )

                                                    })

                                                  }

                                                </Form>

                                              </div> : ''
                                            }

                                          </Form.Group>
                                        </Modal.Body>
                                        <Modal.Footer>
                                          <Button className="cancelBtninpopup" onClick={loading ? "" : closeModal}>
                                            Cancel
                                          </Button>
                                          <Button
                                            className={
                                              imgId === 2 ? "notificationBtninpopup" : "emailBtninpopup"
                                            }
                                            onClick={sendmail}
                                          >

                                            {

                                              imgId === 1
                                                ? "Send Mail"
                                                : imgId === 2
                                                  ? "Send Notification"
                                                  : imgId === 4 ? "Save"
                                                    : "Send"
                                            }
                                          </Button>
                                        </Modal.Footer>
                                      </LoadingOverlay>
                                    </div>
                                  </Modal>
                                </div>
                                <div>
                                  <Modal show={isConfirm} onHide={closeModal}>
                                    <Modal.Header closeButton> </Modal.Header>
                                    <Modal.Body>
                                      Are you sure you want delete <b>Profile</b> ?
                                    </Modal.Body>
                                    <Modal.Footer>
                                      <div className="displayflexforpopupdelete">
                                        <Button
                                          variant="secondary"
                                          className="yesBtninpopup"
                                          onClick={deleteEmployeeDetails}
                                        >
                                          Yes
                                        </Button>
                                        <Button
                                          variant="secondary"
                                          className="noBtninpopup"
                                          onClick={closeModal}
                                        >
                                          No
                                        </Button>
                                      </div>
                                    </Modal.Footer>
                                  </Modal>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))

                  }
                  {
                    employeeData.userDetails?.some(item => item["level"] === 2) ?
                      <div class="d-flex justify-content-center" style={{ height: ' 50px' }}>
                        <div class="vr"></div>
                      </div> : ''
                  }
                </div>
              </div>
              {
                employeeData.userDetails?.some(item => item["level"] === 2) ? <>
                  <hr className="vrtag" />
                  {/* <div className="row row-cols-4 gap-2 justify-content-center borderForEmp py-5"> */}
                  <div className="row" style={{ float: 'none', margin: '1% auto' }}>


                    {

                      employeeData.userDetails?.length === 0 ?
                        <div style={{ position: 'absolute', top: '45vh', left: '60vh', fontSize: '20px', fontWeight: 'bold' }}>No Users Found !!</div> :
                        employeeData.userDetails?.filter((ele) => (ele?.level === 2)).map((edata, index) => {
                          return fetchDataFromServer(edata, index);
                        })

                    }



                  </div>
                </> : ''
              }


            </div>



          </div>

        </div>
      </LoadingOverlay>

    );
  } else if (isViewIcon) {


    return (
      <EditEmployee
        userName={currUserName}
        userImg={imgUrl}
        setCancelStatus={setCancelStatus}
        isReloadStatus={isReloadStatus}
        checkEmployeeCreated={checkEmployeeCreated}
      ></EditEmployee>
    );
  } else {
    return (
      <AddEmployee
        setCancelStatus={setCancelStatus}
        isReloadStatus={isReloadStatus}
        checkEmployeeCreated={checkEmployeeCreated}
      ></AddEmployee>
    );
  }
}

export default Employees;
